import React, {useEffect, useState} from 'react';
import {Button, Switch, Typography} from 'spenda-ui-react';
import {AlertDialog} from '../dialog/AlertDialogSlideV2';
import VariantInventory from './VariantInventory';
import {CloseCross} from '../../assets/svg/CloseCross';
import {ARTable} from '../AccountsReceivable/ARTable';
import {ProductVariantIcon} from '../../assets/svg';
import {InventoryItemsDialog} from './InventoryItemsDialog';
import {useParams} from 'react-router-dom';
import {useInventoryAPI} from '../../services/useInventoryAPI';
import CreateMasterVariantDialog from '../dialog/CreateMasterVariantDialog';

interface VariantAttributeProps {
  label: string;
  shortLabel: string;
  onClick: (label: string, shortLabel: string) => void;
  color?: string;
  imageUrl?: string;
  sellPrice?: string;
}

interface AttributeButtonProps {
  label: string;
  shortLabel: string;
  onClick: (label: string, shortLabel: string) => void;
}

const AttributeButton: React.FC<AttributeButtonProps> = ({label, shortLabel, onClick}) => (
  <div
    className="flex cursor-pointer items-center rounded-bl-md rounded-tl-md bg-gray-200 py-1 text-sm"
    onClick={() => onClick(label, shortLabel)}
    role="button"
    tabIndex={0}
    data-autoid="btnAttribute"
  >
    <Typography
      variant="small"
      className="flex !h-2 items-center justify-between bg-gray-200 !px-2 text-sm text-black hover:no-underline"
      data-autoid="txtAttributeName"
    >
      {label}
    </Typography>
    <Typography variant="small" className="ml-4 mr-4 text-gray-500" data-autoid="txtAttributeAbbr">
      {shortLabel}
    </Typography>
  </div>
);

const VariantAttribute: React.FC<VariantAttributeProps> = ({
  label,
  shortLabel,
  onClick,
  color,
  imageUrl,
  sellPrice,
}) => (
  <div className="flex flex-col ">
    <div className="flex items-center gap-1">
      <AttributeButton onClick={onClick} label={label} shortLabel={shortLabel} />
      {color && <div className="h-10 w-10 rounded-md" style={{backgroundColor: color}}></div>}
      {imageUrl && <img src={imageUrl} alt={label} className="h-10 w-10 rounded-md" />}
    </div>
    {sellPrice && (
      <Typography variant="xsmall" className="font-medium text-spenda-sGreen">
        {sellPrice}
      </Typography>
    )}
  </div>
);

export const Variant = () => {
  const {productID} = useParams<{productID?: string | undefined}>();
  const {createInventory, getInventoryById, isLoading} = useInventoryAPI();

  const [isLinkedDialog, setIsLinkedDialog] = useState<boolean>(false);
  const [isNewItemDialog, setIsNewItemDialog] = useState<boolean>(false);
  const [isVariantInventoryDialog, setIsVariantInventoryDialog] = useState<boolean>(false);
  const [isInventoryItemsDialog, setIsInventoryItemsDialog] = useState<boolean>(false);
  const [openMasterVariantDialog, setOpenMasterVariantDialog] = useState<boolean>(false);
  const [isVariantMaster, setIsVariantMaster] = useState<boolean>(false);
  const [isToggleOn, setIsToggleOn] = useState<boolean>(false);
  const [inventory, setInventory] = useState<any>(null);

  useEffect(() => {
    const fetchProductData = async () => {
      if (productID && !inventory) {
        try {
          const fetchedInventory = await getInventoryById(productID);
          setInventory(fetchedInventory);
          setIsVariantMaster(fetchedInventory.IsVariantMaster ?? false);
          setIsToggleOn(fetchedInventory.IsVariantMaster ?? false);
        } catch (error) {
          console.error(error);
        }
      }
    };
    fetchProductData();
  }, [productID]);

  const handlePromoteToMaster = async () => {
    if (productID) {
      try {
        const payload = {
          ...inventory,
          ID: productID,
          IsVariantMaster: true,
          IsSOHTracked: false,
          IsSold: false,
          IsPurchased: false,
          IsPostingInventoryItem: false,
        };
        await createInventory(payload);
        setOpenMasterVariantDialog(true);
        setIsLinkedDialog(false);
        setIsNewItemDialog(false);
        setIsToggleOn(true);
      } catch (error) {
        console.error('Failed to promote to master:', error);
      }
    }
  };

  const handleToggleChange = async () => {
    if (isVariantMaster) {
      return;
    }
    if (productID && inventory) {
      try {
        if (!inventory.IsIntegrated) {
          setIsNewItemDialog(true);
        } else {
          setIsLinkedDialog(true);
        }
      } catch (error) {
        console.error('Failed to fetch inventory:', error);
      }
    }
    setIsToggleOn(true);
  };

  const handleDialogCancel = () => {
    setIsLinkedDialog(false);
    setIsNewItemDialog(false);
    setIsToggleOn(false);
  };

  const columns = [
    {
      title: 'Attributes',
      key: 'attributes',
      width: '60%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5 ',
      rowRenderer: () => (
        <>
          <div className="flex  max-w-[550px] flex-wrap gap-3">
            <VariantAttribute label="Yellow" onClick={() => {}} shortLabel="Y" color="#FFD700" />

            <VariantAttribute label="Blue" shortLabel="B" onClick={() => {}} color="#1686EE" />
          </div>
        </>
      ),
    },
    {
      title: 'Inventory items',
      key: 'inventoryItems',
      width: '40%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5 bg-spenda-scream/30',
      rowRenderer: (_index: number) => (
        <div className="flex gap-2">
          <Typography
            variant="small"
            className="cursor-pointer font-semibold"
            data-autoid={`txtUseExisting`}
            onClick={() => setIsInventoryItemsDialog(true)}
          >
            Use Existing
          </Typography>
          <Typography variant="small">or</Typography>
          <Typography variant="small" className="cursor-pointer font-semibold" data-autoid={`txtCreateNewItem`}>
            Create New Item
          </Typography>
        </div>
      ),
    },
  ];
  return (
    <>
      <div className="mt-5 flex">
        <div className="w-full lg:w-4/5">
          <div className="flex w-full max-w-full items-start gap-7 lg:w-[916px]">
            <div className="mt-[22px] flex items-center justify-center">
              <Switch
                id="IsLinked"
                name="IsLinked"
                ripple={false}
                className="h-full w-full checked:bg-success"
                onChange={handleToggleChange}
                checked={isToggleOn}
                containerProps={{
                  className: 'w-11 h-6',
                }}
                circleProps={{
                  className: 'before:hidden left-0.5 border-none',
                }}
              />
            </div>
            <div className="flex flex-col gap-2">
              <Typography className="font-poppins text-lg font-medium text-black-800">
                Do you want to enable variations for this product?
              </Typography>
              <Typography
                variant="small"
                className="max-w-full text-start font-poppins font-normal text-gray-800 lg:w-[554px]"
              >
                If you enable variations, this item will no longer be sold and you will be prompted to select the
                specific variation during the sales process.
              </Typography>
            </div>
          </div>
          {isVariantMaster && (
            <div className="mt-4 flex w-full flex-col rounded-md bg-spenda-cream px-2.5 lg:w-[996px]">
              <div className="flex items-center justify-between p-3 ">
                <Typography className="text-lg font-medium text-black-800">Product variants</Typography>
                <Button variant="outlined" onClick={() => setOpenMasterVariantDialog(true)}>
                  Variation Builder
                </Button>
              </div>
              <div className="mb-2 h-1 w-full border-b border-b-[#ECECEC]" />
              <Typography className="cursor-pointer pl-2.5 text-base font-semibold text-primary">Create All</Typography>
              <ARTable isLoading={false} columns={columns} scope="AR" rows={Array(2).fill(1)} tableClass="mt-3" />
            </div>
          )}
        </div>

        <div className="flex h-[350px] w-full max-w-full flex-col items-start rounded-md bg-[#E3EEF5] p-4 lg:w-[320px]">
          <div className="self-end">
            <CloseCross data-autoid={'btnCross'} className="cursor-pointer text-[#cccccc]" />
          </div>
          <div className="flex flex-col items-center gap-y-2 space-x-4 pt-3">
            <ProductVariantIcon />
            <div className="mt-5 flex flex-col items-center justify-center text-center">
              <Typography variant="paragraph" className="font-poppins text-lg font-medium text-black-800">
                Benefits of adding variants
              </Typography>
              <Typography variant="small" className="pt-3 font-poppins font-normal text-gray-800">
                Product variants are a great way to manage the same product that differs in style, colour size, etc.
                Each combination of value will result in a unique product selectable during the sales process.
              </Typography>
            </div>
          </div>
        </div>
      </div>

      {isLinkedDialog && (
        <AlertDialog
          title="Linked to External System"
          headingTextSize="h2"
          headingClassess="text-black justify-center"
          dialogClassess="!max-w-[497px] !min-w-[20%]"
          data-autoid={'dlgLinkedToExternalSystem'}
          actions={[
            {
              label: 'Cancel',
              variant: 'outlined',
              action: handleDialogCancel,
            },
            {
              label: 'Continue',
              loading: isLoading,
              action: () => {
                setIsVariantInventoryDialog(true);
                setIsLinkedDialog(false);
              },
            },
          ]}
          dialogBodyClassess="flex justify-center"
          contentClass="max-w-[374px] text-sm"
          body={
            <div className="flex flex-col gap-3 px-[2.5rem] py-3 text-center">
              <Typography variant="small" className="font-normal text-black-800">
                This product is integrated with an external system, In order to create a Master Inventory Item we will
                need to create a copy. This may result in a duplicate product being displayed when in Sales mode. If you
                don't want the original product to appear in your product management, you can manually archive it.
              </Typography>
              <Typography variant="small" className="font-normal text-black-800">
                Would you like to continue?
              </Typography>
            </div>
          }
        ></AlertDialog>
      )}

      {isNewItemDialog && (
        <AlertDialog
          title="Make Master"
          headingTextSize="h2"
          headingClassess="text-black justify-center"
          dialogClassess="!max-w-[497px] !min-w-[20%]"
          data-autoid={'dlgMakeMasterVariant'}
          actions={[
            {
              label: 'Cancel',
              variant: 'outlined',
              action: handleDialogCancel,
            },
            {
              label: 'Continue',
              loading: isLoading,
              action: handlePromoteToMaster,
            },
          ]}
          dialogBodyClassess="flex justify-center"
          contentClass="max-w-[374px] text-sm"
          content={`Are you sure you want to make into a master variant? You cannot undo this action.`}
        />
      )}
      {isVariantInventoryDialog && <VariantInventory handleClose={() => setIsVariantInventoryDialog(false)} />}
      {isInventoryItemsDialog && <InventoryItemsDialog handleCancel={() => setIsInventoryItemsDialog(false)} />}
      {openMasterVariantDialog && (
        <CreateMasterVariantDialog open={openMasterVariantDialog} onClose={() => setOpenMasterVariantDialog(false)} />
      )}
    </>
  );
};
