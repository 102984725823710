import React, {useEffect, useState} from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {APFooter} from './APFooter';
import APTooltip from '../data-display/APTootip';
import {PostingInventorySelection} from './PostingInventorySelection';
import blueInfoIcon from '../../assets/svg/accounts-payable/blue-info-icon.svg';
import {SwitchSlider} from '../form/SwitchSlider';
import {Inventory} from '../../model/inventory/Inventory';
import {useStagedTransactionsAPI} from '../../services/useStagedTransactionsAPI';
import {
  ISupplierManagementData,
  ISuppliersStagedTransactions,
  ITransactionsList,
} from '../../model/requires-attention/StagedTransactions';
import {useInventoryAPI} from '../../services/useInventoryAPI';
import LoadingIndicator from '../ui/LoadingIndicator';
import {DatTypes} from '../../model/constants/Constants';
import {Typography, Dialog, DialogHeader, DialogBody, Button} from 'spenda-ui-react';
import clsx from 'clsx';
import {ArRefreshBtn} from '../arRefresh/ArRefresh';
import {ISupplier} from '../../model/supplier/Supplier';

export interface ISelectPostingInventoryModalProps {
  handleClose: () => void;
  handleDone: () => void;
  isVendorSelected?: boolean;
  selectedTransactionDetail?: ISupplierManagementData;
  selectedSupplierDetail?: ISupplier;
  selectedSupplierData?: ISuppliersStagedTransactions;
  selectedVendorData?: ITransactionsList;
  scope: 'PSBL' | 'AP';
  isReMapping?: boolean;
}

export const SelectPostingInventoryModal = (props: ISelectPostingInventoryModalProps) => {
  const {
    handleClose,
    handleDone,
    isVendorSelected,
    selectedSupplierData,
    selectedSupplierDetail,
    isReMapping,
    selectedVendorData,
    selectedTransactionDetail,
    scope,
  } = props;
  const [mapEveryLine, setMapEveryLine] = useState<boolean>(
    selectedSupplierData?.isSourceSupplierInventoryMappingEnabled || false,
  );
  const [isPostAsInventoryLoading, setIsPostAsInventoryLoading] = useState(false);
  const [selectedPostingInventory, setSelectedPostingInventory] = useState<Partial<Inventory>>({});
  const {isLoading: isInventoryLoading, getInventoryById} = useInventoryAPI();

  const {setAPSetting} = useStagedTransactionsAPI();

  const isInAPContext = scope === 'AP';
  const isInPSBLContext = scope === 'PSBL';

  const vendorSupplier =
    isVendorSelected && selectedVendorData
      ? selectedVendorData.datTypeID === DatTypes.StagedPurchaseInvoice
        ? selectedVendorData?.stagedPurchaseInvoice?.supplierPrimaryContact
        : selectedVendorData?.stagedDebitNote?.supplierPrimaryContact
      : '';

  const handleDoneBtn = async () => {
    try {
      setIsPostAsInventoryLoading(true);
      if (isVendorSelected && selectedVendorData) {
        const supplierId =
          selectedVendorData.datTypeID === DatTypes.StagedPurchaseInvoice
            ? selectedVendorData?.stagedPurchaseInvoice?.supplierID
            : selectedVendorData?.stagedDebitNote?.supplierID;
        await setAPSetting(supplierId!, {postingInventoryID: selectedPostingInventory?.ID || -1});
      } else {
        await setAPSetting(selectedSupplierData?.sourceSupplier?.supplierID!, {
          postingInventoryID: selectedPostingInventory?.ID || -1,
          isInventoryMappingEnabled: mapEveryLine,
        });
      }
      if (isInPSBLContext) {
        setTimeout(() => {
          handleDone?.();
          setIsPostAsInventoryLoading(false);
        }, 2000);
      } else {
        handleDone?.();
        setIsPostAsInventoryLoading(false);
      }
    } catch (error) {
      console.error('error', error);
      setIsPostAsInventoryLoading(false);
    }
  };

  const handleRemapping = async () => {
    try {
      setIsPostAsInventoryLoading(true);
      if (isVendorSelected && selectedTransactionDetail) {
        await setAPSetting(selectedTransactionDetail.postingSupplierID, {
          postingInventoryID: selectedPostingInventory?.ID || -1,
        });
      }
      if (isInPSBLContext) {
        setTimeout(() => {
          handleDone?.();
          setIsPostAsInventoryLoading(false);
        }, 2000);
      } else {
        handleDone?.();
        setIsPostAsInventoryLoading(false);
      }
    } catch (error) {
      console.error('error', error);
      setIsPostAsInventoryLoading(false);
    }
  };

  const getInventoryDetails = (postingInventoryID: number) => {
    getInventoryById(postingInventoryID.toString()).then((res: Inventory) => {
      res && setSelectedPostingInventory(res);
    });
  };

  useEffect(() => {
    if ((isVendorSelected && selectedVendorData) || (isReMapping && selectedTransactionDetail)) {
      const postingInventoryId = isReMapping
        ? selectedTransactionDetail?.postingInventoryID
        : selectedVendorData?.datTypeID === DatTypes.StagedPurchaseInvoice
          ? selectedVendorData?.stagedPurchaseInvoice?.postingSupplierPostingInventoryID
          : selectedVendorData?.stagedDebitNote?.postingSupplierPostingInventoryID;
      postingInventoryId && getInventoryDetails(Number(postingInventoryId));
    } else if (selectedSupplierData && selectedSupplierData.sourceSupplierPostingInventoryID) {
      getInventoryDetails(selectedSupplierData.sourceSupplierPostingInventoryID);
    }
  }, [selectedSupplierData]);

  return (
    <Dialog
      open
      handler={() => {}}
      className={`${isInPSBLContext ? '!h-[400x] !min-h-[400px] !w-[690px] !min-w-[690px]' : '!h-[540px] !min-h-[540px] !w-[942px] !min-w-[942px]'} relative`}
    >
      <div className={` spenda-color flex h-full w-full items-center justify-center font-poppins text-xl`}>
        <div
          className={clsx(
            `text-spenda-primarytext`,
            {
              '!h-[540px] !min-h-[540px] !w-[942px] !min-w-[942px] px-5 pb-3 pt-3 ': isInAPContext,
            },
            {
              ' mx-auto !h-[400x] !min-h-[400px] !w-[690px] !min-w-[690px]': isInPSBLContext,
            },
          )}
        >
          <DialogHeader
            className={clsx(
              `flex justify-between`,
              {
                '-my-4 -mb-7 border-[#CCCCCC] border-b-default px-3 ': isInAPContext,
              },
              {
                'border-b border-[#D8D8D8]': isInPSBLContext,
              },
            )}
          >
            <h2 className={`w-full self-center text-center text-xl font-light`}>
              {isInAPContext
                ? isVendorSelected
                  ? 'Vendor inventory mapping'
                  : `Select supplier posting inventory`
                : isInPSBLContext
                  ? 'Select inventory behaviour'
                  : ''}
            </h2>

            {isInAPContext && (
              <div className=" m-auto -mr-5 flex justify-end text-right">
                <span className="ap-onboarding-close-icon">
                  <CloseIcon data-autoid="btnClose" onClick={handleClose} className="text-primary" />
                </span>
              </div>
            )}
          </DialogHeader>

          <div className="mt-6 p-2.5 ">
            <div className={`relative mx-auto w-full rounded-lg bg-spenda-cream p-5`}>
              <DialogBody className="flex flex-row flex-wrap justify-between text-left text-base">
                <div className="w-2/6 pr-2">
                  <h1 className="text-left font-bold text-black">Supplier</h1>
                  <Typography className="font-medium text-black">
                    {isReMapping && selectedSupplierDetail
                      ? selectedSupplierDetail?.Name
                      : isVendorSelected && vendorSupplier
                        ? vendorSupplier.name
                        : selectedSupplierData?.sourceSupplier?.name}
                  </Typography>
                </div>
                <div className="w-2/6 px-1">
                  <h1 className="text-left font-bold text-black">ABN</h1>
                  <Typography className="font-medium text-black">
                    {isReMapping && selectedSupplierDetail
                      ? selectedSupplierDetail?.ABN
                      : isVendorSelected && vendorSupplier
                        ? vendorSupplier.abn
                        : selectedSupplierData?.sourceSupplier?.abn}
                  </Typography>
                </div>
                <div className="w-2/6 pl-2">
                  <h1 className="text-left font-bold text-black">Supplier email</h1>
                  <Typography className=" break-words font-medium text-black">
                    {isReMapping && selectedSupplierDetail
                      ? selectedSupplierDetail?.PrimaryContactEmailAddress
                      : isVendorSelected && vendorSupplier
                        ? vendorSupplier.emailAddress
                        : selectedSupplierData?.sourceSupplier?.emailAddress}
                  </Typography>
                </div>
              </DialogBody>
            </div>
            {isInventoryLoading ? (
              <LoadingIndicator isLoading={isInventoryLoading} size="md" color="#1C78AD" />
            ) : (
              <>
                <div className="mt-6 flex items-center justify-start">
                  {isInAPContext && (
                    <div className="mr-8 flex min-w-[230px] flex-row">
                      <span className="min-w-[60%] text-xs text-black">
                        {isVendorSelected ? 'Vendor' : 'Supplier'} generic posting inventory:
                      </span>
                      <APTooltip
                        arrow
                        placement="bottom"
                        title="By default, the system will allocate a generic inventory. If you wish to make your own selection, please choose from the dropdown menu."
                      >
                        <img className="ml-1 cursor-pointer" src={blueInfoIcon} data-autoid={`imgBlueInfo`} />
                      </APTooltip>
                    </div>
                  )}

                  <div className={clsx({'flex w-full items-center  justify-center gap-9': isInPSBLContext})}>
                    <PostingInventorySelection
                      supplierTable={true}
                      selectedPostingInventory={selectedPostingInventory}
                      setSelectedPostingInventory={setSelectedPostingInventory}
                      isShowRedirectIcon={isInAPContext ? true : false}
                    />
                    {isInPSBLContext && (
                      <ArRefreshBtn dataAutoID="btnInventoryRefresh" datTypeId={DatTypes.Inventory} />
                    )}
                  </div>
                </div>

                {!isVendorSelected && isInAPContext && (
                  <div className="mt-6 flex items-center justify-start">
                    <div className="mr-8 flex min-w-[230px] flex-row">
                      <span className="min-w-[60%] text-xs">Map every transactions line:</span>
                      <APTooltip arrow placement="bottom" title="">
                        <img className="ml-1 cursor-pointer" src={blueInfoIcon} data-autoid={`imgBlueInfo`} />
                      </APTooltip>
                    </div>
                    <SwitchSlider
                      name="mappLine"
                      checked={mapEveryLine}
                      handleChange={_e => {
                        setMapEveryLine(!mapEveryLine);
                      }}
                    />
                  </div>
                )}
              </>
            )}
            {isInPSBLContext ? (
              <div className="absolute bottom-2.5 flex h-[60px] w-[670px] flex-row items-center justify-between rounded-[6px] bg-[#ececec] px-2.5">
                <Button
                  type="button"
                  data-autoid={`btnCancel`}
                  variant="outlined"
                  className="!bg-white"
                  color="primary"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  loading={isPostAsInventoryLoading}
                  onClick={isReMapping ? handleRemapping : handleDoneBtn}
                  type="button"
                  data-autoid={`btnContinue`}
                  variant="filled"
                  color="primary"
                >
                  Continue
                </Button>
              </div>
            ) : (
              <APFooter
                isCancelButton={true}
                buttonLabel={isInAPContext ? 'Done' : 'Continue'}
                onNextClick={() => handleDoneBtn()}
                handleCancelClick={handleClose}
                isT2TSupplierModule={true}
                conditionallyClass={true}
                APFooterClass="!bottom-[10px] !left-[10px]"
                style={{width: 'calc(100% - 20px)'}}
              />
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
