import React, {useEffect, useState} from 'react';
import {ARDialogTemplate} from '../ARDialogTemplate';
import {IGetInventoryTemplates} from '../../../model/OnboardingV1Step';
import {Button} from 'spenda-ui-react';
import InventorySelectableButtons from './InventorySelectableButtons';
import {ISelectedInventoryValue} from '../../lowTouchOnboarding/SetUpInventory';

interface IGetInventoryItemsSelection {
  inventoryData?: IGetInventoryTemplates;
  selectedTitles: ISelectedInventoryValue[];
  onClose: () => void;
  setSelectedTitles: React.Dispatch<React.SetStateAction<ISelectedInventoryValue[]>>;
}

export const InventoryItemsSelectionModal = (props: IGetInventoryItemsSelection) => {
  // Props
  const {selectedTitles, inventoryData, onClose, setSelectedTitles} = props;

  // State
  const [localSelectedTitles, setLocalSelectedTitles] = useState<ISelectedInventoryValue[]>(selectedTitles);

  // useEffect
  useEffect(() => {
    setLocalSelectedTitles(selectedTitles);
  }, [selectedTitles]);

  // Functions
  const handleSave = () => {
    setSelectedTitles(localSelectedTitles);
    onClose();
  };

  return (
    <>
      <ARDialogTemplate
        dialogProps={{size: 'md', open: true, className: '!w-[791px] !min-w-[791px]'}}
        isFullScreen
        header={<p className="font-poppins text-xl font-light text-[#333333]">Inventory items selection</p>}
        body={
          <div className="mb-8  flex !min-w-[791px] flex-col items-center justify-center">
            <InventorySelectableButtons
              selectedTitles={localSelectedTitles}
              inventoryData={inventoryData}
              setSelectedTitles={setLocalSelectedTitles}
            />
          </div>
        }
        footer={
          <div className="flex w-full flex-row items-center justify-between">
            <Button data-autoid={`btnCancel`} variant="outlined" color="primary" className="bg-white" onClick={onClose}>
              Cancel
            </Button>
            <Button data-autoid={`btnSave&Close`} variant="filled" color="primary" onClick={() => handleSave()}>
              Save & close
            </Button>
          </div>
        }
      />
    </>
  );
};

export default InventoryItemsSelectionModal;
