import React from 'react';
import {
  Button,
  Dialog,
  DialogBody,
  DialogHeader,
  DialogFooter,
  Typography,
  IconButton,
  Select,
  Option,
  Input,
} from 'spenda-ui-react';
import * as Yup from 'yup';
import {Form, Formik, getIn} from 'formik';
import {CloseCross} from '../../assets/svg/CloseCross';
import {PriceFormat} from '../../utils/formatter';
import {PaymentMethodTypes} from '../../model/constants/Constants';
import useQuotesAPI from '../../services/useQuotesAPI';

interface IQuoteDepositDialog {
  open: boolean;
  handleClose: () => void;
  handleRefresh: () => void;
  depositAmount: number;
  quoteId: number;
  totalInc: number;
}

const selectedPaymentMethods = [
  PaymentMethodTypes.Cash,
  PaymentMethodTypes.Eftpos,
  PaymentMethodTypes.Cheque,
  PaymentMethodTypes.Visa,
  PaymentMethodTypes.PayPal,
  PaymentMethodTypes.CreditCard,
  PaymentMethodTypes.DirectDeposit,
  PaymentMethodTypes.Afterpay,
  PaymentMethodTypes.DirectDebit,
  PaymentMethodTypes.AmericanExpress,
  PaymentMethodTypes.Square,
  PaymentMethodTypes.DinersClub,
  PaymentMethodTypes.UnionPay,
  PaymentMethodTypes.Mastercard,
];

interface ManualDepositValues {
  deposit: string;
  paymentMode: string;
}

export const QuoteDepositDialog = (props: IQuoteDepositDialog) => {
  const {open, handleClose, handleRefresh, depositAmount, quoteId, totalInc} = props;
  const {acceptQuote, saveManualDeposit, isLoading} = useQuotesAPI();

  const validationSchema = Yup.object().shape({
    deposit: Yup.string()
      .required('Deposit amount is required')
      .test('min_max_check', 'Enter positive number', function (val) {
        if (val) {
          const amt = parseFloat(val || '0');
          if (amt < 0) {
            return false;
          } else if (val && depositAmount && amt < depositAmount) {
            return this.createError({message: `Deposit amount can't be less than set deposit!`});
          } else if (val && totalInc && amt > totalInc) {
            return this.createError({message: `Deposit amount can't be more than total!`});
          }
        } else {
          return this.createError({message: 'Invalid Amount'});
        }
        return true;
      }),
    paymentMode: Yup.string().required('Mode of payment is required'),
  });

  const onSubmit = async (values: ManualDepositValues) => {
    try {
      await acceptQuote(quoteId);
      await saveManualDeposit(quoteId, parseFloat(values.deposit), values.paymentMode);
      handleRefresh();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Formik initialValues={{deposit: '', paymentMode: ''}} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({values, touched, errors, handleChange, handleSubmit, setFieldValue}) => {
        return (
          <Form>
            <Dialog
              open={open}
              handler={() => {}}
              className={`flex h-full !w-full !min-w-[20%] flex-col items-center justify-center sm:block sm:h-auto sm:!max-w-[339px] sm:p-0`}
            >
              <DialogHeader className="flex flex-col gap-1">
                <div className="flex w-full justify-end">
                  <IconButton variant="outlined" onClick={handleClose}>
                    <CloseCross />
                  </IconButton>
                </div>
                <Typography className="flex items-center justify-center text-center text-black" variant="h2">
                  How much you want to mark the deposit manually?
                </Typography>
              </DialogHeader>
              <DialogBody className="flex w-full min-w-[290px] flex-col items-center justify-center gap-5">
                <Typography className={`min-w-[268px] text-center font-normal text-black-900`} variant="small">
                  Enter the customer's deposit amount and choose the payment method.
                </Typography>
                <div className="flex w-[308px] items-center justify-between rounded-md bg-[#D3E5EF4D] px-2.5 py-1">
                  <Typography variant="small" className="flex items-center justify-center font-normal text-black-900">
                    Set deposit
                  </Typography>
                  <Typography variant="small" className="p-2 font-normal text-black-900">
                    {PriceFormat(depositAmount)}
                  </Typography>
                </div>
                {errors?.deposit && <Typography className="text-xs text-error">{errors?.deposit}</Typography>}
                <div className="flex w-[308px] flex-col justify-center rounded-md bg-[#D3E5EF4D] p-4">
                  <div className="flex items-center justify-between py-3">
                    <Typography className="font-poppins text-sm text-black-800"> Deposit value: </Typography>
                    <div className="flex w-[103px] items-end justify-end">
                      <Input
                        type="number"
                        name="deposit"
                        data-autoid="txtDeposit"
                        containerProps={{className: '!min-w-[103px]'}}
                        className="!h-[45px] min-h-[45px] w-[103px]"
                        value={values.deposit}
                        onChange={handleChange}
                        label="Deposit"
                        error={getIn(touched, 'deposit') && Boolean(getIn(errors, 'deposit'))}
                      />
                    </div>
                  </div>
                  <div className="flex w-[276px] flex-col items-center justify-center gap-4">
                    {selectedPaymentMethods?.length && (
                      <Select
                        name="paymentMode"
                        size="lg"
                        variant="outlined"
                        label={
                          touched.paymentMode && Boolean(errors.paymentMode)
                            ? 'Mode of payment(required)'
                            : 'Mode of payment'
                        }
                        error={touched.paymentMode && Boolean(errors.paymentMode)}
                        value={values?.paymentMode?.toString()}
                        onChange={(value: string) => {
                          setFieldValue('paymentMode', value);
                        }}
                      >
                        {selectedPaymentMethods?.map((options, index) => {
                          return <Option value={options?.toString()} key={index}>{`${options}`}</Option>;
                        })}
                      </Select>
                    )}
                  </div>
                </div>
              </DialogBody>
              <DialogFooter>
                <div className="flex w-full flex-col items-center justify-center p-2">
                  <div className="flex w-[300px] flex-col justify-between gap-2">
                    <Button type="submit" onClick={() => handleSubmit()} disabled={isLoading} loading={isLoading}>
                      Save Deposit
                    </Button>
                  </div>
                </div>
              </DialogFooter>
            </Dialog>
          </Form>
        );
      }}
    </Formik>
  );
};
