import clsx from 'clsx';
import React, {useContext, useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Alert, Badge, Button, IconButton, Typography} from 'spenda-ui-react';

import {ARInfoYellowIcon} from '../../../assets/svg/ARInfoYellowIcon';
import {AttachDocs} from '../../../assets/svg/AttachDocs';
import IconDownloadClaim from '../../../assets/svg/IconDownloadClaim';
// import SelectAPInvoice from '../../../assets/svg/accounts-payable/select-ap-invoice.svg';
import SelectAPInvoiceIcon from '../../../assets/svg/accounts-payable/SelectAPInvoiceIcon';
import PurchasingContext from '../../../context/purchasing/purchasingContext';
import {useClaimsAndReturnHook} from '../../../hooks/useARHook';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {IClaimRequestDetailsResponse} from '../../../model/claims/ClaimsAndReturns';
import {ClaimsAndReturnsStatus, ConversationType, StartConvoType} from '../../../model/constants/Constants';
import {AUTH_SELLING_AR_EDIT_CLAIM_REQUESTS_ROUTE} from '../../../routes/AuthenticatedRoutes';
import {renderClaimsAndReturnStatus} from '../../../screens/AccountsReceivable/claims-and-returns/ClaimsHighLevel';
import useClaimsAndReturnsAPI from '../../../services/useClaimsAndReturnsAPI';
import {Toast} from '../../../utils/Toast';
import {CurrencyFromNumber, NameInitials, PriceFormat} from '../../../utils/formatter';
import StartConvo from '../../conversationChatBox/StartConvo';
import ARTooltip from '../../data-display/ARTootip';
import LoadingIndicator from '../../ui/LoadingIndicator';
import EditEvidenceDialog from '../EditEvidenceDialog';
import {ApproveClaimModal} from '../modals/ApproveClaimModal';
import {DeleteClaimModal} from '../modals/DeleteClaimModal';
import {RejectClaimModal} from '../modals/RejectClaimModal';
import {IconNoteBook} from '../../../assets/svg';
import ClaimsInternalNotesDialog from '../ClaimsInternalNotesDialog';
import {VisibleContent} from '../../ui/VisibleContent';

export interface IARClaimsWidgetProps {
  claimRequestId?: number;
  accountCustomerId?: number;
  refecthClaimList: () => void;
}

export const ARClaimsWidget = (props: IARClaimsWidgetProps) => {
  // Props
  const {claimRequestId, accountCustomerId, refecthClaimList} = props;

  // APIs
  const {getClaimRquestDetailSupplier, isLoading} = useClaimsAndReturnsAPI();

  // States
  const [claimDetails, setClaimDetails] = useState<IClaimRequestDetailsResponse>();

  // Hooks
  const history = useHistory();
  const {search} = useLocation();

  // UseEffect
  useEffect(() => {
    fetchClaimDetails();
  }, [claimRequestId]);

  const fetchClaimDetails = async () => {
    try {
      if (!claimRequestId || !accountCustomerId) return;
      const claimDetailsResponse = await getClaimRquestDetailSupplier(accountCustomerId, claimRequestId);
      setClaimDetails(claimDetailsResponse);
    } catch {}
  };

  const handleRefetchData = async (status: ClaimsAndReturnsStatus) => {
    const query = new URLSearchParams(search);
    refecthClaimList();
    if (![ClaimsAndReturnsStatus?.Deleted, ClaimsAndReturnsStatus?.Cancelled]?.includes(status)) {
      await fetchClaimDetails();
      return;
    }
    setClaimDetails(undefined);
    query.delete('claimRequestId');
    history.replace({search: query?.toString()});
  };

  return (
    <>
      {isLoading ? (
        <LoadingIndicator size="sm" isLoading={isLoading} />
      ) : claimDetails ? (
        <ClaimDetails
          refetchClaimDetails={handleRefetchData}
          accountCustomerId={accountCustomerId}
          claimRequestId={claimRequestId}
          claimDetails={claimDetails}
        />
      ) : (
        <div className="pb-15 relative flex h-full w-full flex-col">
          <div className="flex h-full w-full flex-col items-center justify-center text-center font-poppins text-lg font-medium text-black-800">
            <div className="mx-5 rounded-[10px] bg-[hsl(var(--primary-header))] px-7 py-8 text-center">
              <p className="font-poppins text-xl font-light text-black-800">Please select a claim</p>
              <SelectAPInvoiceIcon />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

interface IUpdateClaimStatusPaylod {
  reason?: string;
  approveReferenceNo?: string;
  approvePaymentDate?: string;
}
interface IClaimDetailProps {
  claimDetails: IClaimRequestDetailsResponse;
  claimRequestId?: number;
  accountCustomerId?: number;
  refetchClaimDetails: (status: ClaimsAndReturnsStatus) => void;
}

const ClaimDetails = (props: IClaimDetailProps) => {
  // Props
  const {claimDetails, claimRequestId, accountCustomerId, refetchClaimDetails} = props;

  //State
  const [showRejectClaimDialog, setShowRejectClaimDialog] = useState(false);
  const [showApproveClaimDialog, setShowApproveClaimDialog] = useState(false);
  const [isShowDeleteClaimDialog, setIsShowDeleteClaimDialog] = useState(false);
  const [iSShowUploadAttachmentModal, setISShowUploadAttachmentModal] = useState(false);
  const [isShowInternalNotesDialog, setIsShowInternalNotesDialog] = useState(false);

  // APIs
  const {updateClaimAndReturnStatus, isLoading: isSubmitting} = useClaimsAndReturnsAPI();

  // Context
  const {conversations} = useContext(PurchasingContext);

  // Hooks
  const history = useHistory();
  const {downloadClaimDocument, isDownloadClaimLoading} = useClaimsAndReturnHook();

  // Feature Flags
  const {claimAndReturnsV272602} = useFeatureFlags().tenantOwned();

  // Constants
  const attachmentCount = claimDetails?.lines?.reduce((a, c) => a + (c?.claimRequestAttachments?.length || 0), 0) || 0;
  const isShowPendingStatus =
    [ClaimsAndReturnsStatus?.Sent, ClaimsAndReturnsStatus?.Reviewed]?.includes(claimDetails?.status) &&
    claimDetails?.isPendingCustomerAction;

  const handleUpdateClaimRequestStatus = async (status: ClaimsAndReturnsStatus, payload?: IUpdateClaimStatusPaylod) => {
    try {
      if (!claimDetails?.claimRequestID) return;
      const {reason, approvePaymentDate, approveReferenceNo} = payload || {};
      await updateClaimAndReturnStatus(claimDetails?.claimRequestID, {
        status: status,
        VendorCreditNoteDate:
          status === ClaimsAndReturnsStatus.Approved && claimAndReturnsV272602 && approvePaymentDate
            ? approvePaymentDate
            : undefined,
        SupplierReferenceNumber:
          status === ClaimsAndReturnsStatus.Approved && claimAndReturnsV272602 && approveReferenceNo
            ? approveReferenceNo
            : undefined,
        comment: reason || undefined,
        accountCustomerID: claimDetails?.accountCustomerID || undefined,
      });
      setShowRejectClaimDialog(false);
      setShowApproveClaimDialog(false);
      setIsShowDeleteClaimDialog(false);
      refetchClaimDetails(status);
    } catch (e) {
      const {errors} = e as any;
      Toast.error(errors?.[0]?.message);
    }
  };

  const isShowActionButton = ![
    ClaimsAndReturnsStatus?.Rejected,
    ClaimsAndReturnsStatus?.Cancelled,
    ClaimsAndReturnsStatus?.Approved,
    ClaimsAndReturnsStatus?.Finalised,
  ]?.includes(claimDetails.status);

  const isVisibleChatWidget = VisibleContent({
    keyPath: 'accountsReceivable.widgetPanel.conversationChatBox',
    children: true,
  }) as boolean;

  return (
    <>
      <div
        className={clsx(
          'relative flex w-full flex-col',
          {'pb-15': isShowActionButton},
          {'pb-17': claimDetails?.rejectReason && claimDetails?.status === ClaimsAndReturnsStatus.Rejected},
          {'h-[calc(100%-62px)]': isVisibleChatWidget},
          {'h-full': !isVisibleChatWidget},
        )}
      >
        <div className="flex h-full w-full flex-col justify-between">
          <div className=" flex h-auto w-full flex-row items-center justify-between border-[#D8D8D8] border-b-default bg-[#FAFAFA]">
            <div className="flex w-full flex-col items-center justify-between">
              <div className="h-15 flex w-full flex-row items-center justify-between bg-[#FAFAFA] p-2.5">
                <div className="flex flex-row gap-2.5">
                  <div
                    data-autoid={`lblAccountCustomerNameInitials`}
                    className="flex h-10 w-10 items-center justify-center rounded-full bg-[#D4E8E0] font-poppins text-base font-normal leading-normal text-[#3D9F78]"
                  >
                    {(claimDetails?.accountCustomerName || claimDetails?.customerName) &&
                      NameInitials(claimDetails?.accountCustomerName || claimDetails?.customerName || '')}
                  </div>
                  <div className="flex flex-col">
                    <div className="flex flex-row items-center gap-2">
                      <Typography
                        data-autoid={`lblAccountCustomerName`}
                        className="font-medium leading-normal text-black-800"
                        variant="paragraph"
                      >
                        {claimDetails?.accountCustomerName || claimDetails?.customerName}
                      </Typography>
                      <VisibleContent keyPath="accountsReceivable.claimRequests.aRClaimsWidget.startConvo">
                        <div className="h-[16px]" data-autoid={`btnConversationModule`}>
                          {claimRequestId && claimDetails && claimAndReturnsV272602 && (
                            <StartConvo
                              operationUniqueId={claimDetails?.claimGUID}
                              conversationWith={claimDetails?.accountCustomerName || ''}
                              refNumber={claimDetails?.refNumber}
                              customerID={claimDetails?.accountCustomerID}
                              datTypeID={claimDetails?.datTypeID}
                              guid={claimDetails?.claimGUID}
                              contextConvos={conversations}
                              startConvoType={StartConvoType.ICON}
                              convoType={ConversationType.DOCUMENT}
                              unreadMessages={claimDetails?.unreadMessages}
                              conversationId={claimDetails?.conversationID}
                            />
                          )}
                        </div>
                      </VisibleContent>
                    </div>
                    <Typography
                      data-autoid={`lblCompanyPhoneNumber&VendorName`}
                      className="font-normal leading-normal text-black-800"
                      variant="xsmall"
                    >
                      {claimDetails?.companyPhoneNumber}{' '}
                      {claimDetails?.vendorName ? `Vendor - ${claimDetails?.vendorName}` : ''}
                    </Typography>
                  </div>
                </div>
                <div className="mr-1 flex flex-row justify-between gap-3">
                  <div className="flex flex-row gap-2.5">
                    <div className="flex flex-col items-end">
                      <Typography className="text-center font-normal leading-normal text-black-800" variant="xsmall">
                        Code
                      </Typography>
                      <Typography
                        data-autoid={`lblClaimCode`}
                        className="text-center indent-10 font-medium leading-normal text-black-800"
                        variant="paragraph"
                      >
                        {claimDetails?.claimCode}
                      </Typography>
                    </div>
                    <VisibleContent keyPath="accountsReceivable.claimRequests.aRClaimsWidget.attachment">
                      {claimRequestId && claimAndReturnsV272602 && (
                        <Badge content={attachmentCount} className="h-[22px] w-[20px] font-poppins" color="error">
                          <IconButton
                            variant="outlined"
                            data-autoid="btnAttachDocs"
                            onClick={() => setISShowUploadAttachmentModal(true)}
                          >
                            <AttachDocs className="-rotate-45 cursor-pointer" width="22px" height="auto" />
                          </IconButton>
                        </Badge>
                      )}
                    </VisibleContent>
                    {claimAndReturnsV272602 && (
                      <IconButton
                        variant="outlined"
                        data-autoid="btnAddInternalNotes"
                        onClick={() => setIsShowInternalNotesDialog(true)}
                      >
                        <IconNoteBook />
                      </IconButton>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full bg-white px-2.5">
                <div className="h-18 flex w-full flex-col justify-between py-2.5">
                  <div className="flex w-full flex-row justify-between">
                    <div className="flex flex-row items-center gap-2">
                      <Typography
                        data-autoid={`lblClaimedItems`}
                        className=" font-medium leading-normal text-black-800"
                        variant="paragraph"
                      >
                        Claimed items({claimDetails?.noItems})
                      </Typography>
                      {claimAndReturnsV272602 &&
                        (claimDetails?.isDisputed || claimDetails?.claimRequestRefIDIsDisputed) && (
                          <ARTooltip
                            title={
                              <Typography
                                className="whitespace-nowrap text-center font-medium text-spenda-primarytext"
                                variant="small"
                              >
                                {claimDetails?.isDisputed
                                  ? 'Disputed'
                                  : claimDetails?.claimRequestRefIDIsDisputed
                                    ? 'Previously Disputed'
                                    : ''}
                              </Typography>
                            }
                          >
                            <div className="cursor-pointer" data-autoid={`iconInfoClaimsWidget`}>
                              <ARInfoYellowIcon />
                            </div>
                          </ARTooltip>
                        )}
                      {claimAndReturnsV272602 &&
                        (isDownloadClaimLoading ? (
                          <div className="relative pr-5">
                            <LoadingIndicator size="sm" isLoading={isDownloadClaimLoading} />
                          </div>
                        ) : (
                          <ARTooltip
                            title={
                              <p className="whitespace-nowrap text-center font-poppins text-[12px] font-medium text-spenda-primarytext">
                                Download Claim
                              </p>
                            }
                          >
                            <button
                              type="button"
                              data-autoid="btnDownloadClaim"
                              className={'relative flex items-center justify-center'}
                              onClick={() => {
                                claimRequestId &&
                                  accountCustomerId &&
                                  downloadClaimDocument({claimRequestId, accountCustomerId});
                              }}
                            >
                              <IconDownloadClaim />
                            </button>
                          </ARTooltip>
                        ))}
                    </div>
                    <Typography
                      data-autoid={`lblTotalInc`}
                      className="text-right font-medium leading-normal text-[#3C9F78]"
                      variant="paragraph"
                    >
                      {PriceFormat(claimDetails?.totalInc)}
                    </Typography>
                  </div>
                  <div className="flex w-full flex-row items-center justify-between">
                    <div className="">
                      <Typography className="font-medium leading-normal text-[#BFBFBF]" variant="xsmall">
                        Action:{' '}
                        <Typography className="inline text-[#3C9F78]" variant="xsmall" data-autoid={`lblAction`}>
                          {claimDetails?.action}
                        </Typography>
                      </Typography>
                      <Typography className="font-medium leading-normal text-[#BFBFBF]" variant="xsmall">
                        Reason:{' '}
                        <Typography className="inline text-[#3C9F78]" variant="xsmall" data-autoid={`lblReason`}>
                          {claimDetails?.reason}
                        </Typography>
                      </Typography>
                    </div>
                    <div
                      data-autoid={`lblStatus`}
                      className="flex h-6 items-center justify-center rounded-[4px] font-poppins text-xs font-medium leading-normal"
                    >
                      {isShowPendingStatus
                        ? renderClaimsAndReturnStatus('Pending')
                        : renderClaimsAndReturnStatus(claimDetails?.status)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex h-full w-full flex-col items-center overflow-y-auto pb-[80px] pt-2.5">
            <div className="relative flex w-full flex-col bg-[#FFF] px-2.5 py-1">
              <Typography className="pb-[7px] font-medium leading-normal text-black-800" variant="paragraph">
                Lines
              </Typography>
              <table>
                <thead>
                  <tr className="!h-10">
                    <th
                      align="left"
                      className="bg-[#F1F1F1] px-2.5 font-poppins text-base font-medium leading-normal text-[#999999]"
                    >
                      Description
                    </th>
                    <th
                      align="right"
                      className="bg-[#F1F1F1] px-2.5 font-poppins text-base font-medium leading-normal text-[#999999]"
                    >
                      Qty
                    </th>
                    <th
                      align="right"
                      className="bg-[#F1F1F1] px-2.5 font-poppins text-base font-medium leading-normal text-[#999999]"
                    >
                      $(ea)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {claimDetails?.lines?.map((l, indx) => (
                    <tr data-autoid={`rowClaimWidgetLine${l?.claimRequestLineID}`} key={indx} className="!h-10">
                      <td align="left" className="bg-[#FFF] px-2.5">
                        <div className="justify start flex flex-col py-2.5">
                          <Typography
                            data-autoid={`lblShortDescription`}
                            className="mb-[7px] font-semibold leading-normal text-black-800"
                            variant="paragraph"
                          >
                            {l?.shortDescription}
                          </Typography>
                          <Typography className="mb-[3px] font-semibold leading-normal text-black-800" variant="xsmall">
                            Reason:{' '}
                            <Typography variant="xsmall" data-autoid={`lblReason`} className="inline font-normal">
                              {l?.reason}
                            </Typography>
                          </Typography>
                          <Typography className=" font-semibold leading-normal text-black-800" variant="xsmall">
                            Action:{' '}
                            <Typography variant="xsmall" data-autoid={`lblAction`} className="inline font-normal">
                              {l?.action}
                            </Typography>
                          </Typography>
                        </div>
                      </td>
                      <td
                        align="right"
                        className="bg-[#FAFAFA] px-2.5 font-poppins text-base font-medium leading-normal text-[#999999]"
                        data-autoid={`lblQuantity`}
                      >
                        {l?.quantity}
                      </td>
                      <td
                        align="right"
                        className="bg-[#FFF] px-2.5 font-poppins text-base font-medium leading-normal text-[#999999]"
                        data-autoid={`lblSellPriceEx`}
                      >
                        {CurrencyFromNumber(l?.sellPriceEx)}
                      </td>
                    </tr>
                  ))}
                  {/* Lower Lines of table */}
                  <tr>
                    <td
                      colSpan={2}
                      align="right"
                      className="!bg-[hsl(var(--primary-background))] px-2.5 !pt-2.5 pb-[7px] font-poppins text-xs font-normal leading-normal text-black-800"
                    >
                      Total(ex tax):
                    </td>
                    <td
                      align="right"
                      data-autoid={`lblTotalEx`}
                      className="!bg-[hsl(var(--primary-background))] px-2.5 !pt-2.5 pb-[7px] font-poppins text-xs font-normal leading-normal text-black-800"
                    >
                      {PriceFormat(claimDetails?.totalEx)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      align="right"
                      className="!bg-[hsl(var(--primary-background))] px-2.5 pb-[7px] font-poppins text-xs font-normal leading-normal text-black-800"
                    >
                      Tax:
                    </td>
                    <td
                      align="right"
                      data-autoid={`lblTotalTax`}
                      className="!bg-[hsl(var(--primary-background))] px-2.5 pb-[7px] font-poppins text-xs font-normal leading-normal text-black-800"
                    >
                      {PriceFormat(claimDetails?.totalTax)}
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      align="right"
                      className="!bg-[hsl(var(--primary-background))] px-2.5 !pb-2.5 font-poppins text-xs font-extrabold leading-normal text-black-800"
                    >
                      Total(inc tax):
                    </td>
                    <td
                      align="right"
                      className="!bg-[hsl(var(--primary-background))] px-2.5 !pb-2.5 font-poppins text-xs font-extrabold leading-normal text-black-800"
                      data-autoid={`lblTotalInc`}
                    >
                      {PriceFormat(claimDetails?.totalInc)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="absolute bottom-2.5 left-2.5 flex w-[394px] flex-col gap-2.5 ">
            {/* <div className="flex flex-col items-start justify-between rounded-[8px] bg-[#F4E8D1] p-2.5">
              <p className=" text-xs font-semibold font-poppins text-black-800 mb-[6px]">New claim request</p>
              <p className=" text-xs font-normal font-poppins text-black-800">
                You got a new claim request, take appropriate action.
              </p>
            </div> */}
            {claimDetails?.rejectReason && claimDetails?.status === ClaimsAndReturnsStatus.Rejected && (
              <>
                <Alert
                  variant="filled"
                  className="mb-[6px] inline-block rounded-[8px] bg-[#F3DFDA] font-poppins text-xs font-semibold text-black-800"
                >
                  Claim rejected
                  <Typography className="font-normal text-black-800" data-autoid={`lblRejectReason`} variant="xsmall">
                    {claimDetails?.rejectReason}
                  </Typography>
                </Alert>
              </>
            )}

            {isShowActionButton && (
              <div className="h-15 flex flex-row items-center justify-between rounded-[8px] bg-[#f0f0f0] p-2.5">
                <div className="flex flex-row items-center gap-2">
                  {ClaimsAndReturnsStatus?.Draft === claimDetails?.status ? (
                    <Button
                      data-autoid={`btnDelete`}
                      className="bg-white"
                      onClick={() => setIsShowDeleteClaimDialog(true)}
                      variant="outlined"
                      color="error"
                    >
                      Delete
                    </Button>
                  ) : (
                    <Button
                      data-autoid={`btnReject`}
                      className="bg-white"
                      onClick={() => setShowRejectClaimDialog(true)}
                      variant="outlined"
                      color="error"
                    >
                      Reject
                    </Button>
                  )}
                </div>
                <div className="ml-2 flex flex-row items-center gap-2">
                  {[
                    ClaimsAndReturnsStatus?.Sent,
                    ClaimsAndReturnsStatus?.Draft,
                    ClaimsAndReturnsStatus?.Reviewed,
                  ]?.includes(claimDetails?.status) && (
                    <Button
                      data-autoid={`btnEdit`}
                      className="bg-white"
                      onClick={() => {
                        const editClaimRoute = AUTH_SELLING_AR_EDIT_CLAIM_REQUESTS_ROUTE?.replace(
                          ':accountCustomerId',
                          String(claimDetails?.accountCustomerID),
                        );

                        history.push(`${editClaimRoute}/${claimDetails?.claimRequestID}`);
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      Edit
                    </Button>
                  )}
                  {[ClaimsAndReturnsStatus?.Sent, ClaimsAndReturnsStatus?.Reviewed]?.includes(claimDetails?.status) && (
                    <Button
                      data-autoid={`btnApprove`}
                      variant="filled"
                      color="primary"
                      onClick={() => setShowApproveClaimDialog(true)}
                    >
                      Approve
                    </Button>
                  )}
                </div>
              </div>
            )}
            <ApproveClaimModal
              isSubmitting={isSubmitting}
              onConfirm={(approveReferenceNo, approvePaymentDate) => {
                handleUpdateClaimRequestStatus(ClaimsAndReturnsStatus?.Approved, {
                  approveReferenceNo,
                  approvePaymentDate,
                });
              }}
              isOpen={showApproveClaimDialog}
              onClose={() => setShowApproveClaimDialog(false)}
            />
            {showRejectClaimDialog && (
              <RejectClaimModal
                isSubmitting={isSubmitting}
                onConfirm={reason => handleUpdateClaimRequestStatus(ClaimsAndReturnsStatus?.Rejected, {reason})}
                isOpen={showRejectClaimDialog}
                onClose={() => setShowRejectClaimDialog(false)}
              />
            )}
            <DeleteClaimModal
              isOpen={isShowDeleteClaimDialog}
              onConfirm={() => handleUpdateClaimRequestStatus(ClaimsAndReturnsStatus.Deleted)}
              onClose={() => setIsShowDeleteClaimDialog(false)}
              isSubmitting={isSubmitting}
            />
            {iSShowUploadAttachmentModal && (
              <EditEvidenceDialog
                isViewOnly
                showModal={iSShowUploadAttachmentModal}
                handleClose={() => setISShowUploadAttachmentModal(false)}
                claimRequestDetails={claimDetails}
                accountCustomerId={accountCustomerId}
              />
            )}
            {isShowInternalNotesDialog && (
              <ClaimsInternalNotesDialog
                handleClose={() => setIsShowInternalNotesDialog(false)}
                claimRequestID={claimDetails.claimRequestID}
                accountCustomerId={accountCustomerId}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
