import React, {useEffect, useState} from 'react';
import {Typography, Tabs, TabsHeader, TabsBody, Tab, TabPanel} from 'spenda-ui-react';
import moment from 'moment';
import {useParams} from 'react-router-dom';

import OrangeInfoIcon from '../../../assets/svg/quotes-management/OrangeInfoIcon';
import WarningIcon from '../../../assets/svg/quotes-management/WarningIcon';
import {SpendaNoTagLogo} from '../../../assets/svg/SpendaNoTagLogo';
import {CustomerQuotePreviewMainPanel} from '../../../components/quote-management/CustomerQuotePreviewMainPanel';
import useQuotesAPI from '../../../services/useQuotesAPI';
import {IActiveSession, ICustomerQuoteDetails, IQuoteSupplier, IQuotesPackage} from '../../../model/quotes/quotes';
import {QuoteStatus} from '../../../model/constants/Constants';
import IconCheckboxChecked from '../../../assets/svg/IconCheckboxChecked';
import {QuoteAcceptedRejectedDialog} from '../../../components/dialog/QuoteAcceptRejectDialog';
import {QuoteExpiredDialog} from '../../../components/dialog/QuoteExpiredDialog';
import {CustomerQuotesAuthorize} from './CustomerQuoteAuthorize';
import {Toast} from '../../../utils/Toast';
import {LockIcon} from '../../../assets/svg';
import {LimepayCheckout} from '../../../components/limepayCheckout/LimepayCheckout';
import {QuoteAmountPayDialog} from '../../../components/dialog';
import {useFlags} from 'launchdarkly-react-client-sdk';
import {LimepayPaymentStatus} from '../../../components/dialog/LimepayPaymentStatus';
import _ from 'lodash';
import {AttachmentType} from '../../../model/FileDetails';

export const CustomerQuotesPreview = () => {
  const {inviteCode} = useParams<{inviteCode: string}>();
  const [quoteDetails, setQuoteDetails] = useState<IQuotesPackage>();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [activeSession, setActiveSession] = useState<IActiveSession>();
  const [tabs, setTabs] = useState<{label: string; value: number}[]>();
  const [supplier, setSupplier] = useState<IQuoteSupplier>();
  const [showApprovedRejectedDialog, setShowApprovedRejectedDialog] = useState<boolean>(false);
  const [isQuoteExpired, setIsQuoteExpired] = useState<boolean>(false);
  const {getQuoteDetailByInviteCode, requestApprovalCode} = useQuotesAPI();
  const [expiryDate, setExpiryDate] = useState('');
  const [showQuoteExpiredDialog, setShowQuoteExpiredDialog] = useState<boolean>(false);
  const [showAuthenticationDialog, setShowAuthenticationDialog] = useState<boolean>(false);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);
  const [showAcceptAuthoriseDialog, setShowAcceptAuthoriseDialog] = useState<boolean>(false);
  const [showSessionTimeoutDialog, setShowSessionTimeoutDialog] = useState<boolean>(false);
  const [showLimepayCheckout, setShowLimepayCheckout] = useState<boolean>(false);
  const {globalLimepay} = useFlags();
  const [isAmountPayDialog, setIsAmountPayDialog] = useState<boolean>(false);
  const [amountToPay, setAmountToPay] = useState<number>();
  const [showLimepayPaymentStatus, setShowLimePaymentStatus] = useState<boolean>(false);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState<boolean>(false);

  async function getQuoteDetails(code: string, showExistingStatusDialog: boolean = true) {
    const quoteDetails: ICustomerQuoteDetails = await getQuoteDetailByInviteCode(code);
    const isQuoteApproved = quoteDetails?.quotePackage?.status === QuoteStatus.Approved;
    const sortedQuotes = _.sortBy(quoteDetails?.quotePackage?.quotes, obj => {
      return obj.status === QuoteStatus.Approved ? 0 : 1;
    });
    quoteDetails.quotePackage.quotes = sortedQuotes;
    setQuoteDetails(quoteDetails.quotePackage);
    setSupplier(quoteDetails?.supplier);
    if (!tabs || isQuoteApproved) {
      const quotes = quoteDetails.quotePackage.quotes?.map((quote, index) => {
        return {label: quote.name, value: index};
      });
      setTabs(quotes);
      if (showExistingStatusDialog) setSelectedTab(0);
    }
    if (
      (isQuoteApproved || quoteDetails?.quotePackage?.status === QuoteStatus.Rejected) &&
      showExistingStatusDialog &&
      !showApprovedRejectedDialog
    ) {
      setShowApprovedRejectedDialog(true);
    }
    const expDate = moment(quoteDetails?.quotePackage?.expiryDate).format('DD MMM YYYY');
    expDate && setExpiryDate(expDate);

    if (expDate && moment().isAfter(expDate, 'day') && quoteDetails?.quotePackage?.status === QuoteStatus.Draft) {
      setIsQuoteExpired(true);
      setShowQuoteExpiredDialog(true);
    }
  }

  useEffect(() => {
    if (quoteDetails?.quoteTermsMediaFileUrl && !quoteDetails?.quoteTermsMediaFileURI) fetchTermsConditions();
  }, [quoteDetails]);

  const fetchTermsConditions = async () => {
    try {
      const response = await fetch(quoteDetails?.quoteTermsMediaFileUrl!);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob(); // Convert the response to a Blob
      const fileName = 'TermsNConditions.pdf';
      const file = new File([blob], fileName, {
        type: AttachmentType.pdf,
      });

      const reader = new FileReader();
      reader.onload = () => {
        // This will be the data URI
        setQuoteDetails({...quoteDetails, quoteTermsMediaFileURI: reader.result as string});
      };

      reader.readAsDataURL(file); // Convert Blob to Data URL
    } catch (error) {
      console.error('Error fetching PDF:', error);
    }
  };

  const handleRequestOTP = async (acceptAuthorise: boolean = false, isSessionTimeout: boolean = false) => {
    requestApprovalCode(inviteCode)
      .then(() => {
        Toast.info('OTP Sent');
        if (acceptAuthorise && !showAcceptAuthoriseDialog && !isSessionTimeout) setShowAcceptAuthoriseDialog(true);
        else if (!acceptAuthorise && !showAuthenticationDialog && !isSessionTimeout) setShowAuthenticationDialog(true);
        else if (isSessionTimeout) setShowSessionTimeoutDialog(true);
      })
      .catch(error => console.error('Error while requesting approval code', error))
      .finally(() => setIsRequesting(false));
  };

  useEffect(() => {
    if (inviteCode) {
      getQuoteDetails(inviteCode);
    }
  }, [inviteCode]);

  const handleAcceptRejectClose = () => {
    setShowApprovedRejectedDialog(false);
  };

  const handleOpenAmountPayDialog = () => {
    setShowApprovedRejectedDialog(false);
    const approvedQuote =
      quoteDetails?.status === QuoteStatus.Approved &&
      quoteDetails?.quotes?.find(q => q.status === QuoteStatus.Approved);
    const isDepositPaid =
      approvedQuote &&
      (!approvedQuote?.requiredDepositAmount ||
        ((approvedQuote?.requiredDepositAmount || 0) > 0 &&
          (approvedQuote.totalInc || 0) - (approvedQuote.balance || 0) > 0));
    if (globalLimepay && !isDepositPaid) {
      setIsAmountPayDialog(true);
    }
  };

  const handleAmountPay = () => {
    setIsAmountPayDialog(false);
    const approvedQuote =
      quoteDetails?.status === QuoteStatus.Approved &&
      quoteDetails?.quotes?.find(q => q.status === QuoteStatus.Approved);
    const isDepositPaid =
      approvedQuote &&
      (!approvedQuote?.requiredDepositAmount ||
        ((approvedQuote?.requiredDepositAmount || 0) > 0 &&
          (approvedQuote.totalInc || 0) - (approvedQuote.balance || 0) > 0));
    if (globalLimepay && !isDepositPaid) {
      setShowLimepayCheckout(true);
    }
  };

  const renderStatuses = (value: number) => {
    return (
      <>
        {quoteDetails?.quotes &&
        quoteDetails?.quotes?.length > 1 &&
        quoteDetails?.status === QuoteStatus.Draft &&
        !isQuoteExpired ? (
          <div className="mb-3 mt-1 w-full rounded-md bg-[#F4E8D1] p-3 lg:h-[115px] lg:max-w-[231px]">
            <div className="mb-3 flex flex-row items-center">
              <OrangeInfoIcon />
              <Typography className="ml-2 font-medium" variant="small">
                Multiple quotes attached
              </Typography>
            </div>
            <Typography className="mb-3" variant="xsmall">
              There are {quoteDetails?.quotes?.length} quotes in this package, please review them before accepting one,
              the remaining will be automatically rejected.
            </Typography>
          </div>
        ) : (
          <div className="h-[1px] min-w-[230px]"></div>
        )}
        {quoteDetails?.quotes?.[value]?.status === QuoteStatus.Approved && (
          <div className="h-[81px] w-full rounded-md bg-[#BBDBED] p-3 lg:min-w-[220px] lg:max-w-[270px]">
            <div className="mb-3 flex flex-row items-center">
              <IconCheckboxChecked />
              <Typography className="ml-2 font-medium" variant="small">
                Quote Approved
              </Typography>
            </div>
            <Typography className="mb-3" variant="xsmall">
              {quoteDetails?.quotes?.[value]?.approvedByBusinessContactName ||
                quoteDetails?.quotes?.[value]?.approvedByUserName}{' '}
              has approved this quote.
            </Typography>
          </div>
        )}
        {quoteDetails?.quotes?.[value]?.status === QuoteStatus.Rejected && (
          <div className="w-full rounded-md bg-[#F3DFDA] p-3 lg:max-h-[107px] lg:min-w-[220px] lg:max-w-[270px]">
            <div className="mb-3 flex flex-row items-center">
              <WarningIcon />
              <Typography className="ml-2 font-medium" variant="small">
                Quote Rejected
              </Typography>
            </div>
            {quoteDetails?.quotes?.[value]?.rejectReason && (
              <Typography className="mb-3" variant="xsmall">
                Customer reason: {quoteDetails?.quotes?.[value]?.rejectReason}
              </Typography>
            )}
          </div>
        )}
        {isQuoteExpired && quoteDetails?.status === QuoteStatus.Draft && (
          <div className="w-full rounded-md bg-[#F3DFDA] p-3  lg:max-h-[107px] lg:min-w-[220px] lg:max-w-[270px]">
            <div className="mb-3 flex flex-row items-center">
              <WarningIcon />
              <Typography className="ml-2 font-medium" variant="small">
                Quote Expired
              </Typography>
            </div>
            <Typography className="mb-3" variant="xsmall">
              This quote package expired on {expiryDate}, the price of the package is no longer valid.
            </Typography>
          </div>
        )}
        {activeSession?.isAuthorised && !isQuoteExpired && quoteDetails?.status === QuoteStatus.Draft && (
          <div className="mt-3 w-full rounded-md bg-[#F3DFDA] p-3 lg:h-[115px] lg:max-w-[231px]">
            <div className="mb-3 flex flex-row items-center">
              <WarningIcon />
              <Typography className="ml-2 font-medium" variant="small">
                Act Before {expiryDate}
              </Typography>
            </div>
            <Typography className="mb-3" variant="xsmall">
              This quote package is set to expire on the {expiryDate}. Please be aware that after this date, the price
              of the package may no longer be valid.
            </Typography>
          </div>
        )}
      </>
    );
  };

  const handleLimepayClose = (showStatus: boolean, isSuccessful: boolean) => {
    setShowLimepayCheckout(false);
    setShowLimePaymentStatus(showStatus);
    setIsPaymentSuccessful(isSuccessful);
    if (isSuccessful) {
      getQuoteDetails(inviteCode, false);
    }
  };

  return (
    <div className="shadow-[0px_0px_6px_0px_rgba(0,0,0,.6)'] relative flex  h-screen w-full rounded bg-white">
      {showAuthenticationDialog || showAcceptAuthoriseDialog ? (
        <CustomerQuotesAuthorize
          onClose={() =>
            showAuthenticationDialog ? setShowAuthenticationDialog(false) : setShowAcceptAuthoriseDialog(false)
          }
          handleResendCode={handleRequestOTP}
          quoteName={quoteDetails?.quotes?.map(quote => quote.name)}
          fromRevealClick={showAuthenticationDialog}
          abn={supplier?.abn}
          logo={supplier?.logoUrl}
          supplierName={supplier?.name}
          inviteCode={inviteCode}
          setActiveSession={setActiveSession}
          getQuoteDetails={getQuoteDetails}
          selectedQuoteGUID={quoteDetails?.quotes?.[selectedTab]?.quoteGUID!}
          inviteSource={quoteDetails?.invite?.toPhone || quoteDetails?.invite?.toEmailAddress || ''}
          quoteTermsMediaFileURI={quoteDetails?.quoteTermsMediaFileURI}
        />
      ) : (
        <>
          <div className="w-full overflow-y-auto pb-12 pt-2.5">
            <div className="flex w-full justify-center">
              <Tabs value={selectedTab} className="flex w-full flex-col items-center">
                <div className="fixed right-[5px] top-0 z-50 flex w-full items-center justify-center bg-white p-2 pl-3 sm:px-4 sm:py-5 lg:right-auto">
                  <div className="w-full max-w-[750px]">
                    <TabsHeader
                      className="h-10 bg-[#ECECEC]"
                      indicatorProps={{
                        className: 'bg-spenda-sBlue shadow-none !text-white',
                      }}
                    >
                      {tabs?.map(({label, value}) => (
                        <Tab
                          key={value}
                          value={value}
                          className={selectedTab === value ? 'font-medium text-white' : 'font-medium'}
                          onClick={() => setSelectedTab(value)}
                        >
                          {label}
                        </Tab>
                      ))}
                    </TabsHeader>
                  </div>
                </div>
                <TabsBody className="mt-10 overflow-y-auto pb-4 sm:mt-14 sm:pb-7">
                  {tabs?.map(({value}) => (
                    <TabPanel key={value} value={value} className="w-full p-2 sm:p-4">
                      <div
                        className={`flex h-full w-full flex-col justify-center 2xl:flex-row ${activeSession?.isAuthorised || quoteDetails?.status !== QuoteStatus.Draft || isQuoteExpired ? '2xl:ml-[142px]' : '2xl:ml-[20px]'}`}
                      >
                        {!activeSession?.isAuthorised &&
                          quoteDetails?.status === QuoteStatus.Draft &&
                          !isQuoteExpired && (
                            <div className="mr-5 w-full rounded-md bg-[#BBDBED] p-3 lg:mt-[100px] lg:h-[147px] lg:max-w-[231px]">
                              <div className="mb-3 flex flex-row items-center">
                                <LockIcon />
                                <Typography className="ml-2 font-medium" variant="small">
                                  Security validation alert
                                </Typography>
                              </div>
                              <Typography className="mb-3" variant="xsmall">
                                For security purposes, we've minimised the visibility of your personal information. To
                                unveil it, please click on ‘Reveal’.
                              </Typography>
                              <div className="flex justify-center">
                                <button
                                  disabled={isRequesting}
                                  color="primary"
                                  data-autoid={`btnReveal-${quoteDetails?.quotes?.[value].quoteID}`}
                                  className="align-center h-[27px] w-[65px] justify-center rounded-md border-[1px] border-[#1C76AD] bg-white text-[10px] font-semibold text-primary"
                                  onClick={() => {
                                    setIsRequesting(true);
                                    handleRequestOTP();
                                  }}
                                >
                                  Reveal
                                </button>
                              </div>
                            </div>
                          )}
                        <div className="lg:hidden">{renderStatuses(value)}</div>
                        <CustomerQuotePreviewMainPanel
                          activeSession={activeSession}
                          quoteVariantsCount={quoteDetails?.quotes?.length}
                          inviteCode={inviteCode}
                          quoteVariant={quoteDetails?.quotes?.[value]!}
                          getQuoteDetails={getQuoteDetails}
                          supplier={supplier}
                          quotePackage={quoteDetails}
                          isQuoteExpired={isQuoteExpired}
                          handleRequestOTP={handleRequestOTP}
                          showSessionTimeoutDialog={showSessionTimeoutDialog}
                          setShowSessionTimeoutDialog={setShowSessionTimeoutDialog}
                          setActiveSession={setActiveSession}
                          handleAcceptRejectClose={globalLimepay ? handleOpenAmountPayDialog : handleAcceptRejectClose}
                          globalLimepay={globalLimepay}
                          quoteTermsMediaFileURI={quoteDetails?.quoteTermsMediaFileURI}
                        />
                        <div className="mr-7 hidden flex-col 2xl:flex">{renderStatuses(value)}</div>
                      </div>
                    </TabPanel>
                  ))}
                </TabsBody>
              </Tabs>
            </div>
          </div>
          <div className="absolute bottom-0 z-50 flex h-12 w-[100vw] items-center justify-center bg-[#eeeeee]">
            {supplier?.logoUrl ? (
              <img src={supplier?.logoUrl} alt="Tenant Logo" className={`h-[32px]`} />
            ) : (
              <SpendaNoTagLogo height="32px" />
            )}
          </div>
        </>
      )}
      {showApprovedRejectedDialog && (
        <QuoteAcceptedRejectedDialog
          open={showApprovedRejectedDialog}
          isAccepted={quoteDetails?.status === QuoteStatus.Approved}
          handleOpen={handleOpenAmountPayDialog}
          handleClose={handleAcceptRejectClose}
          quoteName={quoteDetails?.quotes?.find(quote => quote.status === QuoteStatus.Approved)?.name}
        />
      )}
      {isAmountPayDialog && (
        <QuoteAmountPayDialog
          open={isAmountPayDialog}
          quoteDetails={quoteDetails?.quotes?.find(q => q.status === QuoteStatus.Approved)!}
          handleOpenPaymentWidget={handleAmountPay}
          handleClose={() => setIsAmountPayDialog(false)}
          setAmountToPay={setAmountToPay}
        />
      )}
      {showQuoteExpiredDialog && <QuoteExpiredDialog expiryDate={expiryDate} handleClose={setShowQuoteExpiredDialog} />}
      {showLimepayCheckout && quoteDetails?.status === QuoteStatus.Approved && amountToPay && (
        <LimepayCheckout
          open={showLimepayCheckout}
          inviteCode={inviteCode}
          amountToPay={amountToPay}
          handleLimepayClose={handleLimepayClose}
          isQuote
        />
      )}
      {showLimepayPaymentStatus && (
        <LimepayPaymentStatus
          open={showLimepayPaymentStatus}
          isSuccessful={isPaymentSuccessful}
          handleClose={setShowLimePaymentStatus}
          amountToPay={amountToPay!}
        />
      )}
    </div>
  );
};
