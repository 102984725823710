import * as React from 'react';
import {SVGProps} from 'react';

const IconSetupInventory = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 11.667h5a.833.833 0 1 0 0-1.667h-5a.833.833 0 1 0 0 1.667Z"
      className="fill-primary"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.833 0H4.167A4.172 4.172 0 0 0 0 4.167V5a2.5 2.5 0 0 0 .833 1.862v8.971A4.172 4.172 0 0 0 5 20h10a4.172 4.172 0 0 0 4.167-4.167V6.862A2.5 2.5 0 0 0 20 5v-.833A4.172 4.172 0 0 0 15.833 0ZM1.667 4.167a2.5 2.5 0 0 1 2.5-2.5h11.666a2.5 2.5 0 0 1 2.5 2.5V5c0 .46-.373.833-.833.833h-15A.833.833 0 0 1 1.667 5v-.833ZM17.5 15.833a2.5 2.5 0 0 1-2.5 2.5H5a2.5 2.5 0 0 1-2.5-2.5V7.5h15v8.333Z"
      className="fill-primary"
    />
  </svg>
);

export default IconSetupInventory;
