import React, {useContext, useState} from 'react';
import {PaymentOptionsSettings} from './PaymentOptionsSettings';
import {Typography} from 'spenda-ui-react';
import {PostingConfigurationView} from '../ARsettingsView/PostingConfigurationsView';
import {MarketplaceLayout} from '../../purchasing/MarketplaceLayout';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {PSBLFooter} from '../../../components/AccountsReceivable/PSBLFooter';
import {APXeroAdaptorInvoices} from '../../purchasing/APXeroAdaptorInvoices';
import {XeroInvoicesDetails} from '../../../components/accountsPayableOnboarding/XeroInvoicesDetails';
import {IntegrationContext} from '../../../context/IntegrationContext';
import {usePSBLHook} from '../../../hooks/useARHook';
import {PreffixSuffix} from '../ARsettingsView/CustomPrefixSuffix';
import {SupplierManagement} from '../ARsettingsView/SupplierManagement';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';

enum PSBLSettingTabs {
  PostingBehaviourView = 'PostingBehaviourView',
  AdaptorView = 'AdaptorView',
  PaymentOptionsView = 'PaymentOptionView',
  CustomPrefixSuffix = 'CustomPrefixSuffix',
  SupplierManagement = 'SupplierManagement',
}

export const PSBLSettingsV2 = () => {
  // Hooks
  const history = useHistory();
  const {search, pathname} = useLocation();
  const {isPaymentOptionAvailable} = usePSBLHook();
  const {supplierId: sId} = useParams<{supplierId?: string}>();

  // State
  const [resetCount, setResetCount] = useState(0);

  // Context
  const {isAPFinancialAdaptorSetup, financialAdaptorDisplayName} = useContext(IntegrationContext);
  const {PSBLSTAGING} = useFeatureFlags().supplierDriven();

  // Constant
  const searchParams = new URLSearchParams(search);
  const selectedTab = searchParams.get('tab') || PSBLSettingTabs.PostingBehaviourView;
  const adaptorLogId = searchParams.get('adaptorLogId');

  const settingTabs = [
    {id: 'btnPostingConfiguration', label: 'Posting configurations', tab: PSBLSettingTabs.PostingBehaviourView},
  ];

  if (isAPFinancialAdaptorSetup()) {
    settingTabs.splice(settingTabs?.length, 0, {
      id: 'btnXeroAdaptor',
      label: `${financialAdaptorDisplayName} adaptor`,
      tab: PSBLSettingTabs.AdaptorView,
    });
  }

  if (isPaymentOptionAvailable) {
    settingTabs.push({id: 'btnPaymentOptions', label: 'Payment options', tab: PSBLSettingTabs.PaymentOptionsView});
  }

  settingTabs.push({
    id: 'btnPrefixSettings',
    label: 'Invoice Matching Settings',
    tab: PSBLSettingTabs.CustomPrefixSuffix,
  });

  if (PSBLSTAGING) {
    settingTabs.push({
      id: 'btnSupplierManagement',
      label: 'Supplier management',
      tab: PSBLSettingTabs.SupplierManagement,
    });
  }

  const handleCancelClickBtn = () => {
    setResetCount(p => p + 1);
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case PSBLSettingTabs.PostingBehaviourView:
        return (
          <PostingConfigurationView
            key={`PostingConfigurationView${resetCount}`}
            supplierId={Number(sId)}
            onClickCancelBtn={handleCancelClickBtn}
          />
        );
      case PSBLSettingTabs.AdaptorView:
        if (!isAPFinancialAdaptorSetup()) return;
        if (adaptorLogId) return <XeroInvoicesDetails arAdaptorLogId={adaptorLogId} scope="PSBL" />;
        return <APXeroAdaptorInvoices scope="PSBL" />;
      case PSBLSettingTabs.PaymentOptionsView:
        if (isPaymentOptionAvailable) return <PaymentOptionsSettings isInSettingPage />;
      case PSBLSettingTabs.CustomPrefixSuffix:
        return <PreffixSuffix key={`PreffixSuffix${resetCount}`} onClickCancelBtn={handleCancelClickBtn} />;
      case PSBLSettingTabs.SupplierManagement:
        if (PSBLSTAGING)
          return <SupplierManagement key={`SupplierManagement${resetCount}`} onClickCancelBtn={handleCancelClickBtn} />;
      default:
        return null;
    }
  };

  const redirectToTabView = (tab?: PSBLSettingTabs) => {
    if (tab) {
      searchParams.set('tab', tab);
    } else {
      searchParams.delete('tab');
    }
    searchParams.delete('adaptorLogId');
    history.push({pathname, search: searchParams?.toString()});
  };

  const settingsTabs = (
    <div
      style={{boxShadow: '0px 0px 6px 0px #D3E5EF'}}
      className={`h-full w-full rounded-[6px] bg-white py-8 !font-poppins`}
    >
      <Typography variant="h2" className="mb-2 px-4 text-lg font-medium text-[#BBBBBB]">
        Settings
      </Typography>
      <div className="w-full pb-8">
        {settingTabs.map(({id, label, tab}) => (
          <button
            key={id}
            data-autoid={id}
            type="button"
            id={id}
            className={`${selectedTab === tab || (!selectedTab && !tab) ? 'bg-primary/10' : 'bg-white'} h-[40px] w-full px-4 text-left text-base font-medium text-black-800`}
            onClick={() => redirectToTabView(tab)}
          >
            {label}
          </button>
        ))}
      </div>
    </div>
  );

  let mainPanel = (
    <>
      <div
        style={{boxShadow: '0px 0px 6px 0px #D3E5EF'}}
        className={`flex h-full w-full rounded-md bg-white px-2.5 pt-2.5 font-poppins`}
      >
        {renderTabContent()}
      </div>
    </>
  );

  return (
    <>
      <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
        <MarketplaceLayout leftPanel={settingsTabs} mainPanel={mainPanel} splitWidthType={6} />
        <PSBLFooter />
      </div>
    </>
  );
};
