import React, {useState} from 'react';
import {Chip, Typography} from 'spenda-ui-react';
import {AlertDialogSlideV2} from '../dialog/AlertDialogSlideV2';
import {camelCase, upperCase} from 'lodash';
import PostingInventoryIcon from '../../assets/svg/accounts-payable/PostingInventoryIcon';

interface ISelectVariantDialog {
  handleCancel: () => void;
  handleAdd: () => void;
  item?: any;
}

interface ISelectVariantSize {
  content?: string;
  handleSelectSize?: () => void;
  isSelected?: boolean;
}

interface ISelectVariantColor {
  imgUrl?: string;
  handleSelectColor?: () => void;
  isSelected?: boolean;
  productDetails?: {
    color: string;
    isAvailable?: boolean;
    noOfAvailableItems?: string;
    priceChange?: string;
  };
}

const SelectVariantSize: React.FC<ISelectVariantSize> = ({content, handleSelectSize, isSelected}) => (
  <div
    className={`flex h-[32px] max-w-[78px] cursor-pointer items-center justify-center rounded-md border p-5 px-6 ${isSelected ? 'border-primary' : 'border-[#ECECEC] bg-spenda-cream'}`}
    onClick={handleSelectSize}
  >
    <Typography className="text-center font-medium text-black-800" data-autoid={`txt${upperCase(camelCase(content))}`}>
      {content}
    </Typography>
  </div>
);

const SelectVariantColor: React.FC<ISelectVariantColor> = ({imgUrl, handleSelectColor, isSelected, productDetails}) => (
  <div className="flex flex-col items-center">
    <div
      className={`flex h-[108px] max-w-[108px] cursor-pointer items-center justify-center rounded-md bg-spenda-cream p-4 ${isSelected ? 'border border-primary' : ''}`}
      onClick={handleSelectColor}
    >
      <img src={imgUrl} alt="Color" className="w-30 h-20 rounded-md" data-autoid={`imgColor`} />,
    </div>
    {productDetails && (
      <div className="flex flex-col">
        <Typography
          className="mt-2 text-center font-medium text-black-800"
          data-autoid={`txt${upperCase(camelCase(productDetails.color))}`}
        >
          {productDetails.color}
        </Typography>
        <Typography
          variant="paragraph"
          className={`text-center ${productDetails.isAvailable ? 'text-spenda-labeltext' : 'text-spenda-error'}`}
        >
          {productDetails.noOfAvailableItems}
        </Typography>
        {productDetails.priceChange && (
          <Typography variant="small" className="font-medium text-spenda-sGreen">
            {productDetails.priceChange}
          </Typography>
        )}
      </div>
    )}
  </div>
);

const DividerWithText: React.FC<{text: string}> = ({text}) => (
  <div className="relative flex w-full items-center justify-center" data-autoid={`divider-${text}`}>
    <div className="absolute bg-white px-2 font-medium text-spenda-labeltext">{text}</div>
    <div className="h-[1px] w-full border border-[#ECECEC]" />
  </div>
);

// The below static data will be replaced by api response later
const productData = {
  product: {
    imageUrl: 'https://i.ibb.co/nPV9Tfb/Bitmap.png',
    category: 'Category Name',
    name: 'Lightweight College Backpack - 210A0372',
    masterCode: 'Master Code- Master2',
    price: '$184.95',
  },
  sizes: [
    {content: '35 L', id: 1},
    {content: '50 L', id: 2},
    {content: '80 L', id: 3},
  ],
  colors: [
    {
      imageUrl: 'https://i.ibb.co/nPV9Tfb/Bitmap.png',
      id: 1,
      productDetails: {
        color: 'Blue',
        isAvailable: true,
        noOfAvailableItems: '10 left',
        priceChange: '+$1.00 (ex)',
      },
    },
    {
      imageUrl: 'https://i.ibb.co/nPV9Tfb/Bitmap.png',
      id: 2,
      productDetails: {
        color: 'Red',
        isAvailable: true,
        noOfAvailableItems: '10 left',
      },
    },
    {
      imageUrl: 'https://i.ibb.co/nPV9Tfb/Bitmap.png',
      id: 3,
      productDetails: {
        color: 'Gray',
        isAvailable: false,
        noOfAvailableItems: 'Out of stock',
      },
    },
  ],
};

export const SelectVariantDialog: React.FC<ISelectVariantDialog> = ({handleCancel, handleAdd, item}) => {
  const [selectedSize, setSelectedSize] = useState<number | null>(null);
  const [selectedColor, setSelectedColor] = useState<number | null>(null);

  const handleSelectSize = (index: number) => {
    setSelectedSize(index);
    setSelectedColor(null); // Reset color selection when size is changed
  };

  const handleSelectColor = (index: number) => setSelectedColor(index);

  return (
    <AlertDialogSlideV2
      headingClassess="border-b border-[#EAEAEA] flex items-center justify-center"
      dialogActionsAlignment="justify-between"
      dialogBodyClassess="flex max-h-[700px] flex-col justify-center gap-3 overflow-y-scroll"
      dialogClassess="!min-w-[948px] "
      data-autoid="dlgSelectVariant"
      actions={[
        {
          label: 'Cancel',
          variant: 'outlined',
          action: handleCancel,
        },
        {
          label: 'Add',
          action: handleAdd,
          disabled: selectedSize === null || selectedColor === null,
        },
      ]}
      title="Select variant"
    >
      <div className="mx-10 my-4 flex h-[550px] flex-col gap-12">
        <div className="!mt-4 flex items-center justify-between">
          <div className="flex items-center gap-4">
            <img
              src={item?.imageUrl || productData.product.imageUrl}
              alt="Product Image"
              className="h-20 w-20"
              data-autoid="imgProductImage"
            />
            <div className="flex flex-col gap-2">
              <Chip
                value={item?.category || productData.product.category}
                color="gray"
                className="flex h-fit max-w-[100px] justify-center p-2 leading-none text-spenda-primarytext"
                data-autoid="chipCategory"
              />
              <div className="flex gap-3">
                <Typography variant="h3" className="text-black" data-autoid="txtProductName">
                  {item?.ShortDescription || productData.product.name}
                </Typography>
                <PostingInventoryIcon
                  className="ml-2.5 cursor-pointer text-spenda-primarytext"
                  data-autoid={'imgPostingInventory'}
                />
              </div>
              <Typography variant="paragraph" className="text-spenda-labeltext" data-autoid="txtProductMasterCode">
                {item?.InventoryCode || productData.product.masterCode}
              </Typography>
            </div>
          </div>
          <div className="flex flex-col items-center justify-end gap-1">
            <Typography variant="paragraph" className="text-spenda-labeltext" data-autoid="txtProductPrice">
              Price
            </Typography>
            <Typography variant="h3" className="text-black" data-autoid="txtProductPrice">
              {item?.StandardSellPriceEx ? `$${item.StandardSellPriceEx}` : productData.product.price}
            </Typography>
          </div>
        </div>

        <DividerWithText text="Choose your size" />

        <div className="flex flex-wrap gap-4">
          {productData.sizes.map(size => (
            <SelectVariantSize
              key={size.id}
              content={size.content}
              handleSelectSize={() => handleSelectSize(size.id)}
              isSelected={selectedSize === size.id}
            />
          ))}
        </div>

        {selectedSize !== null && (
          <>
            <DividerWithText text="Choose your color" />

            <div className="flex flex-wrap gap-4">
              {productData.colors.map(color => (
                <SelectVariantColor
                  key={color.id}
                  imgUrl={color.imageUrl}
                  handleSelectColor={() => handleSelectColor(color.id)}
                  isSelected={selectedColor === color.id}
                  productDetails={color.productDetails}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </AlertDialogSlideV2>
  );
};
