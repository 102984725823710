import React, {useEffect, useMemo, useState} from 'react';
import {Option, Select, SelectProps} from 'spenda-ui-react';
import {IAccounts} from '../../../model/accounts/Accounts';
import useAccountAPI from '../../../services/useAccountAPI';

interface ISelectCOADropdownProps {
  value?: string;
  onChange: (value: string) => void;
  refetchcount?: number;
  isUsingForRoundingAdjustment?: boolean;
  type?: string;
  defaultPlaceholder?: string;
}

export const SelectCOADropdown = (
  props: ISelectCOADropdownProps & Omit<SelectProps, 'value' | 'ref' | 'children' | 'onChange'>,
) => {
  const {value, isUsingForRoundingAdjustment, refetchcount, defaultPlaceholder, type} = props;

  // State
  const [clearingAccounts, setClearingAccounts] = useState<IAccounts[]>([]);

  // APIs
  const {getCreditlineAccounts} = useAccountAPI();

  useEffect(() => {
    getChartAccounts();
  }, []);

  useEffect(() => {
    if (refetchcount) {
      getChartAccounts();
    }
  }, [refetchcount]);

  const getChartAccounts = () => {
    try {
      const query = {Types: type ? type : 'Current_Liability'};
      getCreditlineAccounts(query).then((data: IAccounts[]) => {
        setClearingAccounts(data);
      });
    } catch {}
  };

  const options = useMemo(
    () =>
      clearingAccounts?.map(({Name, ID, Code}) => ({
        optionName: `${Code} - ${Name}`,
        value: isUsingForRoundingAdjustment ? `${Code}` : ID.toString(),
      })),
    [clearingAccounts],
  );

  return (
    <Select
      containerProps={{className: 'relative !overflow-visible'}}
      menuProps={{className: '!max-h-[200px]'}}
      selected={() => options?.find(c => c?.value === value)?.optionName || defaultPlaceholder}
      {...props}
    >
      {!options?.length ? (
        <Option disabled key={'noResults'}>
          No results
        </Option>
      ) : (
        options?.map(({optionName, value}) => (
          <Option key={value} value={value}>
            {optionName}
          </Option>
        ))
      )}
    </Select>
  );
};
