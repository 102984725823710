import React, {PropsWithChildren} from 'react';
import {makeStyles} from '@material-ui/core';

import {ConversationChatBox} from '../conversationChatBox/ConversationChatBox';
import clsx from 'clsx';
import {VisibleContent} from '../ui/VisibleContent';

const useWidgetPanelStyles = makeStyles(() => ({
  chatContainer: {
    '& .MuiExpansionPanelDetails-root': {
      padding: '0 !important',
    },
    '& .MuiExpansionPanelSummary-root': {
      padding: '0 !important',
      paddingRight: '16px !important',
      backgroundColor: '#e9f2f7',
      borderRadius: '12px 12px 0px 0px',
    },
    '& .MuiExpansionPanelSummary-content': {
      margin: '0 !important',
    },
    '& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
}));

interface WidgetPanelProps {
  showChatBox?: boolean;
  children?: React.ReactNode;
}

export const WidgetPanel = (props: PropsWithChildren<WidgetPanelProps>) => {
  const {showChatBox = true, children} = props;

  // Styles
  const classes = useWidgetPanelStyles();

  return (
    <div className={clsx(`${classes.chatContainer}`, `relative flex h-full w-full justify-center rounded-md bg-white`)}>
      {children}
      <VisibleContent keyPath="accountsReceivable.widgetPanel.conversationChatBox">
        {showChatBox && (
          <div className="absolute bottom-0 z-20 flex w-full">
            <ConversationChatBox />
          </div>
        )}
      </VisibleContent>
    </div>
  );
};
