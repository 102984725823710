import * as React from 'react';
import {SVGProps} from 'react';

const ReconcilingPeriodStepImage = (props: SVGProps<SVGSVGElement>) => (
  <svg width={140} height={140} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.6}
      d="M8.319 23.036c1.434-7.122 19.08-17.877 36.59-4.606 17.51 13.272 23.594 14.672 37.36 0 10.2-10.87 30.593-6.355 41.047 1.722 10.455 8.077 11.036 28.846 4.066 36.347-9.114 9.807-27.693-4.85-22.517 35.373.84 6.527 1.124 19.72-4.896 28.519-7.17 10.48-17.927 17.911-34.88 7.829-22.163-13.181-9.98-31.652-28.692-42.78-12.474-7.42-15.881 2.212-24.593-4.711C8.319 70.344 13.23 70.18 22.839 53.036c8.407-15-18.005-12.692-14.52-30Z"
      fill="#D6EAF3"
    />
    <path
      d="M39.422 43.568h53.437a5.938 5.938 0 0 1 5.938 5.938v42.156a5.938 5.938 0 0 1-5.938 5.938H39.422a5.938 5.938 0 0 1-5.938-5.938V49.506a5.937 5.937 0 0 1 5.938-5.938Z"
      fill="#FAFAFA"
    />
    <path
      d="M92.86 43.568H39.421a5.937 5.937 0 0 0-5.938 5.938v5.937h65.313v-5.937a5.938 5.938 0 0 0-5.938-5.938Z"
      fill="#7FC0A6"
    />
    <path
      d="M42.984 63.756h8.313v8.312h-8.313v-8.312ZM61.984 63.756h8.313v8.312h-8.313v-8.312ZM80.984 63.756h8.313v8.312h-8.313v-8.312ZM42.984 80.38h8.313v8.313h-8.313v-8.312ZM61.984 80.38h8.313v8.313h-8.313v-8.312Z"
      fill="#BBDBED"
    />
    <circle cx={92.117} cy={86.17} r={14.398} fill="#DDB975" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M100.281 49.506V72.55C104.902 75.33 108 80.395 108 86.17c0 8.758-7.125 15.883-15.883 15.883a15.794 15.794 0 0 1-9.235-2.969h-43.46c-4.092 0-7.422-3.33-7.422-7.422V49.506c0-4.093 3.33-7.422 7.422-7.422h.594v-2.672a1.484 1.484 0 0 1 2.968 0v2.672h13.36v-2.672a1.484 1.484 0 1 1 2.968 0v2.672h13.36v-2.672a1.484 1.484 0 1 1 2.969 0v2.672H89v-2.672a1.484 1.484 0 0 1 2.969 0v2.672h.89c4.093 0 7.422 3.33 7.422 7.422Zm-65.312 7.422v34.734a4.458 4.458 0 0 0 4.453 4.453h40.321a15.806 15.806 0 0 1-3.509-9.945c0-5.138 2.453-9.712 6.248-12.617h-1.498c-.82 0-1.484-.665-1.484-1.485v-8.312c0-.82.665-1.484 1.484-1.484h8.313c.82 0 1.484.664 1.484 1.484v6.588a15.813 15.813 0 0 1 6.531.817V56.928H72.82a1.484 1.484 0 1 1 0-2.969h24.493v-4.453a4.458 4.458 0 0 0-4.454-4.453h-.89v2.672a1.484 1.484 0 0 1-2.969 0v-2.672H75.64v2.672a1.484 1.484 0 1 1-2.968 0v-2.672h-13.36v2.672a1.484 1.484 0 0 1-2.968 0v-2.672h-13.36v2.672a1.484 1.484 0 0 1-2.968 0v-2.672h-.594a4.458 4.458 0 0 0-4.453 4.453v4.453H59.46a1.484 1.484 0 1 1 0 2.969H34.969Zm52.843 13.656H82.47V65.24h5.344v5.344ZM79.204 86.17c0 7.12 5.793 12.914 12.914 12.914 7.121 0 12.914-5.793 12.914-12.914 0-7.12-5.793-12.914-12.914-12.914-7.12 0-12.914 5.793-12.914 12.914Z"
      fill="#333"
    />
    <path
      d="M92.117 81.412c1.078 0 1.955.734 1.955 1.637a1.484 1.484 0 0 0 2.969 0c0-2.056-1.448-3.801-3.44-4.392v-.206a1.484 1.484 0 0 0-2.968 0v.207c-1.992.59-3.44 2.335-3.44 4.39 0 2.54 2.21 4.606 4.924 4.606 1.078 0 1.955.735 1.955 1.637 0 .903-.877 1.637-1.955 1.637s-1.955-.734-1.955-1.637a1.484 1.484 0 0 0-2.968 0c0 2.056 1.447 3.8 3.439 4.391v.207a1.484 1.484 0 1 0 2.969 0v-.207c1.991-.59 3.439-2.335 3.439-4.39 0-2.54-2.209-4.606-4.924-4.606-1.078 0-1.955-.735-1.955-1.637 0-.903.877-1.637 1.955-1.637Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.984 62.272h8.313c.82 0 1.484.664 1.484 1.484v8.312c0 .82-.664 1.485-1.484 1.485h-8.313c-.82 0-1.484-.665-1.484-1.485v-8.312c0-.82.665-1.484 1.484-1.484Zm1.485 8.312h5.343V65.24H44.47v5.344ZM61.984 62.272h8.313c.82 0 1.484.664 1.484 1.484v8.312c0 .82-.664 1.485-1.484 1.485h-8.313c-.82 0-1.484-.665-1.484-1.485v-8.312c0-.82.665-1.484 1.484-1.484Zm1.485 8.312h5.343V65.24H63.47v5.344ZM42.984 78.897h8.313c.82 0 1.484.664 1.484 1.484v8.312c0 .82-.664 1.485-1.484 1.485h-8.313c-.82 0-1.484-.665-1.484-1.485v-8.312c0-.82.665-1.484 1.484-1.484Zm1.485 8.312h5.343v-5.344H44.47v5.344ZM61.984 78.897h8.313c.82 0 1.484.664 1.484 1.484v8.312c0 .82-.664 1.485-1.484 1.485h-8.313c-.82 0-1.484-.665-1.484-1.485v-8.312c0-.82.665-1.484 1.484-1.484Zm1.485 8.312h5.343v-5.344H63.47v5.344Z"
      fill="#333"
    />
    <path
      d="M66.14 56.928c.391 0 .774-.16 1.05-.435.276-.276.435-.66.435-1.05s-.159-.773-.435-1.05a1.496 1.496 0 0 0-1.05-.434c-.39 0-.773.159-1.049.435-.276.276-.435.659-.435 1.05 0 .39.16.773.435 1.049.276.276.66.435 1.05.435Z"
      fill="#333"
    />
  </svg>
);

export default ReconcilingPeriodStepImage;
