import {Box, useMediaQuery} from '@material-ui/core';
import React, {PropsWithChildren, useContext, useEffect, useState} from 'react';
import {SButton} from '../../components/buttons/Button';
import {css} from 'glamor';
import {useAuth0} from '@auth0/auth0-react';
import AppContext from '../../context/app/appContext';
import {useHistory} from 'react-router-dom';
import {IInvoiceInvitationDetails, useSupplierTransactionsAPI} from '../../services/useSupplierTransactionsAPI';
import {PriceFormat, NameInitials} from '../../utils/formatter';
import Skeleton from 'react-loading-skeleton';
import {PayByLinkLayout} from './Layout';
import {InvoiceStatuses} from '../../model/constants/Constants';
import useTenantAPI from '../../services/useTenantAPI';
import {useQuery} from './useQuery';
import {useAuth0Callback} from '../../hooks/useAuth0Callback';
import Shield from '../../assets/svg/Shield';
import {PayByLinkContext} from './PayByLinkContext';
import PayByLinkCNPInvoice from './PayByLinkCNPInvoice';
import {PayByLinkLoader} from './PayByLinkLoader';
import {useFlags} from 'launchdarkly-react-client-sdk';

export const PayByLink = (/*props: RouteChildrenProps<any, IPayByLinkInvoice>*/) => {
  const {globalLimepay} = useFlags();
  const history = useHistory();
  const matches = true;

  const {setInvoiceInvitation} = useContext(AppContext);
  const {setSupplierDetails} = useContext(PayByLinkContext);

  const [isLoadingUser, setIsLoadingUser] = useState(true);

  const {loginWithAuth0Redirect} = useAuth0Callback();
  const {getInvoicePaymentInvitation, isLoading: isLoadingSummary} = useSupplierTransactionsAPI();
  const {matchAndLink} = useTenantAPI();
  const [showCNPFlow, setShowCNPFlow] = useState<boolean>(false);

  const [footerLogo, setFooterLogo] = useState<string>();

  const query = useQuery();
  const code = query.get('code') || '';
  const ssoConnection = query.get('ssoConnection') || '';
  const redirectUrl = query.get('redirectUrl') || '';
  const createClaim = query.get('createClaim') || '';

  const {user, isAuthenticated} = useAuth0();

  useEffect(() => {
    setInvoiceInvitation(undefined);

    async function paymentInvitationProcess(piblCode?: string) {
      if (piblCode) {
        const invoicePaymentInvitationResponse = await getInvoicePaymentInvitation(piblCode);
        if (!invoicePaymentInvitationResponse) {
          history.push('/');
          return;
        }

        const supplierInviteDetails = invoicePaymentInvitationResponse!;
        setSupplierDetails(supplierInviteDetails);
        setShowCNPFlow(supplierInviteDetails.isAprilPaymentsEnabled);
        setFooterLogo(supplierInviteDetails?.logoURL);

        if (isAuthenticated && user) {
          if (!supplierInviteDetails?.isLinkedToSupplier) {
            await matchAndLink(piblCode);
          }

          const redirectPath = `${piblCode}?
          ${redirectUrl ? 'redirectUrl=' + redirectUrl : ''}
          ${createClaim ? '&createClaim=' + createClaim : ''}
          `;

          // remove empty spaces
          const redirectPathClean = redirectPath.replace(/\s/g, '');
          setIsLoadingUser(false);
          redirectAndShowInvoice(redirectPathClean);
          return;
        } else {
          if (redirectUrl || createClaim) {
            setIsLoadingUser(false);
            loadAuth0Login(supplierInviteDetails.isExistingUser, supplierInviteDetails.contact.emailAddress);
          }
        }
        setIsLoadingUser(false);
      }
    }

    paymentInvitationProcess(code);
  }, [code]);

  const loadAuth0Login = (isExistingUser?: boolean, emailAddress?: string) => {
    const redirectUri = `/invoices?code=${code}
    ${redirectUrl ? '&redirectUrl=' + redirectUrl : ''}
    ${createClaim ? '&createClaim=' + createClaim : ''}`;

    // remove empty spaces
    const trimmedRedirectUri = redirectUri.replace(/\s/g, '').trim();

    const redirectLoginOptions = {
      redirectUri: trimmedRedirectUri,
      screen_hint: isExistingUser ? 'login' : 'signup',
      login_hint: emailAddress,
      connection: ssoConnection,
    };

    loginWithAuth0Redirect(redirectLoginOptions);
  };

  const redirectAndShowInvoice = async (magicLink: string) => {
    if (createClaim) {
      history.push(`/invoices/claim/${magicLink}`);
      return;
    }

    history.push(`/invoices/pibl/${magicLink}`);
  };

  return (
    <>
      {isLoadingSummary || isLoadingUser ? (
        <PayByLinkLoader open={isLoadingSummary || isLoadingUser} />
      ) : showCNPFlow && globalLimepay ? (
        <PayByLinkLayout isScrollable footerLogo={footerLogo}>
          <PayByLinkCNPInvoice code={code} />
        </PayByLinkLayout>
      ) : (
        <PayByLinkLayout>
          <PayByLinkSummary
            loadAuth0Login={loadAuth0Login}
            redirectAndShowInvoice={redirectAndShowInvoice}
            isLoadingSummary={isLoadingSummary}
            isLoadingUser={isLoadingUser}
            matches={matches}
            code={code || ''}
            redirectUrl={redirectUrl}
          ></PayByLinkSummary>
        </PayByLinkLayout>
      )}
    </>
  );
};

export interface IPayByLinkSummary {
  loadAuth0Login: (isExistingUser?: boolean, emailAddress?: string) => void;
  isLoadingSummary?: boolean;
  isLoadingUser?: boolean;
  pblSummary?: IInvoiceInvitationDetails;
  matches?: boolean;
  code: string;
  redirectUrl?: string;
  redirectAndShowInvoice: any;
}

const PayByLinkSummary = (props: PropsWithChildren<IPayByLinkSummary>) => {
  const {user} = useContext(AppContext);
  const {supplierDetails: pblSummary} = useContext(PayByLinkContext);

  const {loadAuth0Login, isLoadingSummary, isLoadingUser, matches, code, redirectAndShowInvoice, redirectUrl} = props;
  const [isToggleShowAndHide] = useState(false);

  const payInvoice = async () => {
    if (user) {
      const redirectPath = `${code}?${redirectUrl ? 'redirectUrl=' + redirectUrl : ''}`;
      return redirectAndShowInvoice(redirectPath);
    }

    loadAuth0Login(pblSummary?.isExistingUser, pblSummary?.contact.emailAddress);
  };

  const paidBorderStyle = css({
    borderColor: '#317e60',
    backgroundColor: '#3C9F78',
    color: '#6ae1b2',
  });

  const hasInvoiceBeenPaid = pblSummary?.isExistingUser && pblSummary?.invoiceStatusType === InvoiceStatuses.Paid;
  const width = useMediaQuery('(max-width:390px)');
  return (
    <div
      className={`rounded-default z-10 flex items-center justify-center p-5`}
      style={{backgroundColor: '#FFF', boxShadow: '0px 0px 12px 8px rgba(0,0,0,.05)'}}
    >
      <div
        className={`flex flex-col items-center  ${isToggleShowAndHide ? (matches ? 'hidden' : '') : ''} ${
          !matches ? 'w-1/4' : 'h-full'
        }`}
        style={{width: width ? '80%' : '350px'}}
      >
        <div className="mb-2 ">
          {(!!isLoadingSummary && <Skeleton circle={true} height={'6rem'} width={'6rem'} />) || pblSummary?.logoURL ? (
            <img
              src={pblSummary?.logoURL}
              alt="Supplier Company Logo"
              style={{maxWidth: '17rem', maxHeight: '12rem', margin: '0 auto', borderRadius: '10%'}}
            />
          ) : pblSummary?.supplierName ? (
            <p
              {...css({
                backgroundColor: '#b8b046',
                color: 'black',
                border: '1px solid #fff',
                width: '7rem',
                height: '7rem',
              })}
              className="relative m-auto flex h-8 w-8 items-center justify-center rounded-full pt-1 text-center text-5xl"
            >
              {NameInitials(pblSummary?.supplierName)?.toUpperCase()}
            </p>
          ) : null}
        </div>

        <div className="mb-8 text-center">
          {(!!isLoadingSummary && (
            <Box>
              <Skeleton width="4rem" />
            </Box>
          )) || (
            <h2 className={`font-poppins text-base font-medium text-spenda-headingtext`}>{pblSummary?.supplierName}</h2>
          )}

          {pblSummary?.supplierBusinessNumber
            ? (!!isLoadingSummary && (
                <Box>
                  <Skeleton width="4rem" />
                </Box>
              )) || (
                <p className="text-center font-poppins text-base font-medium text-spenda-headingtext">
                  ABN: {pblSummary?.supplierBusinessNumber}
                </p>
              )
            : null}
        </div>

        {pblSummary?.invoiceStatusType === InvoiceStatuses.Paid && (
          <div
            className={`mb-12 flex flex-col border-4 ${paidBorderStyle} ${
              matches ? 'w-1/2' : 'w-1/3'
            } items-center rounded-lg p-1`}
          >
            <h1 className="text-4xl font-bold uppercase ">PAID</h1>
          </div>
        )}

        <div className="mb-12 flex flex-col items-center ">
          <p className="text-md mb-2 text-center font-poppins font-medium text-spenda-headingtext">
            {hasInvoiceBeenPaid ? 'Has received payment of' : 'Has requested a payment for'}
          </p>
          <div className="rounded-md border px-5 py-4" style={{backgroundColor: '#E9F1F6', borderColor: '#1C78AD'}}>
            {(!!isLoadingSummary && (
              <Box>
                <Skeleton width="10rem" height="3rem" />
              </Box>
            )) || (
              <p className="text-center font-poppins text-4xl font-medium text-spenda-headingtext" style={{}}>
                {PriceFormat(pblSummary?.invoiceBalance || pblSummary?.invoiceTotalInc || 0)}
              </p>
            )}
          </div>
        </div>
        <div>
          <div
            className="mb-3 rounded-md border p-3"
            style={{backgroundColor: '#F4F8FB', borderColor: '#1C78AD', maxWidth: '290px'}}
          >
            {(!!isLoadingSummary && (
              <Box>
                <Skeleton width="15rem" height="4rem" />
              </Box>
            )) || (
              <div className="flex flex-row items-center justify-around ">
                <Shield style={{maxWidth: '52', maxHeight: '58', width: '100%', height: '100%'}} />
                <p
                  className="text-center font-poppins  text-xs font-medium text-spenda-headingtext"
                  style={{width: '70%'}}
                >
                  Payments to {pblSummary?.supplierName} are processed securely through Spenda Payment Services.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className={`${matches ? 'block' : 'hidden'} w-full max-w-xs `}>
          <SButton
            isSubmitting={isLoadingUser}
            disabled={isLoadingUser}
            onClick={payInvoice}
            lineHeight="m"
            fontSize="s"
            variant="contained"
            color="blueShade"
            width="100%"
            height="3rem"
            fontFamily="poppins"
            borderRadius="6px"
          >
            {hasInvoiceBeenPaid ? 'View' : 'Pay Now With Spenda'}
          </SButton>
        </div>
      </div>
    </div>
  );
};
