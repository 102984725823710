import React, {useState, PropsWithChildren, useEffect, useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Box, makeStyles} from '@material-ui/core';
import {Chip} from 'spenda-ui-react';
import clsx from 'clsx';
import {css} from 'glamor';
import {omit} from 'lodash';
import moment from 'moment';
import {useEnvironment} from '../../hooks/useEnvironment';

import {
  CONSIGNMENT_NOTE_STATUS,
  DatTypes,
  PICK_SLIP_STATUS,
  SalesOrdersViewMode,
  SALES_ORDER_STATUS,
  StorageKeys,
  SALES_ORDER_STATUS_LABEL,
} from '../../model/constants/Constants';
import {ILinkedTran} from '../../model/requisition/Requisition';
import {AUTH_SELLING_COLLECT_ROUTE} from '../../routes/AuthenticatedRoutes';
import {
  AUTH_SELLING_SALES_ORDER_VIEW,
  AUTH_SELLING_SALES_ORDER_PICK,
  AUTH_SELLING_SALES_ORDER_PACK,
  AUTH_SELLING_SALES_ORDER_SHIP,
} from '../../routes/SalesOrderRoutes';
import {Layout} from '../../components/layout/Layout';
import {STable} from '../../components/tables/STable';
import {SPagination} from '../../components/tables/SPagination';
import {SalesOrderToolbar} from '../../components/sales-orders/SalesOrderToolbar';
import {useLoadSalesOrder, useSalesOrderAPI} from '../../services/useSalesOrderAPI';
import {useConsignmentNoteAPI} from '../../services/useConsignmentNoteAPI';
import useStorage from '../../hooks/useStorage';
import {PriceFormat} from '../../utils/formatter';
import {SalesOrderReservationStatus} from '../../model/sales-order/SalesOrder';
import LoadingIndicator from '../../components/ui/LoadingIndicator';
import {VisibleContent} from '../../components/ui/VisibleContent';
import {CustomContent} from '../../components/ui/CustomContent';

const useOrdersListStyles = makeStyles(() => ({
  root: {
    maxHeight: 'calc( 100vh - 20.5em )',
  },
  title: {
    fontWeight: 500,
    fontSize: '1.25em',
    color: '#4D4D4D',
  },
  toolbar: {
    width: '100%',
    minHeight: '70px',
    padding: '10px',
    backgroundColor: '#ffffff',
    marginBottom: '5px',
    borderRadius: '5px',
    display: 'grid',
    gridTemplateColumns: 'repeat(5, 1fr)',
    gridTemplateRows: 'minmax(50px,50px)',
  },
}));

const steps = ['Pick', 'Pack', 'Shipped', 'Invoiced', 'Paid'];

function StepIcon({state}: {state: number}) {
  return (
    <div className="flex h-[22px] items-center">
      {/* not active */}
      {state === 0 ? <div className="h-[15px] w-[15px] rounded-full border border-primary" /> : null}
      {/* partially active */}
      {state === 1 ? (
        <div className="h-[15px] w-[15px] rounded-full border border-primary bg-[linear-gradient(to_right,hsl(var(--primary))_60%,transparent_50%)] bg-[-1px_0] bg-no-repeat" />
      ) : null}
      {/* active */}
      {state === 2 ? <div className="h-[15px] w-[15px] rounded-full bg-primary" /> : null}
    </div>
  );
}

function getActiveSteps(rowData: any): number[] {
  const activeSteps = Array.from(Array(steps.length).keys());

  if (rowData?.PickingStatus !== 'Picked') {
    if (rowData?.PickingStatus === 'Not Picked') {
      activeSteps[0] = 0;
    } else {
      activeSteps[0] = 1;
    }
  } else {
    activeSteps[0] = 2;
  }
  if (rowData?.PackingStatus !== 'Packed') {
    if (
      rowData?.PackingStatus === 'Not Ready to Pack' ||
      rowData?.PackingStatus === 'Not Packed' ||
      rowData?.PackingStatus === 'Ready to Pack'
    ) {
      activeSteps[1] = 0;
    } else {
      activeSteps[1] = 1;
    }
  } else {
    activeSteps[1] = 2;
  }
  if (rowData?.ShippingStatus !== 'Shipped') {
    if (rowData?.ShippingStatus === 'Not Shipped') {
      activeSteps[2] = 0;
    } else {
      activeSteps[2] = 1;
    }
  } else {
    activeSteps[2] = 2;
  }
  if (rowData?.InvoiceStatus !== 'Invoiced') {
    if (rowData?.InvoiceStatus === 'Not Invoiced') {
      activeSteps[3] = 0;
    } else {
      activeSteps[3] = 1;
    }
  } else {
    activeSteps[3] = 2;
  }
  if (rowData?.PaymentStatus !== 'Paid') {
    if (!rowData?.PaymentStatus || rowData?.PaymentStatus === 'Not Paid') {
      activeSteps[4] = 0;
    } else {
      activeSteps[4] = 1;
    }
  } else {
    activeSteps[4] = 2;
  }
  return activeSteps;
}

export const SalesOderStatus = ({status, data_autoid}: {status: SALES_ORDER_STATUS_LABEL; data_autoid: string}) => {
  const color = () => {
    if (status === SALES_ORDER_STATUS_LABEL.Reserved || status === SALES_ORDER_STATUS_LABEL.Open) {
      return 'primary';
    }
    if (status === SALES_ORDER_STATUS_LABEL.Approved) {
      return 'cool-purple';
    }
    if (status === SALES_ORDER_STATUS_LABEL.Cancelled) {
      return 'error';
    }
  };
  return (
    <Chip
      color={color()}
      value={status}
      data-autoid={data_autoid}
      className="static mx-auto h-6 w-fit min-w-[116px] py-1 text-center"
    />
  );
};

export const SalesOrderList = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = useOrdersListStyles();
  const {getItem, setItem} = useStorage();
  const [selectedColumns, setSelectedColumns] = useState<string[]>([
    'RefNumber',
    'CustomerName',
    'CustomerEmailAddress',
    'CreatedWith',
    'TransDateString',
    'WarehouseName',
    'TotalEx',
    'TotalInc',
    'Status',
  ]);

  const {
    count,
    loading,
    totalCount,
    pageSize,
    currentPage,
    items: salesOrderList,
    searchFilter,
    getOrdersList,
    setSearchFilter,
  } = useLoadSalesOrder();

  const {isCapricornEnvironment} = useEnvironment();

  useEffect(() => {
    if (location?.state && (location?.state as any)?.ViewMode) {
      setSearchFilter({...searchFilter, ViewMode: (location?.state as any)?.ViewMode || SalesOrdersViewMode.All});
    }
  }, []);

  const {getConsignmentNoteById, isLoading: isLoadingCon} = useConsignmentNoteAPI();
  const {getOrderById, isLoading: isLoadingOrder} = useSalesOrderAPI();

  const isLoading = isLoadingCon || isLoadingOrder;

  const ViewMode = searchFilter.ViewMode || SalesOrdersViewMode.All;

  const handleStepperClick = async (rowData: any, index: number) => {
    const salesOrder = await getOrderById(rowData.SalesOrderID);
    if (index === 0) {
      // Pick Slip Click
      const pickSlip = salesOrder.LinkedTrans.filter(linkedTran => linkedTran.DatTypeID === DatTypes.PickSlip);
      if (pickSlip.length === 1) {
        history.push({
          pathname: AUTH_SELLING_SALES_ORDER_PICK.replace(/:pickSlipID/g, String(pickSlip[0].ID)),
        });
      } else {
        setSearchFilter({
          ...omit(searchFilter, ['StartDate', 'EndDate']),
          SearchString: salesOrder.RefNumber,
          StartRow: 1,
          ViewMode: SalesOrdersViewMode.ReadyToPick,
          Status: Object.values(PICK_SLIP_STATUS),
        });
      }
    } else if (index === 1) {
      // Pack Slip Click
      const conNote = salesOrder.LinkedTrans.filter(linkedTran => linkedTran.DatTypeID === DatTypes.ConsignmentNote);
      if (conNote.length === 1) {
        const conNoteRes = await getConsignmentNoteById(String(conNote[0].ID));
        if (conNoteRes.Value) {
          const pickSlip = conNoteRes?.Value?.LinkedTrans?.find(
            (_linkedTrans: ILinkedTran) =>
              _linkedTrans.DatTypeID === DatTypes.PickSlip && _linkedTrans.Status === PICK_SLIP_STATUS.COMPLETE,
          );
          if (pickSlip) {
            history.push({
              pathname: AUTH_SELLING_SALES_ORDER_PACK.replace(/:pickSlipID/g, pickSlip?.ID + '').replace(
                /:consignmentNoteID\?/g,
                String(conNote[0].ID),
              ),
            });
          }
        }
      } else {
        setSearchFilter({
          ...omit(searchFilter, ['StartDate', 'EndDate']),
          SearchString: salesOrder.RefNumber,
          StartRow: 1,
          ViewMode: SalesOrdersViewMode.ReadyToPack,
          Status: Object.values(CONSIGNMENT_NOTE_STATUS),
        });
      }
    } else if (index === 2) {
      // Ship Slip Click
      const conNote = salesOrder.LinkedTrans.filter(linkedTran => linkedTran.DatTypeID === DatTypes.ConsignmentNote);
      if (conNote.length === 1) {
        history.push({
          pathname: AUTH_SELLING_SALES_ORDER_SHIP.replace(/:consignmentNoteID/g, String(conNote[0].ID)),
        });
      } else {
        setSearchFilter({
          ...omit(searchFilter, ['StartDate', 'EndDate']),
          SearchString: salesOrder.RefNumber,
          StartRow: 1,
          ViewMode: SalesOrdersViewMode.AwaitingCollection,
          Status: Object.values(PICK_SLIP_STATUS),
        });
      }
    } else if (index === 3 || index === 4) {
      // Invoiced && Paid Click
      history.push({
        pathname: `${AUTH_SELLING_COLLECT_ROUTE}/${salesOrder.AccountCustomerID}`,
      });
    }
  };

  const columnSetting = (handleStepperClick: (x: any, i: number) => void) => ({
    [SalesOrdersViewMode.All]: {
      RefNumber: {
        title: 'Order ID',
        field: 'RefNumber',
        render: (rowData: any) => <span data-autoid={`txtOrderID-${rowData.SalesOrderID}`}>{rowData.RefNumber}</span>,
      },
      CustomerName: {
        title: 'Customer Name',
        field: 'CustomerName',
        render: (rowData: any) => (
          <span data-autoid={`txtCustomerName-${rowData.SalesOrderID}`}>{rowData.CustomerName ?? '-'}</span>
        ),
      },
      CapricornMemberNumber: {
        title: 'Member Number',
        field: 'CapricornMemberNumber',
        align: 'center',
        hide: !isCapricornEnvironment,
        render: (rowData: any) => (
          <span data-autoid={`txtMemberNumber-${rowData.SalesOrderID}`}>{rowData.CapricornMemberNumber ?? '-'}</span>
        ),
      },
      CustomerEmailAddress: {
        title: 'Email',
        field: 'CustomerEmailAddress',
        align: 'center',
        render: (rowData: any) => (
          <span data-autoid={`txtCustomerEmail-${rowData.SalesOrderID}`}>{rowData.CustomerEmailAddress ?? '-'}</span>
        ),
      },
      CreatedWith: {
        title: 'Sales Channel',
        field: 'SalesChannel',
        align: 'center',
        hide: isCapricornEnvironment,
        render: (rowData: any) => (
          <span data-autoid={`txtSalesChannel-${rowData.SalesOrderID}`}>{rowData.SalesChannel ?? '-'}</span>
        ),
      },
      TransDateString: {
        title: 'Created Date',
        field: 'TransDateString',
        align: 'center',
        render: (rowData: any) => (
          <span data-autoid={`txtCreateDate-${rowData.SalesOrderID}`}>
            {moment(rowData.OrderedDate).format('DD MMM YYYY')}
          </span>
        ),
      },
      TotalEx: {
        title: 'Order Total Ex',
        field: 'TotalEx',
        align: 'right',
        render: (rowData: any) => (
          <span data-autoid={`txtTotalEx-${rowData.SalesOrderID}`}>{PriceFormat(rowData.TotalEx)}</span>
        ),
      },
      TotalInc: {
        title: 'Order Total Inc',
        field: 'TotalInc',
        align: 'right',
        render: (rowData: any) => (
          <span data-autoid={`txtTotalInc-${rowData.SalesOrderID}`}>{PriceFormat(rowData.TotalInc)}</span>
        ),
      },
      Status: {
        title: 'Order Status',
        field: 'Status',
        align: 'center',
        width: '20%',
        render: (rowData: any) => {
          const activeSteps = getActiveSteps(rowData);

          const statusLabel =
            rowData?.ReservationStatus === SalesOrderReservationStatus?.Active &&
            rowData.Status === SALES_ORDER_STATUS.OPEN
              ? SALES_ORDER_STATUS_LABEL.Reserved
              : SALES_ORDER_STATUS_LABEL[rowData.Status as keyof typeof SALES_ORDER_STATUS_LABEL];

          if (rowData.Status === SALES_ORDER_STATUS.OPEN || rowData.Status === SALES_ORDER_STATUS.CANCELLED) {
            return (
              <SalesOderStatus status={statusLabel} data_autoid={`txtStatus${statusLabel}-${rowData.SalesOrderID}`} />
            );
          }

          return (
            <CustomContent
              keyPath={`salesOrders.dashboard.status.${rowData.Status}`}
              render={value => (
                <SalesOderStatus status={value} data_autoid={`txtStatus${value}-${rowData.SalesOrderID}`} />
              )}
            >
              <section className="flex" data-autoid={`txtStatus${rowData.Status}-${rowData.SalesOrderID}`}>
                {steps.map((label: string, index: number) => (
                  <div
                    key={label}
                    onClick={e => {
                      e.stopPropagation();
                      if (activeSteps[index]) handleStepperClick(rowData, index);
                    }}
                    className="flex items-center gap-x-2 rounded-md px-2 hover:bg-primary/5"
                    style={{cursor: activeSteps[index] ? 'pointer' : 'default'}}
                  >
                    <StepIcon state={activeSteps[index]} />
                    <span className="font-poppins text-xs font-medium text-spenda-primarytext">{label}</span>
                  </div>
                ))}
              </section>
            </CustomContent>
          );
        },
      },
    },
    [SalesOrdersViewMode.ReadyToPick]: {
      RefNumber: {
        title: 'PickSlip ID',
        field: 'RefNumber',
        render: (rowData: any) => <span data-autoid={`txtRefNumber-${rowData.SalesOrderID}`}>{rowData.RefNumber}</span>,
      },
      SalesOrderRefNumber: {
        title: 'Sales Order ID',
        field: 'SalesOrderRefNumber',
        render: (rowData: any) => (
          <span data-autoid={`txtSalesOrderRefNumber-${rowData.SalesOrderID}`}>
            {rowData.SalesOrderRefNumber ?? '-'}
          </span>
        ),
      },
      CustomerName: {
        title: 'Customer Name',
        field: 'CustomerName',
        render: (rowData: any) => (
          <span data-autoid={`txtCustomerName-${rowData.SalesOrderID}`}>{rowData.CustomerName ?? '-'}</span>
        ),
      },
      CustomerEmailAddress: {
        title: 'Customer Email',
        field: 'CustomerEmailAddress',
        render: (rowData: any) => (
          <span data-autoid={`txtCustomerEmail-${rowData.SalesOrderID}`}>{rowData.CustomerEmailAddress ?? '-'}</span>
        ),
      },
      ContactPhone: {
        title: 'Customer Phone',
        field: 'ContactPhone',
        render: (rowData: any) => (
          <span data-autoid={`txtContactPhone-${rowData.SalesOrderID}`}>{rowData.ContactPhone ?? '-'}</span>
        ),
      },
      Status: {
        title: 'PickSlip Status',
        field: 'Status',
        align: 'center',
        render: (rowData: any) => (
          <span data-autoid={`txtStatus-${rowData.SalesOrderID}`} className="text-error">
            {rowData.Status.replace(/_/g, ' ')}
          </span>
        ),
      },
    },
    [SalesOrdersViewMode.ReadyToPack]: {
      RefNumber: {
        title: 'Consignment ID',
        field: 'RefNumber',
        render: (rowData: any) => <span data-autoid={`txtRefNumber-${rowData.SalesOrderID}`}>{rowData.RefNumber}</span>,
      },
      SalesOrderRefNumbers: {
        title: 'Sales Order IDs',
        field: 'SalesOrderRefNumbers',
        render: (rowData: any) => (
          <span data-autoid={`txtSalesOrderRefNumbers-${rowData.SalesOrderID}`}>
            {rowData.SalesOrderRefNumbers ?? '-'}
          </span>
        ),
      },
      DeliverTo_ContactFullName: {
        title: 'Customer Name',
        field: 'DeliverTo_ContactFullName',
        align: 'center',
        render: (rowData: any) => (
          <span data-autoid={`txtCustomerName-${rowData.SalesOrderID}`}>
            {rowData.DeliverTo_ContactFullName ?? '-'}
          </span>
        ),
      },
      DeliverTo_ContactEmail: {
        title: 'Customer Email',
        field: 'DeliverTo_ContactEmail',
        align: 'center',
        render: (rowData: any) => (
          <span data-autoid={`txtCustomerEmail-${rowData.SalesOrderID}`}>{rowData.DeliverTo_ContactEmail ?? '-'}</span>
        ),
      },
      DeliverTo_ContactPhone: {
        title: 'Customer Phone',
        field: 'DeliverTo_ContactPhone',
        align: 'right',
        render: (rowData: any) => (
          <span data-autoid={`txtCustomerPhone-${rowData.SalesOrderID}`}>{rowData.DeliverTo_ContactPhone ?? '-'}</span>
        ),
      },
      NumberOfPackages: {
        title: 'No of Packages',
        field: 'NumberOfPackages',
        align: 'right',
        render: (rowData: any) => (
          <span data-autoid={`txtNumberOfPackages-${rowData.SalesOrderID}`}>{rowData.NumberOfPackages ?? '-'}</span>
        ),
      },
      Status: {
        title: 'Package Status',
        field: 'Status',
        align: 'center',
        render: (rowData: any) => (
          <span className="text-error" data-autoid={`txtStatus-${rowData.SalesOrderID}`}>
            {rowData.Status.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1')}
          </span>
        ),
      },
    },
    aliases: {
      [SalesOrdersViewMode.RequiresAttention]: SalesOrdersViewMode.All,
      [SalesOrdersViewMode.AwaitingCollection]: SalesOrdersViewMode.ReadyToPack,
    },
  });

  useEffect(() => {
    getItem(StorageKeys.SalesColumnSetting).then((res: {[x: string]: string[] | undefined}) => {
      if (res && res[ViewMode]) {
        setSelectedColumns(res[ViewMode] || []);
      }
    });
  }, []);

  const columns = useMemo(() => columnSetting(handleStepperClick), []);

  useEffect(() => {
    getItem(StorageKeys.SalesColumnSetting).then((res: {[x: string]: string[] | undefined}) => {
      if (res && res[ViewMode]) {
        setSelectedColumns(res[ViewMode] || []);
      } else {
        setSelectedColumns(Object.keys(columns[ViewMode] || columns[columns.aliases?.[ViewMode]]) || []);
      }
    });
  }, [searchFilter.ViewMode]);

  const handleSelectedColumnChange = async (_selectedColumns: string[]) => {
    const settings = await getItem(StorageKeys.SalesColumnSetting);
    await setItem(StorageKeys.SalesColumnSetting, {...(settings || {}), [ViewMode]: _selectedColumns});
    setSelectedColumns(_selectedColumns);
  };

  const handleRowClick = async (rowData: any) => {
    if (
      searchFilter.ViewMode === SalesOrdersViewMode.All ||
      searchFilter.ViewMode === SalesOrdersViewMode.RequiresAttention
    ) {
      history.push({
        pathname: AUTH_SELLING_SALES_ORDER_VIEW.replace(/:orderID/g, rowData.SalesOrderID),
      });
    } else if (searchFilter.ViewMode === SalesOrdersViewMode.ReadyToPick) {
      history.push({
        pathname: AUTH_SELLING_SALES_ORDER_PICK.replace(/:pickSlipID/g, rowData.PickSlipID),
      });
    } else if (searchFilter.ViewMode === SalesOrdersViewMode.ReadyToPack) {
      const conNoteRes = await getConsignmentNoteById(rowData.ConsignmentNoteID);
      if (conNoteRes.Value) {
        const pickSlip = conNoteRes?.Value?.LinkedTrans?.find(
          (_linkedTrans: ILinkedTran) =>
            _linkedTrans.DatTypeID === DatTypes.PickSlip && _linkedTrans.Status === PICK_SLIP_STATUS.COMPLETE,
        );
        if (pickSlip) {
          history.push({
            pathname: AUTH_SELLING_SALES_ORDER_PACK.replace(/:pickSlipID/g, pickSlip?.ID + '').replace(
              /:consignmentNoteID\?/g,
              rowData.ConsignmentNoteID,
            ),
          });
        }
      }
    } else if (searchFilter.ViewMode === SalesOrdersViewMode.AwaitingCollection) {
      history.push({
        pathname: AUTH_SELLING_SALES_ORDER_SHIP.replace(/:consignmentNoteID/g, rowData.ConsignmentNoteID),
      });
    }
  };

  const handlePageChange = (newPage: number) => {
    setSearchFilter({
      ...searchFilter,
      StartRow: (newPage - 1) * pageSize + 1,
    });
  };

  const handleRowsPerPageChange = (rows: number) => {
    setSearchFilter({
      ...searchFilter,
      MaxResults: rows,
      StartRow: 1,
    });
  };

  const handleBucketClick = (viewMode: string) => {
    setSearchFilter({
      ...omit(searchFilter, ['StartDate', 'EndDate', 'Status', 'SearchString']),
      StartRow: 1,
      ViewMode: viewMode,
    });
  };

  const isBucketActive = (ViewMode: string) => {
    return searchFilter.ViewMode === ViewMode;
  };

  const getToolbarTitle = () => {
    switch (searchFilter.ViewMode) {
      case SalesOrdersViewMode.All:
      case SalesOrdersViewMode.RequiresAttention:
        return (
          <span className="text-xl font-light">
            Sales Orders<span className="ml-4 text-base font-medium">Orders ({isLoading ? 0 : totalCount})</span>
          </span>
        );
      case SalesOrdersViewMode.ReadyToPick:
        return (
          <span className="text-xl font-light">
            Pickslips<span className="ml-4 text-base font-medium">Count ({isLoading ? 0 : totalCount})</span>
          </span>
        );
      case SalesOrdersViewMode.ReadyToPack:
      case SalesOrdersViewMode.AwaitingCollection:
        return (
          <span className="text-xl font-light">
            Consignment Notes<span className="ml-4 text-base font-medium">Count ({isLoading ? 0 : totalCount})</span>
          </span>
        );
      default:
        return '0';
    }
  };

  const isShowingTab = VisibleContent({
    keyPath: 'salesOrders.dashboard.tabs',
    children: true,
  }) as boolean;

  const ordersList = (
    <>
      {isShowingTab && (
        <div className={clsx(classes.toolbar, 'font-poppins')}>
          <Bucket
            isActive={isBucketActive(SalesOrdersViewMode.All)}
            onClick={() => handleBucketClick(SalesOrdersViewMode.All)}
            dataAutoId="btnAllStatus"
          >
            <Box className="flex flex-col ">
              <p className={clsx('text-xs font-light')}>All Status</p>
              <p className={clsx('mt-1 text-base font-semibold')}>{`${count.TotalRecordCount} Orders`}</p>
            </Box>
          </Bucket>
          <Bucket
            isActive={isBucketActive(SalesOrdersViewMode.RequiresAttention)}
            onClick={() => handleBucketClick(SalesOrdersViewMode.RequiresAttention)}
            dataAutoId="btnRequiresAttention"
          >
            <Box className="flex flex-col ">
              <p className={clsx('text-xs font-light')}>Requires Attention</p>
              <p className={clsx('mt-1 text-base font-semibold')}>{`${count.TotalCountRequiresAttention} Orders`}</p>
            </Box>
          </Bucket>
          <Bucket
            isActive={isBucketActive(SalesOrdersViewMode.ReadyToPick)}
            onClick={() => handleBucketClick(SalesOrdersViewMode.ReadyToPick)}
            dataAutoId="btnReadyToPick"
          >
            <Box className="flex flex-col">
              <p className={clsx('text-xs font-light')}>Ready To Pick</p>
              <p className={clsx('mt-1 text-base font-semibold')}>{`${count.TotalCountReadyToPick} Orders`}</p>
            </Box>
          </Bucket>
          <Bucket
            isActive={isBucketActive(SalesOrdersViewMode.ReadyToPack)}
            onClick={() => handleBucketClick(SalesOrdersViewMode.ReadyToPack)}
            dataAutoId="btnPickandPack"
          >
            <Box className="flex flex-col">
              <p className={clsx('text-xs font-light')}>Ready To Pack</p>
              <p className={clsx('mt-1 text-base font-semibold')}>{`${count.TotalCountReadyToPack} Orders`}</p>
            </Box>
          </Bucket>
          <Bucket
            isActive={isBucketActive(SalesOrdersViewMode.AwaitingCollection)}
            onClick={() => handleBucketClick(SalesOrdersViewMode.AwaitingCollection)}
            dataAutoId="btnAwaitingCollection"
          >
            <Box className="flex flex-col">
              <p className={clsx('text-xs font-light')}>Awaiting Collection</p>
              <p className={clsx('mt-1 text-base font-semibold')}>{`${count.TotalCountAwaitingDispatch} Orders`}</p>
            </Box>
          </Bucket>
        </div>
      )}
      <div
        className={clsx(classes.root, 'flex min-h-full w-full flex-col rounded-lg bg-white px-4 font-poppins')}
        id="orders-list"
      >
        <div className="sticky top-0 z-50">
          <SalesOrderToolbar
            title={getToolbarTitle()}
            searchFilter={searchFilter}
            data={salesOrderList}
            columns={columns[ViewMode] || columns[columns.aliases?.[ViewMode]]}
            selectedColumns={selectedColumns}
            refetchList={getOrdersList}
            setSelectedColumns={handleSelectedColumnChange}
            setSearchFilter={setSearchFilter}
          />
        </div>
        <STable
          newUI={true}
          columns={Object.entries(columns[ViewMode] || columns[columns.aliases?.[ViewMode]])
            .map(([key, value]: [string, any]) => {
              if (selectedColumns.includes(key)) {
                return value;
              }
            })
            .filter(value => value)}
          data={salesOrderList}
          emptyDataMessage={'No Orders'}
          stickyHeader={true}
          loading={loading}
          onRowClick={handleRowClick}
          containerClassName={clsx('overflow-y-auto', {
            'h-[calc(100vh-310px)]': isShowingTab,
            'h-[calc(100vh-95px)]': !isShowingTab,
          })}
          tableHeadClassName="sticky top-0 z-0"
        />
        <SPagination
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          rowsPerPageOptions={[10, 15, 25]}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
          containerClassName="!py-2.5"
        />
      </div>
    </>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
      <LoadingIndicator isLoading={isLoading} size="md" />
      <Layout leftPanel={ordersList} splitWidthType={4} />
    </div>
  );
};

export const Bucket = (
  props: PropsWithChildren<{
    isActive?: boolean;
    onClick: () => void;
    dataAutoId: string;
  }>,
) => {
  let darkColor = 'hsl(var(--primary))';
  let lightColor = '#ffffff';

  const styles = css({
    cursor: 'pointer',
    borderRadius: '6px',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'center',
    textAlign: 'center',
    lineHeight: '1',
  });

  const bucketColor = css({
    backgroundColor: props.isActive ? darkColor : lightColor,
    border: `1px solid ${props.isActive ? lightColor : darkColor}`,
    color: props.isActive ? '#FFFFFF' : darkColor,
  });

  const filterButton = css({
    flex: '0 1 auto',
  });

  return (
    <div className={`${styles} ${bucketColor} relative`} onClick={props.onClick}>
      <div className={`flex items-center justify-center ${filterButton}`} data-autoid={props.dataAutoId}>
        {props.isActive && (
          <svg
            className="absolute bottom-0 left-0 z-10 w-full"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1440 320"
          >
            <path
              fill="#FFFFFF"
              fillOpacity="0.1"
              d="M0,288L48,261.3C96,235,192,181,288,138.7C384,96,480,64,576,64C672,64,768,96,864,128C960,160,1056,192,1152,181.3C1248,171,1344,117,1392,90.7L1440,64L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
            ></path>
          </svg>
        )}
        {props.children}
      </div>
    </div>
  );
};
