import React from 'react';
import {Dialog, DialogBody, Typography} from 'spenda-ui-react';
import RebrandedSpendaFooterLogo from '../../assets/svg/RebrandedSpendaFooterLogo';
import LoadingIndicator from '../../components/ui/LoadingIndicator';

interface IQuoteAcceptedRejectedDialog {
  open: boolean;
}

export const PayByLinkLoader = (props: IQuoteAcceptedRejectedDialog) => {
  const {open} = props;
  return (
    <Dialog
      open={open}
      handler={() => {}}
      backdropClassName="bg-spenda-newbg"
      className={`flex !w-full !min-w-[20%] !max-w-[90%] flex-col items-center justify-center rounded-2xl sm:block sm:h-auto sm:!max-w-[339px] sm:p-0 `}
    >
      <DialogBody className="mt-2 flex flex-col items-center justify-center">
        <>
          <RebrandedSpendaFooterLogo style={{width: '147px', height: '68px'}} />
          <Typography className="mt-2 text-spenda-labeltext" variant="small">
            Please wait, we are loading your payment details.
          </Typography>
          <div className="mt-[100px]">
            <LoadingIndicator
              isLoading={true}
              size="lg"
              color="hsl(var(--primary))"
              position={{top: '56%', right: '41%'}}
            />
          </div>
        </>
      </DialogBody>
    </Dialog>
  );
};
