import React, {useContext, useEffect} from 'react';
import {APOrPsblModuleInfo} from './APOrPsblModuleInfo';
import {isWebsiteId17} from '../../config';
import {Redirect} from 'react-router-dom';
import {ZohoContext} from '../../context/zoho-context/ZohoContext';

const PSBLModuleInfo = () => {
  const zohoChat = useContext(ZohoContext);

  useEffect(() => {
    if (zohoChat) {
      zohoChat.hideFloatingButton();
    }
    return () => zohoChat?.showFloatingButton();
  }, [zohoChat]);

  if (!isWebsiteId17) {
    return <Redirect to={'/menu'} />;
  }

  return <APOrPsblModuleInfo isPsblModuleInfo />;
};

export default PSBLModuleInfo;
