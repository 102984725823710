import React, {useRef, useState} from 'react';
import {Formik, FormikProps} from 'formik';
import * as Yup from 'yup';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';
import {Input} from 'spenda-ui-react';
import {AddAttributeDialog} from './AddAttributeDialog';

interface IAddVariantDialogProps {
  open?: boolean;
  onClose: () => void;
}

interface IVariantValues {
  variantName: string;
  abbreviation: string;
}

const validationSchema = Yup.object({
  variantName: Yup.string().required('Variant Name is required'),
  abbreviation: Yup.string().required('Abbreviation is required').max(3, 'Reached char limit'),
});

export const AddVariantDialog: React.FunctionComponent<IAddVariantDialogProps> = ({
  open,
  onClose,
}: IAddVariantDialogProps) => {
  const [openAttributeDialog, setOpenAttributeDialog] = useState<boolean>(false);
  const [variantName, setVariantName] = useState<string>('');
  const formRef = useRef<FormikProps<IVariantValues>>(null);
  const initialValues: IVariantValues = {variantName: '', abbreviation: ''};

  const handleCloseClick = (props: FormikProps<IVariantValues>, cancelClick: boolean = true) => {
    if (cancelClick && props.dirty) {
      setOpenAttributeDialog(true);
    } else {
      onClose();
    }
  };

  return (
    <>
      {open && (
        <Formik
          innerRef={formRef}
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={() => {}}
        >
          {({handleChange, handleSubmit, values, validateForm, touched, errors}) => (
            <AlertDialogSlideV2
            dialogClassess="!max-w-[497px] !min-w-[20%]"
            dialogBodyClassess="flex flex-grow items-center justify-center py-16"
              title="Add a new variation property"
              headingClassess="font-semibold justify-center"
              actions={[
                {
                  label: 'Cancel',
                  action: (props: FormikProps<IVariantValues>) => handleCloseClick(props),
                  variant: 'outlined',
                  className: 'mr-auto',
                },
                {
                  label: 'Save & Create',
                  variant: 'outlined',
                  className: 'ml-auto',
                  action: async () => {
                    const errors = await validateForm();
                    handleSubmit();
                    if (Object.keys(errors).length === 0) {
                      setVariantName(values.variantName);
                      setOpenAttributeDialog(true);
                    }
                  },
                },
                {
                  label: 'Continue',
                  type: 'submit',
                  className: 'ml-3',
                  action: async () => {
                    const errors = await validateForm();
                    handleSubmit();
                    if (Object.keys(errors).length === 0) {
                      setVariantName(values.variantName);
                      setOpenAttributeDialog(true);
                    }
                  },
                },
              ]}
              dialogActionsAlignment="justify-between"
            >
              <>
                <div className="flex items-center justify-center gap-4">
                  <Input
                    data-autoid={`txtVariantName`}
                    label="Variant Name"
                    type="text"
                    name="variantName"
                    placeholder=""
                    containerProps={{className: 'w-[231px] min-w-[231px]'}}
                    onChange={handleChange}
                    value={values.variantName}
                    helperText={touched.variantName && errors.variantName ? errors.variantName : ''}
                    error={Boolean(touched.variantName && errors.variantName)}
                  />
                  <Input
                    data-autoid={`txtAbbreviation`}
                    label="Abbreviation"
                    type="text"
                    name="abbreviation"
                    placeholder=""
                    containerProps={{className: 'w-[119px] min-w-[119px]'}}
                    onChange={handleChange}
                    helperText={touched.abbreviation && errors.abbreviation ? errors.abbreviation : ''}
                    error={Boolean(touched.abbreviation && errors.abbreviation)}
                  />
                </div>
              </>
            </AlertDialogSlideV2>
          )}
        </Formik>
      )}
      {openAttributeDialog && (
        <AddAttributeDialog open={openAttributeDialog} onClose={onClose} variantName={variantName} />
      )}
    </>
  );
};
