import React, {useState} from 'react';
import moment from 'moment';
import {Typography} from 'spenda-ui-react';

import {DatTypes} from '../../model/constants/Constants';
import {useQuoteContext} from '../../context/quote-context/QuoteContext';
import {CreateServiceJobModal} from '../dialog/CreateServiceJobModal';
import {ServiceJobScope} from '../../model/service-management/serviceJob';
import {ServiceContextProvider} from '../../context/serviceManagement/ServiceContextProvider';
import {APInvoiceDetailView} from '../../screens/purchasing/APInvoiceDetailView';
import PaymentSummary from '../dialog/PaymentSummary';
import {IQuotesPaymentSummary} from '../../model/quotes/quotes';

export const QuoteLinkedTransaction = () => {
  const {quotePackage, refreshQuotePackage, approvedQuote} = useQuoteContext();

  const [openServiceDialog, setOpenServiceDialog] = useState<boolean>(false);
  const [selectedPaymentSummary, setSelectedPaymentSummary] = useState<IQuotesPaymentSummary>();
  const [invoiceId, setInvoiceId] = useState<number | undefined>();
  const [serviceJobId, setServiceJobId] = useState<number>();

  const handleOpenServiceDialog = (id: number) => {
    setServiceJobId(id);
    setOpenServiceDialog(true);
  };

  const handleCloseServiceDialog = () => {
    refreshQuotePackage();
    setOpenServiceDialog(false);
    setServiceJobId(undefined);
  };

  const handleClosePaymentSummary = () => {
    setSelectedPaymentSummary(undefined);
  };

  return (
    <>
      {approvedQuote &&
        approvedQuote?.paymentSummary?.map(payment => (
          <div
            key={payment.refNumber}
            className="mb-2.5 flex w-full cursor-pointer flex-col items-start rounded-[10px] bg-primary/10 p-3.5"
            onClick={() => setSelectedPaymentSummary(payment)}
          >
            <Typography className="text-base font-medium text-primary">Payment - [{payment?.refNumber}]</Typography>
            <Typography variant="xsmall" className="font-normal text-black-800">
              Generated on: {moment.utc(payment?.appliedDate).local().format('DD/MM HH:mm')}
            </Typography>
          </div>
        ))}
      {quotePackage?.quotes?.map(quote => {
        const sortedTransactions = (quote.linkedTransactions || []).sort((a, b) => {
          const dateAsc = new Date(a.createdDateTime_utc);
          const dateDesc = new Date(b.createdDateTime_utc);
          return dateDesc.getTime() - dateAsc.getTime();
        });

        return sortedTransactions.map((trans, index) => (
          <>
            <div
              className="mb-2.5 flex w-full cursor-pointer flex-col items-start rounded-[10px] bg-primary/10 p-3.5"
              key={index}
              data-autoid={`lnk-${trans.refNumber}`}
              onClick={() =>
                trans.datTypeID === DatTypes.ServiceJob ? handleOpenServiceDialog(trans.id) : setInvoiceId(trans.id)
              }
            >
              <Typography className="text-base font-medium text-primary" data-autoid={`txt${trans.refNumber}`}>
                {trans.datTypeID === DatTypes.ServiceJob ? 'Service job' : 'Invoice'} - [{trans.refNumber}]
              </Typography>
              <Typography
                variant="xsmall"
                className="font-normal text-black-800"
                data-autoid={`txtGeneratedDate${trans.refNumber}`}
              >
                Generated on: {moment.utc(trans.createdDateTime_utc).local().format('DD/MM HH:mm')}
              </Typography>
            </div>
            {openServiceDialog && serviceJobId === trans.id && (
              <ServiceContextProvider>
                <CreateServiceJobModal
                  serviceJobId={trans.id}
                  open={serviceJobId === trans.id}
                  handleClose={handleCloseServiceDialog}
                  scope={ServiceJobScope.QuoteDetails}
                />
              </ServiceContextProvider>
            )}
            {invoiceId === trans.id && (
              <APInvoiceDetailView
                open={Boolean(invoiceId)}
                onClose={() => {
                  setInvoiceId(undefined);
                }}
                isServiceJobInvoice
                apInvoiceId={{transId: invoiceId!}}
              />
            )}
          </>
        ));
      })}
      {selectedPaymentSummary && (
        <PaymentSummary handleClose={handleClosePaymentSummary} selectedPaymentSummary={selectedPaymentSummary} />
      )}
    </>
  );
};
