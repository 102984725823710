import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import {FormikProps, getIn, useFormik} from 'formik';
import {APFooter} from '../accountsPayableOnboarding/APFooter';
import {ISupplier} from '../../model/supplier/Supplier';
import {useSupplierAPI} from '../../services/useSupplierAPI';
import {baseSupplierValidationSchema, abnNzbnValidationSchema} from '../../utils/supplierValidation';
import {ITransactionsList} from '../../model/requires-attention/StagedTransactions';
import {DatTypes} from '../../model/constants/Constants';
import {useStagedTransactionsAPI} from '../../services/useStagedTransactionsAPI';
import {Dialog, Input} from 'spenda-ui-react';
import useGetTenantDetails from '../../services/useGetTenantDetails';

export interface ISupplierValues extends Partial<ISupplier> {}

export const CompleteSupplierDetails = (props: FormikProps<ISupplierValues>) => {
  const {values, errors, touched, handleChange, handleBlur} = props;

  return (
    <>
      <div className={`relative mx-auto my-[30px] w-full rounded-lg bg-spenda-cream p-5`}>
        <div className="flex flex-row flex-wrap items-center justify-between text-left text-base">
          <div className="w-6/12">
            <h1 className="text-left text-xs font-semibold sm:text-base sm:font-bold ">Supplier</h1>
            <p className=" text-xs font-normal sm:text-base sm:font-medium">{values?.Name}</p>
          </div>
          <div className="w-6/12">
            <h1 className="text-left text-xs font-semibold sm:text-base sm:font-bold ">Reference ID</h1>
            <p className=" text-xs font-normal sm:text-base sm:font-medium">{values?.RefNumber || '-'}</p>
          </div>
        </div>
      </div>
      <div className="px-2">
        <div className="mb-6 w-full sm:w-9/12">
          <p className="text-xs font-medium text-spenda-labeltext">
            Please fill the supplier details below. It is mandatory to fill the supplier details before creating a
            supplier.
          </p>
        </div>
        <div className="sm:w-10/12">
          <div className="mb-6 flex w-full flex-col sm:flex-row">
            <div className="mb-6 w-full sm:mb-0 sm:mr-5 sm:w-6/12">
              <Input
                id="Contacts.0.FirstName"
                name="Contacts.0.FirstName"
                label="First name"
                data-autoid={'txtFirstName'}
                value={values.Contacts?.[0]?.FirstName}
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={getIn(touched, 'Contacts.0.FirstName') && getIn(errors, 'Contacts.0.FirstName')}
                error={getIn(touched, 'Contacts.0.FirstName') && Boolean(getIn(errors, 'Contacts.0.FirstName'))}
              />
            </div>
            <div className="w-full sm:ml-5 sm:w-6/12">
              <Input
                id="Contacts.0.LastName"
                name="Contacts.0.LastName"
                label="Last name"
                data-autoid={'txtLastName'}
                value={values.Contacts?.[0]?.LastName}
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={getIn(touched, 'Contacts.0.LastName') && getIn(errors, 'Contacts.0.LastName')}
                error={getIn(touched, 'Contacts.0.LastName') && Boolean(getIn(errors, 'Contacts.0.LastName'))}
              />
            </div>
          </div>
          <div className="mb-6 flex w-full flex-col sm:flex-row">
            <div className="mb-6 w-full sm:mb-0 sm:mr-5 sm:w-6/12">
              <Input
                id="ABN"
                name="ABN"
                label={'ABN / NZBN'}
                data-autoid={'txtABN'}
                value={values.ABN}
                helperText={getIn(touched, 'ABN') && getIn(errors, 'ABN')}
                error={getIn(touched, 'ABN') && Boolean(getIn(errors, 'ABN'))}
                autoComplete="off"
                onChange={handleChange}
              />
            </div>
            <div className="w-full sm:ml-5 sm:w-6/12">
              <Input
                id="Contacts.0.EmailAddress"
                name="Contacts.0.EmailAddress"
                label="Email"
                data-autoid={'txtEmail'}
                value={values.Contacts?.[0]?.EmailAddress}
                helperText={getIn(touched, 'Contacts.0.EmailAddress') && getIn(errors, 'Contacts.0.EmailAddress')}
                error={getIn(touched, 'Contacts.0.EmailAddress') && Boolean(getIn(errors, 'Contacts.0.EmailAddress'))}
                autoComplete="off"
                onChange={e => handleChange(e)}
              />
            </div>
          </div>
          <div className="mb-6 flex w-full flex-col sm:flex-row">
            <div className="mb-6 w-full sm:mb-0 sm:mr-5 sm:w-6/12">
              <Input
                id="Contacts.0.PhoneMobile"
                name="Contacts.0.PhoneMobile"
                label="Contact number"
                data-autoid={'txtContactNumber'}
                value={values.Contacts?.[0]?.PhoneMobile}
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={getIn(touched, 'Contacts.0.PhoneMobile') && getIn(errors, 'Contacts.0.PhoneMobile')}
                error={getIn(touched, 'Contacts.0.PhoneMobile') && Boolean(getIn(errors, 'Contacts.0.PhoneMobile'))}
              />
            </div>
            <div className="w-full sm:ml-5 sm:w-6/12">
              <Input
                label="Country"
                id={`Locations.0.Addresses.0.Country`}
                data-autoid={'txtCountry'}
                name={`Locations.0.Addresses.0.Country`}
                key={`Locations.0.Addresses.0.Country`}
                value={values?.Locations?.[0]?.Addresses?.[0]?.Country}
                onChange={handleChange}
                helperText={
                  getIn(touched, 'Locations.0.Addresses.0.Country') && getIn(errors, 'Locations.0.Addresses.0.Country')
                }
                error={
                  getIn(touched, 'Locations.0.Addresses.0.Country') &&
                  Boolean(getIn(errors, 'Locations.0.Addresses.0.Country'))
                }
                autoComplete="off"
              />
            </div>
          </div>
          <div className="mb-6 flex w-full flex-col sm:flex-row">
            <div className="mb-6 w-full sm:mb-0 sm:mr-5 sm:w-6/12">
              <Input
                label="PostCode"
                id={`Locations.0.Addresses.0.PostCode`}
                name={`Locations.0.Addresses.0.PostCode`}
                key={`Locations.0.Addresses.0.PostCode`}
                data-autoid={'txtPostCode'}
                value={values?.Locations?.[0]?.Addresses?.[0]?.PostCode}
                onChange={handleChange}
                helperText={
                  getIn(touched, 'Locations.0.Addresses.0.PostCode') &&
                  getIn(errors, 'Locations.0.Addresses.0.PostCode')
                }
                error={
                  getIn(touched, 'Locations.0.Addresses.0.PostCode') &&
                  Boolean(getIn(errors, 'Locations.0.Addresses.0.PostCode'))
                }
                autoComplete="off"
              />
            </div>
            <div className="w-full sm:ml-5 sm:w-6/12"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export const initSupplierDetails: ISupplierValues = {
  Contacts: [
    {
      IsPrimaryContact: true,
      PhoneMobile: '',
      FirstName: '',
      LastName: '',
      EmailAddress: '',
      IsActive: true,
    },
  ],
  Locations: [
    {
      Addresses: [
        {
          PostCode: '',
          Country: '',
        },
      ],
      IsActive: true,
      IsDefaultLocation: false,
    },
  ],
  ABN: '',
  Name: '',
  RefNumber: '',
};
interface ICompleteSupplierDetailsDialog {
  vendorPostingDetails: ITransactionsList;
  selectedSupplierId: number;
  handleClose: () => void;
  handleDiscardModal: () => void;
}

export const CompleteSupplierDetailsDialog = (props: ICompleteSupplierDetailsDialog) => {
  const {tenantCountry} = useGetTenantDetails();
  const isNZTenant = tenantCountry === 'NZ';

  const {handleClose, handleDiscardModal, vendorPostingDetails, selectedSupplierId} = props;
  const vendorName =
    vendorPostingDetails?.datTypeID === DatTypes.StagedPurchaseInvoice
      ? vendorPostingDetails?.stagedPurchaseInvoice?.vendorName?.trim() || '-'
      : vendorPostingDetails?.stagedDebitNote?.vendorName?.trim() || '-';
  const vendorRefNo =
    vendorPostingDetails?.datTypeID === DatTypes.StagedPurchaseInvoice
      ? vendorPostingDetails?.stagedPurchaseInvoice?.vendorInvoiceRefNumber || '-'
      : vendorPostingDetails?.stagedDebitNote?.vendorDebitNoteRefNumber || '-';

  const {saveSupplier} = useSupplierAPI();
  const {postVendor} = useStagedTransactionsAPI();

  const onFormSubmit = async (values: ISupplierValues): Promise<void> => {
    return saveSupplier(values).then(async res => {
      if (res) {
        await postVendor(selectedSupplierId, res?.ID, vendorName);
        handleClose?.();
      }
    });
  };

  const {handleSubmit, isSubmitting, ...formikProps} = useFormik({
    validateOnBlur: true,
    initialValues: {...initSupplierDetails, RefNumber: vendorRefNo, Name: vendorName},
    onSubmit: onFormSubmit,
    validationSchema: isNZTenant ? abnNzbnValidationSchema : baseSupplierValidationSchema,
    enableReinitialize: true,
    validateOnMount: true,
  });

  return (
    <Dialog open handler={() => {}} className={`!h-[633px] !min-h-[633px] !min-w-[760px]`}>
      <form onSubmit={handleSubmit} className={`relative h-full`}>
        <div
          className={`spenda-color relative flex h-full max-w-full items-center justify-center font-poppins text-xl`}
        >
          <div className={`modal-box !h-full max-h-[633px] max-w-[760px] px-[30px] pb-5 pt-3 text-spenda-primarytext`}>
            <div className={`-mx-[30px] flex justify-between border-[#CCCCCC] border-b-default px-3 pb-3`}>
              <h2 className={`w-full self-center text-center text-xl font-light`}>Complete supplier details</h2>
              <div className="flex justify-end text-right">
                <span className="ap-onboarding-close-icon">
                  <CloseIcon data-autoid="btnClose" onClick={props?.handleClose} className="text-primary" />
                </span>
              </div>
            </div>
            <CompleteSupplierDetails {...formikProps} isSubmitting={isSubmitting} handleSubmit={handleSubmit} />
          </div>
        </div>
        <APFooter
          APFooterClass="!bottom-[10px] !left-[10px]"
          style={{width: 'calc(100% - 20px)'}}
          isCancelButton={true}
          isDisabled={isSubmitting}
          buttonLabel={'Save Details'}
          secondaryButtonLabel="Discard Details"
          handleCancelClick={handleDiscardModal}
          cancelButtonType="button"
          isT2TSupplierModule={true}
          conditionallyClass={true}
        />
      </form>
    </Dialog>
  );
};
