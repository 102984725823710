import React, {useRef, useState} from 'react';
import {Formik, FormikProps} from 'formik';
import * as Yup from 'yup';

import {AlertDialog, AlertDialogSlideV2} from './AlertDialogSlideV2';
import {IconButton, Input, Upload} from 'spenda-ui-react';
import {FileType} from './QuoteAttachmentDialog';
import {CloseCross} from '../../assets/svg/CloseCross';
import {WidgetDeleteLogo} from '../../assets/svg/WidgetDeleteLogo';
import { UploadColour } from '../../assets/svg';

interface IAddAttributeDialogProps {
  open?: boolean;
  onClose: () => void;
  initialData?: {
    label: string;
    shortLabel: string;
  };
  variantName?: string;
}

interface IAttributeValues {
  attributeName: string;
  abbreviation: string;
  exSellPrice: string;
  incSellPrice: string;
  colourCode: string;
}

const validationSchema = Yup.object({
  attributeName: Yup.string().required('Attribute Name is required'),
  abbreviation: Yup.string().required('Abbreviation is required').max(3, 'Reached char limit'),
});

export const UploadedImageView = ({
  uri: fileURL,
  name,
  onDelete,
  containerClassName,
}: {
  uri: string;
  name: string;
  containerClassName?: string;
  onDelete?: () => void;
}) => {
  return (
    <div className={`w-1/5 space-y-1 rounded-md ${containerClassName ?? ''}`}>
      <div className="relative flex w-full flex-col">
        <div className="absolute inset-0 flex items-center justify-center rounded-md bg-[#00000040] opacity-0 hover:opacity-100">
          {onDelete && (
            <div className="flex space-x-3">
              <IconButton
                className="h-[30px] rounded-full bg-black-300 !fill-[#fff] opacity-90"
                color="error"
                name="DeleteImage"
                onClick={onDelete}
                size="sm"
                variant="text"
              >
                <WidgetDeleteLogo data-autoid="DeleteIcon" />
              </IconButton>
            </div>
          )}
        </div>
        <img loading="lazy" src={fileURL} alt={name} className="h-full !w-auto rounded-md object-cover object-center" />
      </div>
    </div>
  );
};

export const AddAttributeDialog: React.FunctionComponent<IAddAttributeDialogProps> = ({
  open,
  onClose,
  initialData,
  variantName,
}: IAddAttributeDialogProps) => {
  const formRef = useRef<FormikProps<IAttributeValues>>(null);
  const [addedFileToUpload, setAddedFileToUpload] = useState<FileType | null>(null);
  const [onDeleteImage, setOnDeleteImage] = useState<boolean>(false);

  const attributeValues: IAttributeValues = {
    attributeName: initialData?.label || '',
    abbreviation: initialData?.shortLabel || '',
    exSellPrice: '',
    incSellPrice: '',
    colourCode: '',
  };

  const deleteFile = () => {
    setAddedFileToUpload(null);
    setOnDeleteImage(false);
  };

  const handleFileChange = (files: FileList) => {
    if (files && files.length > 0) {
      const newFile: FileType = {
        file: files[0],
        uri: URL.createObjectURL(files[0]),
      };
      setAddedFileToUpload(newFile);
    }
  };

  const handleSubmit = () => {
    onClose(); //closing the diaog after submission logic
  };

  const handleSaveAndAddAttribute = (resetForm: () => void) => {
    resetForm(); //resetting the form after submission logic
    setAddedFileToUpload(null);
  };

  return (
    <>
      {open && (
        <Formik
          innerRef={formRef}
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={attributeValues}
          onSubmit={handleSubmit}
        >
          {({handleChange, values, handleSubmit, touched, errors, resetForm}) => (
            <AlertDialogSlideV2
              dialogClassess="!mx-5 !w-full !max-w-[791px]"
              dialogBodyClassess="h-[280px] flex flex-grow items-center justify-center"
              title={variantName ? `Add attribute for ${variantName}` : 'Add attribute for size'}
              headingClassess="font-semibold justify-center"
              actions={[
                {label: 'Cancel', action: () => onClose(), variant: 'outlined', className: 'mr-auto'},
                {
                  label: 'Save & Add another Attribute',
                  action: () => handleSaveAndAddAttribute(resetForm),
                  variant: 'outlined',
                  className: 'ml-auto',
                },
                {
                  label: 'Save & Close',
                  action: () => handleSubmit(),
                  type: 'submit',
                  className: 'ml-3',
                },
              ]}
              dialogActionsAlignment="justify-between"
            >
              <>
                <div className="flex gap-8">
                  <div className="mt-8 flex flex-col gap-10">
                    <div className="flex items-center justify-center gap-4">
                      <Input
                        data-autoid={`txtAttributeName`}
                        label="Attribute Name"
                        type="text"
                        name="attributeName"
                        placeholder=""
                        containerProps={{className: 'w-[200px] min-w-[200px]'}}
                        onChange={handleChange}
                        value={values.attributeName}
                        helperText={touched.attributeName && errors.attributeName ? errors.attributeName : ''}
                        error={Boolean(touched.attributeName && errors.attributeName)}
                      />
                      <Input
                        data-autoid={`txtAbbreviation`}
                        label="Abbreviation"
                        type="text"
                        name="abbreviation"
                        placeholder=""
                        containerProps={{className: 'w-[110px] min-w-[110px]'}}
                        maxLength={3}
                        displayLength
                        InputProp={{
                          endAdornment: <CloseCross />,
                          startAdornment: <></>,
                        }}
                        onChange={handleChange}
                        helperText={touched.abbreviation && errors.abbreviation ? errors.abbreviation : ''}
                        error={Boolean(touched.abbreviation && errors.abbreviation)}
                      />
                    </div>
                    <div className="flex items-center justify-center gap-4">
                      <Input
                        data-autoid={`txtExSellPrice`}
                        label="Increased sell price by (Ex)"
                        type="number"
                        name="exSellPrice"
                        placeholder="$ 0.00"
                        containerProps={{className: 'w-[155px] min-w-[155px]'}}
                        shrink={true}
                        />
                      <Input
                        data-autoid={`txtIncSellPrice`}
                        label="Increased sell price by (Inc)"
                        type="number"
                        name="incSellPrice"
                        placeholder="$ 0.00"
                        containerProps={{className: 'w-[155px] min-w-[155px]'}}
                        shrink={true}
                      />
                    </div>
                  </div>

                  <div className="max-h-[320px] w-0.5 border border-solid border-[#ECECEC]" />

                  <div className="flex flex-col items-center gap-8">
                    {!addedFileToUpload ? (
                      <Upload
                        data-autoid="uploadAttachment"
                        color="primary"
                        label=""
                        className="h-[80px] !w-[80px] rounded-md border-none !bg-[#E3EEF5]"
                        icon={<UploadColour />}
                        onChange={handleFileChange}
                        disabled={Boolean(values.colourCode)}
                        accept="image/*"
                      />
                    ) : (
                      <UploadedImageView
                        name={addedFileToUpload.file.name}
                        uri={addedFileToUpload.uri}
                        containerClassName={'!w-[80px] !h-[80px] flex justify-start'}
                        onDelete={() => setOnDeleteImage(true)}
                      />
                    )}
                    <div className="relative flex w-full items-center justify-center">
                      <div className="absolute bg-white px-2 font-medium text-spenda-primarytext">or</div>
                      <div className="h-0.5 w-full max-w-[160px] border border-[#ECECEC]" />
                    </div>
                    <Input
                      data-autoid={`txtColourCode`}
                      label="Colour Code"
                      type="text"
                      name="colourCode"
                      placeholder=""
                      containerProps={{className: 'w-[134px] min-w-[134px]'}}
                      InputProp={{
                        endAdornment: (
                          <div
                            className="h-6 w-6 rounded-md"
                            style={{
                              backgroundColor: values.colourCode || '#FFFFFF',
                            }}
                          />
                        ),
                        startAdornment: <></>,
                      }}
                      value={values.colourCode}
                      onChange={handleChange}
                      disabled={Boolean(addedFileToUpload)}
                    />
                  </div>
                </div>
              </>
            </AlertDialogSlideV2>
          )}
        </Formik>
      )}
      {onDeleteImage && (
        <AlertDialog
          id="discardPopup"
          title="Delete Image"
          size="xs"
          actions={[
            {
              label: 'No',
              action: () => setOnDeleteImage(false),
              variant: 'outlined',
            },
            {
              label: 'Yes',
              action: () => deleteFile(),
            },
          ]}
          content="Would you like to delete this image ?"
        />
      )}
    </>
  );
};
