import * as React from 'react';
import {SVGProps} from 'react';

const IconReconsilingPeriod = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.5 20.006H.833a.833.833 0 1 1 0-1.667H7.5a.833.833 0 1 1 0 1.667ZM5.833 16.672h-5a.833.833 0 1 1 0-1.666h5a.833.833 0 1 1 0 1.666ZM4.167 13.34H.833a.833.833 0 1 1 0-1.667h3.334a.833.833 0 1 1 0 1.666Z"
      className="fill-primary"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.834 19.968a.834.834 0 1 1-.075-1.666 8.333 8.333 0 1 0-9.058-9.055.833.833 0 0 1-1.66-.15 10 10 0 0 1 19.96.909 9.945 9.945 0 0 1-9.093 9.959.763.763 0 0 1-.074.003Z"
      className="fill-primary"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 5.006a.833.833 0 0 0-.834.833v4.167c0 .22.088.433.245.589l2.5 2.5a.833.833 0 0 0 1.178-1.178L10.833 9.66V5.839A.833.833 0 0 0 10 5.006Z"
      className="fill-primary"
    />
  </svg>
);

export default IconReconsilingPeriod;
