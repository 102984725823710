import React, {useState} from 'react';
import {Option, SearchInput, Select, Typography} from 'spenda-ui-react';
import {AlertDialogSlideV2} from '../dialog/AlertDialogSlideV2';
import {ARTable} from '../AccountsReceivable/ARTable';

interface IInventoryItemsDialog {
  handleCancel: () => void;
}

const columns = [
  {
    title: 'SKU',
    key: 'sku',
    width: '13%',
    align: 'left',
    rowClassName: 'p-2.5',
    rowRenderer: (index: number) => {
      return <Typography data-autoid={`txtSku-${index}`}>YSH000S</Typography>;
    },
  },
  {
    title: 'Code',
    key: 'code',
    width: '13%',
    align: 'left',
    rowClassName: 'p-2.5',
    rowRenderer: (index: number) => {
      return <Typography data-autoid={`txtCode-${index}`}>000003</Typography>;
    },
  },
  {
    title: 'Product',
    key: 'product',
    width: '13%',
    align: 'left',
    rowClassName: 'p-2.5',
    rowRenderer: (index: number) => {
      return <Typography data-autoid={`txtProduct-${index}`}>Florence Oak - Clovelly</Typography>;
    },
  },
];

export const InventoryItemsDialog: React.FC<IInventoryItemsDialog> = ({handleCancel}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const handleSelectChange = (value: string | undefined) => {
    setSelectedOption(value || null);
  };

  return (
    <>
      <AlertDialogSlideV2
        headingClassess="border-b border-[#EAEAEA] flex items-center justify-center"
        dialogActionsAlignment="justify-between"
        dialogBodyClassess="flex  flex-col justify-center gap-3"
        dialogClassess="!min-w-[948px] "
        data-autoid="dlgInventoryItems"
        actions={[
          {
            label: 'Cancel',
            variant: 'outlined',
            action: handleCancel,
          },
          {
            label: 'Save & Close',
            action: () => {},
          },
        ]}
        title={'Inventory Items '}
      >
        <div className="mx-4 mr-10 flex items-center justify-between">
          <Typography variant="h3" className="text-center text-spenda-primarytext">
            Products (320)
          </Typography>
          <div className="flex items-center justify-center gap-2">
            <div className="flex w-[330px] items-center justify-center">
              <SearchInput
                expanded
                reverse={true}
                clearIcon
                iconButtonProps={{
                  name: 'Search',
                }}
                data-autoid="txtSearchInput"
                autoFocus
                placeholder="Search product, code..."
              />
            </div>
            <div className="w-[180px]">
              <Select label="Sort by code: A to Z " onChange={handleSelectChange} value={selectedOption || ''}>
                <Option value="sortByCodeAtoZ">Sort by code: A to Z </Option>
                <Option value="sortByCodeZtoA">Sort by code: Z to A</Option>
              </Select>
            </div>
          </div>
        </div>
        <div className="mx-4 mt-4 overflow-y-scroll h-[500px]">
          <div>
            <ARTable
              isLoading={false}
              rows={Array(14).fill(2)}
              columns={columns}
              tableClass="!-mx-2.5 !w-[calc(100%+20px)] text-black"
            />
          </div>
        </div>
      </AlertDialogSlideV2>
    </>
  );
};
