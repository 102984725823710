import React, {useContext, useEffect, useMemo, useState} from 'react';
import {MarketplaceLayout} from '../../purchasing/MarketplaceLayout';
import {PSBLFooter} from '../../../components/AccountsReceivable/PSBLFooter';
import {Button, IconButton, Typography, Menu, MenuHandler, MenuItem, MenuList} from 'spenda-ui-react';
import IconFilter from '../../../assets/svg/IconFilter';
import {ARTable} from '../../../components/AccountsReceivable/ARTable';
import {IconSort} from '../../../assets/svg';
import {ARSelectToggler} from '../../../components/AccountsReceivable/ARSelectToggler';
import ARTooltip from '../../../components/data-display/ARTootip';
import AdvanceFilter from '../../../components/ECommerce/AdvanceFilter';
import moment from 'moment';
import {useSupplierTransactionsAPI} from '../../../services/useSupplierTransactionsAPI';
import {useParams} from 'react-router-dom';
import MatchAndLinkRequireAttentionDialog from '../../../components/AccountsReceivable/modals/MatchAndLinkRequireAttentionDialog';
import {PostingTypeModal} from '../../../components/accountsPayableOnboarding/PostingTypeModal';
import {SelectPostingInventoryModal} from '../../../components/accountsPayableOnboarding/SelectPostingInventoryModal';
import {
  IStagedDebitNote,
  IStagedPurchaseInvoice,
  IStagedTransactions,
  IStagedTransactionsResponse,
  ISuppliersStagedTransactions,
} from '../../../model/requires-attention/StagedTransactions';
import {DatTypes} from '../../../model/constants/Constants';
import {PriceFormat} from '../../../utils/formatter';
import {PostAsNewInvoiceModal} from '../../../components/AccountsReceivable/modals/PostAsANewInvoiceModal';
import {ArRefreshBtn} from '../../../components/arRefresh/ArRefresh';
import AppContext from '../../../context/app/appContext';
import {VerifyingTaxAmountDialog} from '../../../components/AccountsReceivable/modals/VerifyingTaxAmountDialog';
import {IStagingPurchaseInvoiceTaxAdjustment} from '../../../services/useStagedTransactionsAPI';
import {useFeatureFlags} from '../../../hooks/useFeatureFlags';
import {IOnHoldSelectedRow} from '../../../components/AccountsReceivable/templates/InvoiceFromCapricornTemplate';
import {OnHoldModal} from '../../../components/AccountsReceivable/modals/OnHoldModal';
import {IconDocument} from '../../../assets/svg/IconDocument';
import DismissTransactionModal from '../../../components/AccountsReceivable/modals/DismissTransactionModal';
import {IntegrationContext} from '../../../context/IntegrationContext';

export interface ISelectedRow {
  supplier: string;
  refNo: string;
  messageID: number;
  documentID: number;
  totalInc: number;
  totalTax: number;
  datTypeID: number;
  InventoryCode?: string;
  InventoryID?: number;
}

export interface IRequiresAttentionFilters {
  suppliers: IFilterOption[];
  actionRequired: IFilterOption[];
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

interface IFilterOption {
  id: string | number;
  name: string;
}

interface IFilterSection {
  title: string;
  options: IFilterOption[];
  searchPlaceholder: string;
  searchKey: string;
  filterKey: string;
}

export const initialFilters = {
  suppliers: [],
  actionRequired: [],
  startDate: null,
  endDate: null,
};

export const RequiresAttention = () => {
  // Hooks
  const {supplierId: sId} = useParams<{supplierId?: string}>();
  const {financialAdaptorDisplayName} = useContext(IntegrationContext);

  // Context
  const {marketplaceSupplier} = useContext(AppContext);
  const {PSBLSTAGING, psblV22LowTouchOnboarding92923} = useFeatureFlags().supplierDriven();

  // States
  const [isShowMatchAndLinkDialog, setIsShowMatchAndLinkDialog] = useState(false);
  const [isShowPostSupplierAsPopup, setIsShowPostSupplierAsPopup] = useState(false);
  const [isShowPostingPopup, setIsShowPostingPopup] = useState(false);
  const [isShowPostInventoryAsPopup, setIsShowPostInventoryAsPopup] = useState(false);
  const [isShowFilterDialog, setIsShowFilterDialog] = useState<boolean>(false);
  const [isVerifytaxAmountDialog, setIsVerifytaxAmountDialog] = useState<boolean>(false);

  const [stagedTransactions, setStagedTransactions] = useState<IStagedTransactions[]>();
  const [selectedStagedTransaction, setSelectedStagedTransaction] = useState<IStagedTransactions>();
  const [isLoading, setIsLoading] = useState(false);
  const [postAsNewClickedData, setPostAsNewClickedData] = useState<ISelectedRow>();
  const [taxAdjusments, setTaxAdjusments] = useState<IStagingPurchaseInvoiceTaxAdjustment[]>([]);
  const [onHoldClickedData, setOnHoldClickedData] = useState<IOnHoldSelectedRow>();
  const [showOnHoldModal, setShowOnHoldModal] = useState<boolean>(false);
  const [isOnHold, setIsOnHold] = useState<boolean>(false);

  const [selectedRows, setSelectedRows] = useState<ISelectedRow[]>([]);
  const [filters, setFilters] = useState<IRequiresAttentionFilters>(initialFilters);
  const [sortKey, setSortKey] = useState<string>('supplier');
  const [sortDirection, setSortDirection] = useState<{[key: string]: boolean}>({
    supplier: false,
    transactionDate: false,
    statementPeriod: false,
    amount: false,
  });
  const [isShowDismissDialog, setIsShowDismissDialog] = useState<boolean>(false);

  // Apis
  const {getRequiresAttentionInvoices} = useSupplierTransactionsAPI();

  // Constant
  const supplierId = Number(sId);
  const datePickerButtons = ['This week', 'Last month'];
  const sortOptions = [
    {id: 'supplier', name: 'Supplier A-Z'},
    {id: 'transactionDate', name: 'Date - Oldest to Newest'},
    {id: 'statementPeriod', name: 'Period - Oldest to Newest'},
    {id: 'amount', name: 'Amount - Smallest to Largest'},
  ];

  useEffect(() => {
    marketplaceSupplier?.TenantName && fetchRequireAttentionData();
  }, [supplierId, marketplaceSupplier?.TenantName]);

  const fetchRequireAttentionData = async () => {
    try {
      if (!supplierId) return;
      setIsLoading(true);
      const response: IStagedTransactionsResponse = await getRequiresAttentionInvoices(supplierId);
      const transactions: IStagedTransactions[] = response.value;
      const updatedTransaction: IStagedTransactions[] = transactions?.map((t: IStagedTransactions) => {
        if (t?.stagedPurchaseInvoice) {
          return {
            ...t,
            stagedPurchaseInvoice: {
              ...t?.stagedPurchaseInvoice,
              vendorName: t?.stagedPurchaseInvoice?.vendorName || marketplaceSupplier?.TenantName,
            },
          } as IStagedTransactions;
        }
        if (t?.stagedDebitNote) {
          return {
            ...t,
            stagedDebitNote: {
              ...t?.stagedDebitNote,
              vendorName: t?.stagedDebitNote?.vendorName || marketplaceSupplier?.TenantName,
            },
          } as IStagedTransactions;
        }
        return t;
      });
      setStagedTransactions(updatedTransaction);
      return updatedTransaction;
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const handlePostingAsSupplierDone = async () => {
    const records = await fetchRequireAttentionData();
    const updatedRecords = records?.find(r => r.documentID === selectedStagedTransaction?.documentID);
    setSelectedStagedTransaction(updatedRecords);
    setIsShowPostSupplierAsPopup(false);
    setIsShowPostInventoryAsPopup(true);
  };

  const handlePostAsInventoryDone = async () => {
    setIsShowPostInventoryAsPopup(false);
    await fetchRequireAttentionData();
  };

  const handlePostAsNewInvoiceDone = async () => {
    setSelectedRows([]);
    setIsShowPostingPopup(false);
    await fetchRequireAttentionData();
  };

  const handleMatchAndLinkDone = async () => {
    setSelectedStagedTransaction(undefined);
    setIsShowMatchAndLinkDialog(false);
    await fetchRequireAttentionData();
  };

  const handleVerifyTaxAmountDone = (updatedSelectedRows: ISelectedRow[]) => {
    const taxAdjusmentsData = updatedSelectedRows?.map(row => ({
      totalTax: row?.datTypeID === DatTypes.StagedDebitNote ? 0 : row?.totalTax,
      stagingDocumentID: row?.documentID,
      postingInventoryID: row?.InventoryID,
      datTypeID: row?.datTypeID === DatTypes.StagedPurchaseInvoice ? 'StagingPurchaseInvoice' : 'StagingDebitNote',
    }));
    setTaxAdjusments(taxAdjusmentsData);
    setIsVerifytaxAmountDialog(false);
    setIsShowPostingPopup(true);
  };

  const handleSelect = (row: ISelectedRow) => {
    setSelectedRows(prevSelectedRows => {
      const isSelected = prevSelectedRows.some(selectedRow => selectedRow.refNo === row.refNo);
      if (isSelected) {
        return prevSelectedRows.filter(selectedRow => selectedRow.refNo !== row.refNo);
      } else {
        return [
          ...prevSelectedRows,
          {
            refNo: row.refNo,
            supplier: row.supplier,
            messageID: row?.messageID,
            documentID: row?.documentID,
            totalInc: row?.totalInc,
            totalTax: row?.totalTax,
            datTypeID: row?.datTypeID,
            InventoryCode: row?.InventoryCode,
            InventoryID: row?.InventoryID,
          },
        ];
      }
    });
  };

  const handleSelectAll = () => {
    setSelectedRows(prevSelectedRows => {
      const selectableRows = filterRecords?.filter(row => {
        const {sourceSupplierPrimaryContact, postingSupplierPostingInventoryCode} = getComparedStagedData(row);
        return sourceSupplierPrimaryContact?.name && postingSupplierPostingInventoryCode;
      });
      const allSelected = selectableRows?.every(row => {
        const {refNumber} = getComparedStagedData(row);
        return prevSelectedRows.some(selectedRow => selectedRow.refNo === refNumber);
      });
      if (allSelected) {
        return prevSelectedRows.filter(
          selectedRow =>
            !selectableRows?.some(row => {
              const {refNumber} = getComparedStagedData(row);
              return selectedRow.refNo === refNumber;
            }),
        );
      } else {
        const newSelectedRows = [...prevSelectedRows];
        selectableRows?.forEach(row => {
          const {
            refNumber,
            vendorName,
            messageID,
            documentID,
            totalTax,
            totalInc,
            datTypeID,
            postingSupplierPostingInventoryID,
            postingSupplierPostingInventoryCode,
          } = getComparedStagedData(row);
          if (!newSelectedRows.some(selectedRow => selectedRow.refNo === refNumber)) {
            newSelectedRows.push({
              refNo: refNumber,
              supplier: vendorName || '',
              messageID: messageID,
              documentID: documentID,
              totalInc: totalInc,
              totalTax: totalTax,
              datTypeID: datTypeID,
              InventoryCode: String(postingSupplierPostingInventoryCode),
              InventoryID: Number(postingSupplierPostingInventoryID),
            });
          }
        });
        return newSelectedRows;
      }
    });
  };

  const handleDismissTransaction = async () => {
    setSelectedRows([]);
    setIsShowDismissDialog(false);
    await fetchRequireAttentionData();
  };

  const filterRecords = useMemo(() => {
    if (!filters.suppliers.length && !filters.actionRequired.length && !filters.startDate && !filters.endDate) {
      return stagedTransactions;
    }
    return stagedTransactions?.filter(row => {
      const {
        sourceSupplierPrimaryContact,
        supplierPrimaryContact,
        postingSupplierPostingInventoryCode,
        vendorName,
        transDate,
        matchedDocumentID,
        datTypeID,
        status,
      } = getComparedStagedData(row);
      const supplierPrimaryName = sourceSupplierPrimaryContact?.name;
      const inventoryCode = postingSupplierPostingInventoryCode;
      const isPostAsNew =
        datTypeID === DatTypes.StagedPurchaseInvoice
          ? supplierPrimaryContact?.countPurchaseInvoices === 0
          : supplierPrimaryContact?.countDebitNotes === 0;
      const matchSupplier =
        filters.suppliers.length === 0 || filters.suppliers.some(supplier => supplier.name === vendorName);
      const matchActionRequired =
        filters.actionRequired.length === 0 ||
        filters.actionRequired.some(
          actionRequired =>
            (actionRequired.name === 'On Hold' && status === 'OnHold') ||
            (actionRequired.name === 'Match' &&
              status != 'OnHold' &&
              supplierPrimaryName &&
              inventoryCode &&
              matchedDocumentID &&
              !isPostAsNew) ||
            (actionRequired.name === 'Post as New' &&
              status != 'OnHold' &&
              supplierPrimaryName &&
              inventoryCode &&
              !matchedDocumentID &&
              isPostAsNew) ||
            (actionRequired.name === 'Manually Match' &&
              status != 'OnHold' &&
              supplierPrimaryName &&
              inventoryCode &&
              !matchedDocumentID &&
              !isPostAsNew) ||
            (actionRequired.name === 'Information Required' &&
              status != 'OnHold' &&
              (!supplierPrimaryName || !inventoryCode)),
        );
      const transactionDate = moment(transDate);
      const matchDate =
        (!filters.startDate || transactionDate.isSameOrAfter(filters.startDate, 'day')) &&
        (!filters.endDate || transactionDate.isSameOrBefore(filters.endDate, 'day'));

      return matchSupplier && matchActionRequired && matchDate;
    });
  }, [filters, stagedTransactions]);

  const existingActions = new Set(
    stagedTransactions?.flatMap(row => {
      const {
        sourceSupplierPrimaryContact,
        supplierPrimaryContact,
        postingSupplierPostingInventoryCode,
        matchedDocumentID,
        datTypeID,
        status,
      } = getComparedStagedData(row);
      const supplierPrimaryName = sourceSupplierPrimaryContact?.name;
      const inventoryCode = postingSupplierPostingInventoryCode;
      const isPostAsNew =
        datTypeID === DatTypes.StagedPurchaseInvoice
          ? supplierPrimaryContact?.countPurchaseInvoices === 0
          : supplierPrimaryContact?.countDebitNotes === 0;

      return [
        status === 'OnHold' && 'On Hold',
        status != 'OnHold' && supplierPrimaryName && inventoryCode && matchedDocumentID && !isPostAsNew && 'Match',
        status != 'OnHold' &&
          supplierPrimaryName &&
          inventoryCode &&
          !matchedDocumentID &&
          isPostAsNew &&
          'Post as New',
        status != 'OnHold' &&
          supplierPrimaryName &&
          inventoryCode &&
          !matchedDocumentID &&
          !isPostAsNew &&
          'Manually Match',
        status != 'OnHold' && (!supplierPrimaryName || !inventoryCode) && 'Information Required',
      ].filter(Boolean);
    }),
  );

  const filterSections: IFilterSection[] = [
    {
      title: 'Suppliers',
      options: [
        ...new Set(
          stagedTransactions
            ?.map(row => {
              const {vendorName} = getComparedStagedData(row);
              return vendorName;
            })
            .filter(vendorName => vendorName != null),
        ),
      ].map(supplier => ({
        id: supplier || '',
        name: supplier || '',
      })),
      searchPlaceholder: 'Search suppliers',
      searchKey: 'supplier',
      filterKey: 'suppliers',
    },
    {
      title: 'Action required',
      options: ['Match', 'On Hold', 'Post as New', 'Manually Match', 'Information Required']
        .filter(action => existingActions.has(action))
        .map(action => ({
          id: action || '',
          name: action || '',
        })),
      searchPlaceholder: 'Search action required',
      searchKey: 'actionRequired',
      filterKey: 'actionRequired',
    },
  ];

  const handleSaveFilters = (f: IRequiresAttentionFilters) => {
    setIsShowFilterDialog(false);
    setFilters(f);
  };

  const handleClearAllFilter = () => {
    setIsShowFilterDialog(false);
    setFilters(initialFilters);
  };

  const handleSortChange = (value: string | undefined) => {
    if (value) {
      setSortKey(value);
      setSortDirection(prev => {
        return {
          ...prev,
          [value]: !prev[value],
        };
      });
    }
  };

  const sortData = (rows: IStagedTransactions[], sortKey: string) => {
    const sortedRows = [...rows];
    const monthOrder = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const getMonthIndex = (month: string) => monthOrder.indexOf(month);
    const direction = sortDirection[sortKey] ? 1 : -1;

    switch (sortKey) {
      case 'supplier':
        sortedRows?.sort((a: IStagedTransactions, b: IStagedTransactions) => {
          const {vendorName: vendorNameA = ''} = getComparedStagedData(a);
          const {vendorName: vendorNameB = ''} = getComparedStagedData(b);
          const nameA = vendorNameA || '';
          const nameB = vendorNameB || '';
          return (direction === 1 ? -1 : 1) * nameA.localeCompare(nameB);
        });
        break;
      case 'transactionDate':
        sortedRows?.sort((a, b) => {
          const {transDate: transDateA} = getComparedStagedData(a);
          const {transDate: transDateB} = getComparedStagedData(b);
          return direction * moment(transDateA ?? '').diff(moment(transDateB ?? ''));
        });
        break;
      case 'statementPeriod':
        sortedRows.sort((a, b) => {
          const {statementStartDate: periodA} = getComparedStagedData(a);
          const {statementStartDate: periodB} = getComparedStagedData(b);
          return direction * (getMonthIndex(periodA ?? '') - getMonthIndex(periodB ?? ''));
        });
        break;
      case 'amount':
        sortedRows.sort((a, b) => {
          const {totalInc: amountA} = getComparedStagedData(a);
          const {totalInc: amountB} = getComparedStagedData(b);
          return direction * ((amountA ?? 0) - (amountB ?? 0));
        });
        break;
      default:
        break;
    }
    return sortedRows;
  };

  const updatedSortOptions = sortOptions.map(option => ({
    ...option,
    name:
      option.id === sortKey
        ? option.name.startsWith('Supplier')
          ? `${option.name.split(' ')[0]} ${sortDirection[sortKey] ? 'Z-A' : 'A-Z'}`
          : option.name.startsWith('Amount')
            ? `${option.name.split(' - ')[0]} - ${sortDirection[sortKey] ? 'Smallest to Largest' : 'Largest to Smallest'}`
            : `${option.name.split(' - ')[0]} - ${sortDirection[sortKey] ? 'Oldest to Newest' : 'Newest to Oldest'}`
        : option.name,
  }));

  const sortedRecords = useMemo(() => {
    if (!sortKey) return sortData(filterRecords || [], 'supplier');
    return sortData(filterRecords || [], sortKey);
  }, [filterRecords, sortKey, sortDirection]);

  const getRowDataAutoId = (rowData: IStagedTransactions) => {
    const id =
      rowData?.datTypeID === DatTypes.StagedPurchaseInvoice
        ? rowData?.stagedPurchaseInvoice?.id
        : rowData?.stagedDebitNote?.id;
    return `rowRequiredAttention-${id}`;
  };

  const emptyRowData = () => {
    return (
      <Typography variant="small" className="text-center text-base font-semibold text-[#999999]">
        <p>Your Capricorn Account is fully reconciled.</p>
        <p>Check back tomorrow for new transactions.</p>
      </Typography>
    );
  };

  const handleShowPostingModal = () => {
    if (PSBLSTAGING) {
      setIsVerifytaxAmountDialog(true);
    } else setIsShowPostingPopup(true);
  };

  const handlePostAsNewButtonClick = (rowData: IStagedTransactions) => {
    const {
      refNumber,
      messageID,
      documentID,
      totalInc,
      totalTax,
      vendorName,
      datTypeID,
      postingSupplierPostingInventoryCode,
    } = getComparedStagedData(rowData);
    setPostAsNewClickedData({
      refNo: refNumber,
      supplier: vendorName || '',
      messageID: messageID,
      documentID: documentID,
      totalInc: totalInc,
      totalTax: totalTax,
      datTypeID: datTypeID,
      InventoryCode: postingSupplierPostingInventoryCode || '',
    });
    if (datTypeID === DatTypes.StagedPurchaseInvoice && PSBLSTAGING) {
      setIsVerifytaxAmountDialog(true);
    } else setIsShowPostingPopup(true);
  };

  const handleOnHoldButtonClick = (rowData: IStagedTransactions) => {
    const {refNumber, messageID, documentID, datTypeID, status, notes} = getComparedStagedData(rowData);
    setSelectedStagedTransaction(rowData);
    setOnHoldClickedData({
      refNo: refNumber,
      messageID: messageID,
      documentID: documentID,
      datTypeID: datTypeID,
      status: status,
      notes: notes,
    });
    setIsOnHold(true);
    setShowOnHoldModal(true);
  };

  const handleOnHoldDone = async (isSave: boolean, id: number) => {
    if (isSave && id) {
      const updatedData = await fetchRequireAttentionData();
      const relevantData = updatedData?.find(data => data.messageID === id);
      if (relevantData) {
        const {refNumber, messageID, documentID, datTypeID, status, notes} = getComparedStagedData(relevantData);
        setOnHoldClickedData({
          refNo: refNumber,
          messageID: messageID,
          documentID: documentID,
          datTypeID: datTypeID,
          status: status,
          notes: notes,
        });
      }
    } else {
      setShowOnHoldModal(false);
      setIsShowMatchAndLinkDialog(true);
    }
  };

  const columns = [
    {
      title: '',
      key: 'id',
      width: '2%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      headerRenderer: () => {
        const isNotSelectable =
          filterRecords?.length === 0 ||
          !filterRecords?.some(row => {
            const {sourceSupplierPrimaryContact, postingSupplierPostingInventoryCode} = getComparedStagedData(row);
            return sourceSupplierPrimaryContact?.name && postingSupplierPostingInventoryCode;
          });
        const isSelected =
          !!filterRecords?.length &&
          filterRecords?.some(row => {
            const {sourceSupplierPrimaryContact, postingSupplierPostingInventoryCode} = getComparedStagedData(row);
            return sourceSupplierPrimaryContact?.name && postingSupplierPostingInventoryCode;
          }) &&
          filterRecords
            ?.filter(row => {
              const {sourceSupplierPrimaryContact, postingSupplierPostingInventoryCode} = getComparedStagedData(row);
              return sourceSupplierPrimaryContact?.name && postingSupplierPostingInventoryCode;
            })
            .every(row => {
              const {refNumber} = getComparedStagedData(row);
              return selectedRows.some(selectedRow => selectedRow.refNo === refNumber);
            });

        return (
          <>
            {isNotSelectable ? (
              <ARTooltip
                arrow
                title={
                  <Typography
                    variant="paragraph"
                    className="min-w-[200px] text-center text-[10px] font-medium text-black-800"
                  >
                    Not selectable
                  </Typography>
                }
              >
                <span
                  className={`cursor-pointer items-center font-poppins text-base font-normal opacity-30 grayscale`}
                  data-autoid={`chkAllTransactionRow`}
                >
                  <ARSelectToggler containerClassName="!py-0" key={`checkbox`} isSelected={isSelected} />
                </span>
              </ARTooltip>
            ) : (
              <span
                className={`cursor-pointer items-center font-poppins text-base font-normal`}
                onClick={() => handleSelectAll()}
                data-autoid={`chkAllTransactionRow`}
              >
                <ARSelectToggler containerClassName="!py-0" key={`checkbox`} isSelected={isSelected} />
              </span>
            )}
          </>
        );
      },
      rowRenderer: (rowData: IStagedTransactions) => {
        const {
          vendorName,
          supplierPrimaryContact,
          postingSupplierPostingInventoryCode,
          postingSupplierPostingInventoryID,
          refNumber,
          messageID,
          documentID,
          totalInc,
          totalTax,
          datTypeID,
        } = getComparedStagedData(rowData);
        const supplierPrimaryName = supplierPrimaryContact?.name;
        const isNotSelectable = !supplierPrimaryName || !postingSupplierPostingInventoryCode;
        const isSelected = selectedRows.some(selectedRow => selectedRow.refNo === refNumber);
        return (
          <>
            {isNotSelectable ? (
              <ARTooltip
                arrow
                title={
                  <Typography
                    variant="paragraph"
                    className="min-w-[200px] text-center text-[10px] font-medium text-black-800"
                  >
                    This {refNumber} cannot be selected as the Post Supplier or Post Inventory is not selected.
                  </Typography>
                }
              >
                <span
                  className={`cursor-pointer items-center font-poppins text-base font-normal opacity-30 grayscale`}
                  data-autoid={`chkTransactionRow`}
                >
                  <ARSelectToggler containerClassName="!py-0" key={`checkbox${refNumber}`} isSelected={isSelected} />
                </span>
              </ARTooltip>
            ) : (
              <span
                className={`cursor-pointer items-center font-poppins text-base font-normal`}
                onClick={() =>
                  handleSelect({
                    supplier: vendorName ? vendorName : '',
                    refNo: refNumber ? refNumber : '',
                    messageID: messageID,
                    documentID: documentID,
                    totalInc: totalInc,
                    totalTax: totalTax,
                    datTypeID: datTypeID,
                    InventoryCode: String(postingSupplierPostingInventoryCode),
                    InventoryID: Number(postingSupplierPostingInventoryID),
                  })
                }
                data-autoid={`chkTransactionRow`}
              >
                <ARSelectToggler containerClassName="!py-0" key={`checkbox${refNumber}`} isSelected={isSelected} />
              </span>
            )}
          </>
        );
      },
    },
    {
      title: 'Supplier',
      key: 'supplier',
      width: '10%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IStagedTransactions) => {
        const {vendorName} = getComparedStagedData(rowData);
        return (
          <Typography
            data-autoid={`lblSupplierName`}
            variant="paragraph"
            className="inline overflow-ellipsis font-medium"
          >
            {vendorName}
          </Typography>
        );
      },
    },
    {
      title: 'Ref No.',
      key: 'refNo',
      width: '8%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IStagedTransactions) => {
        const {refNumber} = getComparedStagedData(rowData);
        return (
          <Typography data-autoid={`lblRefNo`} variant="paragraph" className="inline overflow-ellipsis font-medium">
            {refNumber}
          </Typography>
        );
      },
    },
    {
      title: 'Trans date',
      key: 'transactionDate',
      width: '8%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IStagedTransactions) => {
        const {transDate} = getComparedStagedData(rowData);
        return (
          <Typography
            data-autoid={`lblTransactionDate`}
            variant="paragraph"
            className="inline overflow-ellipsis font-medium"
          >
            {moment(transDate).format('DD MMM YYYY')}
          </Typography>
        );
      },
    },
    {
      title: 'Statement',
      key: 'statementPeriod',
      width: '8%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IStagedTransactions) => {
        const {statementStartDate} = getComparedStagedData(rowData);
        return (
          <Typography
            data-autoid={`lblStatementPeriod`}
            variant="paragraph"
            className="inline overflow-ellipsis font-medium"
          >
            {statementStartDate ? moment(statementStartDate).format('MMMM') : '-'}
          </Typography>
        );
      },
    },
    {
      title: 'Total',
      key: 'invoiceTotal',
      width: '8%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IStagedTransactions) => {
        const {totalInc, datTypeID} = getComparedStagedData(rowData);
        return (
          <Typography
            data-autoid={`lblInvoiceTotal`}
            variant="paragraph"
            className="inline overflow-ellipsis font-medium"
          >
            {datTypeID === DatTypes.StagedDebitNote ? `(${PriceFormat(totalInc)})` : PriceFormat(totalInc)}
          </Typography>
        );
      },
    },
    {
      title: 'Amount paid',
      key: 'amountPaid',
      width: '8%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IStagedTransactions) => {
        const {totalInc, balance} = getComparedStagedData(rowData);
        const amount = (totalInc || 0) - (balance || 0);
        return (
          <Typography
            data-autoid={`lblAmountPaid`}
            variant="paragraph"
            className="inline overflow-ellipsis font-medium"
          >
            {PriceFormat(amount)}
          </Typography>
        );
      },
    },
    {
      title: 'Outstanding',
      key: 'outstanding',
      width: '8%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IStagedTransactions) => {
        const {balance, datTypeID} = getComparedStagedData(rowData);
        return (
          <Typography
            data-autoid={`lblOutstanding`}
            variant="paragraph"
            className="inline overflow-ellipsis font-medium"
          >
            {datTypeID === DatTypes.StagedDebitNote ? `(${PriceFormat(balance)})` : PriceFormat(balance)}
          </Typography>
        );
      },
    },
    {
      title: 'Posting',
      key: 'posting',
      width: '13%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IStagedTransactions) => {
        const {supplierPrimaryContact} = getComparedStagedData(rowData);
        const supplierName = supplierPrimaryContact?.name;
        return (
          <>
            {supplierName ? (
              <Typography
                data-autoid={`lblPosting`}
                variant="paragraph"
                className="inline overflow-ellipsis font-medium"
              >
                {supplierName}
              </Typography>
            ) : (
              <Typography
                data-autoid={`lblPosting`}
                variant="paragraph"
                className="inline overflow-ellipsis font-medium"
              >
                -
              </Typography>
            )}
          </>
        );
      },
    },
    {
      title: 'Post inventory as',
      key: 'postInventoryAs',
      width: '13%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IStagedTransactions) => {
        const {postingSupplierPostingInventoryCode} = getComparedStagedData(rowData);
        return (
          <>
            {postingSupplierPostingInventoryCode ? (
              <Typography
                data-autoid={`lblPostInventoryAs`}
                variant="paragraph"
                className="inline overflow-ellipsis font-medium"
              >
                {postingSupplierPostingInventoryCode}
              </Typography>
            ) : (
              <Typography
                data-autoid={`lblPostInventoryAs`}
                variant="paragraph"
                className="inline overflow-ellipsis font-medium"
              >
                -
              </Typography>
            )}
          </>
        );
      },
    },
    {
      title: 'Action required',
      key: 'actionRequired',
      width: '13%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: IStagedTransactions) => {
        const {supplierPrimaryContact, postingSupplierPostingInventoryCode, datTypeID, status} =
          getComparedStagedData(rowData);
        const supplierName = supplierPrimaryContact?.name;
        const documentID = rowData?.matchedDocumentID;
        const isMatched = documentID !== null;
        const isPostAsNew =
          datTypeID === DatTypes.StagedPurchaseInvoice
            ? supplierPrimaryContact?.countPurchaseInvoices === 0
            : supplierPrimaryContact?.countDebitNotes === 0;

        if (status === 'OnHold') {
          return (
            <button
              data-autoid={`btnActionRequired`}
              className="font-poppins text-base font-medium text-primary"
              onClick={() => handleOnHoldButtonClick(rowData)}
            >
              {'On Hold'}
            </button>
          );
        } else if (isPostAsNew && supplierName && postingSupplierPostingInventoryCode) {
          return (
            <button
              data-autoid={`btnActionRequired`}
              className="font-poppins text-base font-medium text-primary"
              onClick={() => handlePostAsNewButtonClick(rowData)}
            >
              {'Post as New'}
            </button>
          );
        } else if (!isPostAsNew && supplierName && postingSupplierPostingInventoryCode) {
          return (
            <button
              data-autoid={`btnActionRequired`}
              className="font-poppins text-base font-medium text-primary"
              onClick={() => {
                setSelectedStagedTransaction(rowData);
                setIsShowMatchAndLinkDialog(true);
              }}
            >
              {isMatched ? 'Match' : 'Manually Match'}
            </button>
          );
        } else {
          return (
            <button
              data-autoid={`btnActionRequired`}
              className="font-poppins text-base font-medium text-primary"
              onClick={() => {
                setSelectedStagedTransaction(rowData);
                if (supplierName) {
                  setIsShowPostInventoryAsPopup(true);
                } else {
                  setIsShowPostSupplierAsPopup(true);
                }
              }}
            >
              {'Information Required'}
            </button>
          );
        }
      },
    },
  ];

  const leftPannel = (
    <div className={`relative flex h-full flex-col overflow-hidden rounded-md bg-primary-background`}>
      <div className="h-[inherit] w-full">
        <div className={`mt-1 flex items-center justify-between rounded-t-md border-b border-[#D8D8D8] bg-white p-2.5`}>
          <h2 className="font-poppins text-xl font-light text-spenda-primarytext">Requires Attention</h2>
          <div className="flex flex-row items-center justify-end gap-2.5">
            {PSBLSTAGING ? (
              <>
                {selectedRows.length >= 1 ? (
                  <>
                    <Button
                      type="button"
                      onClick={() => {
                        setPostAsNewClickedData(undefined);
                        handleShowPostingModal();
                      }}
                      variant="filled"
                      color="primary"
                      data-autoid={`btnPostAsANewInvoice`}
                    >
                      Post as New Invoice
                    </Button>
                    <Button
                      type="button"
                      variant="outlined"
                      color="error"
                      data-autoid={`btnDismissTransaction`}
                      onClick={() => setIsShowDismissDialog(true)}
                    >
                      Dismiss Transaction
                    </Button>
                  </>
                ) : (
                  <>
                    {/* {psblV22LowTouchOnboarding92923 && (
                      <RefreshDataBtn
                        newOnly
                        datTypeId={DatTypes.PurchaseInvoice}
                        dataAutoID="btnARRefresh"
                        title="Refresh Capricorn Data"
                        handleRefreshData={() => {
                          fetchRequireAttentionData();
                        }}
                      />
                    )} */}
                    <ArRefreshBtn
                      newOnly
                      isNewBtn={psblV22LowTouchOnboarding92923}
                      datTypeId={DatTypes.PurchaseInvoice}
                      dataAutoID="btnARRefresh"
                      title={`Refresh ${financialAdaptorDisplayName} Data`}
                      handleRefreshData={() => fetchRequireAttentionData()}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                <ArRefreshBtn
                  datTypeId={DatTypes.PurchaseInvoice}
                  dataAutoID="btnARRefresh"
                  handleRefreshData={() => {
                    fetchRequireAttentionData();
                  }}
                  newOnly
                />
                {selectedRows.length >= 1 && (
                  <Button
                    type="button"
                    onClick={() => {
                      setPostAsNewClickedData(undefined);
                      handleShowPostingModal();
                    }}
                    variant="filled"
                    color="primary"
                    data-autoid={`btnPostAsANewInvoice`}
                  >
                    Post as New
                  </Button>
                )}
              </>
            )}
            <IconButton
              variant="outlined"
              color="primary"
              data-autoid={'btnAdvanceFilter'}
              onClick={() => setIsShowFilterDialog(true)}
            >
              <IconFilter />
            </IconButton>
            <Menu>
              <MenuHandler>
                <IconButton variant="outlined" data-autoid={'btnSortFilter'} className="focus:outline-none">
                  <IconSort />
                </IconButton>
              </MenuHandler>
              <MenuList className="flex flex-col p-0">
                {updatedSortOptions?.map((option, index) => (
                  <MenuItem
                    data-autoid={`lblSortKey${option.id}`}
                    key={option.id}
                    color="primary"
                    onClick={() => handleSortChange(option.id)}
                    className={`rounded-none ${option.id === sortKey && 'bg-primary/[0.2]'} ${index !== sortOptions.length - 1 ? 'border-b border-[#D8D8D8]' : ''} p-2`}
                  >
                    <Typography variant="small" className="text-sm font-medium text-[#333333]">
                      {option.name}
                    </Typography>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </div>
        </div>
        <div className={`invoicesList h-[inherit] overflow-auto bg-white pb-20 pt-2.5`}>
          <ARTable
            isHighlightRowOnHover
            isPaginated
            dataAutoId={`RequiredAttention`}
            tableClass="mb-12"
            isLoading={isLoading}
            columns={columns}
            rows={sortedRecords || []}
            getRowDataAutoId={getRowDataAutoId}
            emptyDataMessage={emptyRowData}
          />
        </div>
      </div>
      {isShowFilterDialog && (
        <AdvanceFilter
          showModal={isShowFilterDialog}
          setOpen={() => setIsShowFilterDialog(!isShowFilterDialog)}
          onSave={handleSaveFilters}
          onClear={handleClearAllFilter}
          filterSections={filterSections}
          selectedFilters={filters}
          dateButtons={datePickerButtons}
        />
      )}
      {isShowMatchAndLinkDialog && selectedStagedTransaction && (
        <MatchAndLinkRequireAttentionDialog
          isShow={isShowMatchAndLinkDialog}
          handleClose={() => setIsShowMatchAndLinkDialog(false)}
          selectedStagedTransaction={selectedStagedTransaction}
          handleDone={handleMatchAndLinkDone}
          PSBLSTAGING={PSBLSTAGING}
        />
      )}
      {isShowPostSupplierAsPopup && selectedStagedTransaction && (
        <PostingTypeModal
          isVendorPostingType
          vendorPostingTypeDetail={selectedStagedTransaction}
          supplierDetails={getComparedStagedData(selectedStagedTransaction)?.sourceSupplierPrimaryContact}
          handleClose={() => setIsShowPostSupplierAsPopup(false)}
          handleDone={handlePostingAsSupplierDone}
          scope="PSBL"
          isDialog
        />
      )}
      {isShowPostInventoryAsPopup && (
        <SelectPostingInventoryModal
          isVendorSelected
          selectedSupplierData={convertIntoSuppliersStaggedTransaction(selectedStagedTransaction)}
          selectedVendorData={selectedStagedTransaction}
          handleClose={() => setIsShowPostInventoryAsPopup(false)}
          handleDone={handlePostAsInventoryDone}
          scope="PSBL"
        />
      )}
      {isShowPostingPopup && (
        <PostAsNewInvoiceModal
          isOpen={isShowPostingPopup}
          onClose={() => setIsShowPostingPopup(false)}
          handleDone={handlePostAsNewInvoiceDone}
          selectedRows={postAsNewClickedData ? [postAsNewClickedData] : selectedRows}
          taxAdjusments={taxAdjusments}
          PSBLSTAGING={PSBLSTAGING}
        />
      )}
      {isVerifytaxAmountDialog && PSBLSTAGING && (
        <VerifyingTaxAmountDialog
          isOpen={isVerifytaxAmountDialog}
          handleDone={handleVerifyTaxAmountDone}
          onClose={() => setIsVerifytaxAmountDialog(false)}
          selectedRows={postAsNewClickedData ? [postAsNewClickedData] : selectedRows}
        />
      )}
      {showOnHoldModal && onHoldClickedData && (
        <OnHoldModal
          isOnHold={isOnHold}
          isOpen={showOnHoldModal}
          onClose={() => setShowOnHoldModal(false)}
          selectedRows={onHoldClickedData}
          handleDone={(isSave: boolean, messageID: number) => handleOnHoldDone(isSave, messageID)}
        />
      )}
      {isShowDismissDialog && PSBLSTAGING && (
        <DismissTransactionModal
          isOpen={isShowDismissDialog}
          onClose={() => setIsShowDismissDialog(false)}
          selectedRows={selectedRows}
          handleDone={handleDismissTransaction}
        />
      )}
      {PSBLSTAGING && false && (
        <div className="absolute right-0 top-0 flex h-[58px] w-[375px] items-center rounded-lg bg-white p-4 font-poppins text-xs text-primary shadow-md">
          <div className={`flex h-10 w-10 min-w-10 items-center justify-center rounded-[10px] bg-[#E3EEF5]`}>
            <IconDocument />
          </div>
          <p className="px-2 text-left">Invoices will start importing from the next statement period [insert date].</p>
          <p className="text-gray-500">1m</p>
        </div>
      )}
    </div>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
      <MarketplaceLayout leftPanel={leftPannel} splitWidthType={4} />
      <PSBLFooter />
    </div>
  );
};

export const convertIntoSuppliersStaggedTransaction = (
  tx: IStagedTransactions | undefined,
): ISuppliersStagedTransactions | undefined => {
  if (!tx) return;
  const {
    isSourceSupplierVendorMappingEnabled,
    sourceSupplierCreditLineAccountID,
    isSourceSupplierPosted,
    supplierPrimaryContact,
    sourceSupplierPrimaryContact,
    sourceSupplierPostingInventoryID,
    sourceSupplierPostingInventoryCode,
    isSourceSupplierInventoryMappingEnabled,
  } = tx.datTypeID === DatTypes.StagedPurchaseInvoice ? tx?.stagedPurchaseInvoice || {} : tx?.stagedDebitNote || {};

  return {
    sourceSupplier: sourceSupplierPrimaryContact,
    mappedSupplier: supplierPrimaryContact,
    transactionsList: [
      {
        stagedDebitNote: tx?.stagedDebitNote || null,
        stagedPurchaseInvoice: tx?.stagedPurchaseInvoice || null,
        datTypeID: tx.datTypeID,
        messageID: tx.messageID,
      },
    ],
    isSourceSupplierVendorMappingEnabled: Boolean(isSourceSupplierVendorMappingEnabled),
    isSourceSupplierPosted: Boolean(isSourceSupplierPosted),
    sourceSupplierCreditLineAccountID: sourceSupplierCreditLineAccountID || null,
    sourceSupplierPostingInventoryID,
    sourceSupplierPostingInventoryCode,
    isSourceSupplierInventoryMappingEnabled,
  };
};

export const getComparedStagedData = (tx: IStagedTransactions) => {
  if (tx.datTypeID === DatTypes.StagedPurchaseInvoice) {
    return {...tx, ...(tx.stagedPurchaseInvoice as IStagedPurchaseInvoice)};
  }
  return {...tx, ...(tx.stagedDebitNote as IStagedDebitNote)};
};
