import React, {SetStateAction, useState} from 'react';
import {Button, Typography} from 'spenda-ui-react';
import IconQuotesDivider from './../../assets/svg/quotes-management/IconQuotesDivider';
import {SpendaNoTagLogo} from './../../assets/svg/SpendaNoTagLogo';
import SecurityCode from './../../components/inputs/SecurityCode';
import {IActiveSession} from './../../model/quotes/quotes';
import {useSupplierTransactionsAPI} from '../../services/useSupplierTransactionsAPI';

interface IPayByLinkCNPAuthorizeProps {
  onClose: React.Dispatch<SetStateAction<boolean>>;
  handleResendCode: () => void;
  inviteCode: string;
  abn?: string;
  supplierName?: string;
  logo?: string;
  setActiveSession: React.Dispatch<SetStateAction<IActiveSession>>;
  inviteSource?: string;
  refetchUnmaskedInvoiceDetails: (approvalSessionGUID: string) => void;
}

export const PayByLinkCNPAuthorize = (props: IPayByLinkCNPAuthorizeProps) => {
  const [securityCode, setSecurityCode] = useState<string>('');
  const [securityCodeErrorMsg, setSecuityCodeErrorMsg] = useState('');
  const [isSending, setIsSending] = useState<boolean>(false);

  const {submitApprovalCode} = useSupplierTransactionsAPI();

  const onEnterVerificationCode = async () => {
    setSecuityCodeErrorMsg('');
    if (securityCode.trim().length < 6) {
      return;
    }
  };

  const handleViewQuote = async () => {
    // verify otp
    submitApprovalCode(props.inviteCode, securityCode)
      .then(async response => {
        props.setActiveSession({...response, isAuthorised: true});
        props.refetchUnmaskedInvoiceDetails(response.approvalSessionGUID);
        props.onClose(false);
      })
      .catch(error => {
        if (error && error?.length && error?.[0]?.message) {
          setSecuityCodeErrorMsg(error?.[0]?.message);
        }
      })
      .finally(() => setIsSending(false));
  };

  const handleChangeSecurityCode = (code: any) => {
    if (securityCodeErrorMsg) setSecuityCodeErrorMsg('');
    return setSecurityCode(code);
  };

  return (
    <div className="happyFlowBg flex h-full w-full items-center justify-center">
      <div className="flex w-full max-w-[750px] flex-col items-center justify-center text-black-800 md:flex-row">
        <div className="mb-7 flex w-[357px] flex-col items-center justify-center md:mb-0">
          <div className="w-full max-w-[160px] pt-2 text-center sm:max-w-max">
            {props?.logo ? (
              <img src={props?.logo} alt="Preview Logo" className={`mx-auto h-[100px] w-[100px]`} />
            ) : (
              <SpendaNoTagLogo height={'40px'} width={'100px'} className="mx-auto" />
            )}
          </div>
          {props.abn ? (
            <Typography className="mt-3">ABN: {props.abn}</Typography>
          ) : (
            <Typography className="mt-3">{props.supplierName}</Typography>
          )}
          <div className="mt-4 flex flex-col items-center justify-center gap-y-5 md:gap-y-3"></div>
        </div>
        <IconQuotesDivider className="hidden md:block" />
        <div className="flex w-full max-w-[400px] flex-col items-center justify-center gap-2 md:max-w-[357px]">
          <Typography className="mb-5 text-center md:mb-9 md:max-w-[196px]" variant="small">
            {`Please enter the 6 digit code sent to you ending in ${props.inviteSource} to view this invoice.`}
          </Typography>
          <div className="mb-4 flex gap-2">
            <SecurityCode
              is2SA={true}
              onChange={code => handleChangeSecurityCode(code)}
              value={securityCode}
              onEnter={onEnterVerificationCode}
              newCodeDesign
              // below key to be used to show invalid code
              invalidCode={!!securityCodeErrorMsg}
            ></SecurityCode>
          </div>
          <button
            type="button"
            className="cursor-pointer"
            onClick={() => props.handleResendCode()}
            disabled={isSending}
          >
            <Typography className=" mb-3 font-semibold text-primary">Resend Code</Typography>
          </button>
          <Button
            variant="filled"
            color="primary"
            onClick={() => {
              setIsSending(true);
              handleViewQuote();
            }}
            disabled={securityCode.trim().length < 6 || isSending}
          >
            {'View Invoice'}
          </Button>
        </div>
      </div>
    </div>
  );
};
