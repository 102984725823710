import React, {useState, useCallback, useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Formik, FormikHelpers, FormikProps} from 'formik';
import clsx from 'clsx';
import {debounce, pick} from 'lodash';
import * as Yup from 'yup';

import {AUTH_PURCHASING_DELIVERY_RECEIPT_DETAIL} from '../../routes/PurchasingRoutes';
import AppContext from '../../context/app/appContext';
import {IDeliveryReceipt} from '../../model/delivery-receipt/DeliveryReceipt';
import {DatTypes} from '../../model/constants/Constants';
import {ISupplier} from '../../model/supplier/Supplier';
import {IWarehouse} from '../../model/Warehouse';
import {SDialog} from '../modals/modalSpendaMeterialUI';
import {STextField} from '../inputs/STextField';
import SDatePicker from '../pickers/DatePicker';
import LoadingIndicator from '../ui/LoadingIndicator';
import {PrimaryButton, SecondaryButton} from '../buttons/DefaultButtons';
import {CreateSupplierDialog} from '../dialog/CreateSupplierDialog';
import useDeliveryReceiptAPI from '../../services/useDeliveryReceiptAPI';
import {useSupplierAPI} from '../../services/useSupplierAPI';
import useWarehouseAPI from '../../services/useWarehouseAPI';
import {Toast} from '../../utils/Toast';

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  IconButton,
  MenuItem,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import ArrowLeftRoundedIcon from '@material-ui/icons/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';
import {IconEdit} from '../../assets/svg/IconEdit';
import {Carousel} from 'spenda-ui-react';

interface ICreatePurchaseInvoice {
  open: boolean;
  onClose: (refetchList?: boolean) => void;
  deliveryReceiptID?: string;
  isSupplierSelect?: boolean;
  deliveryReceiptData?: string;
  isReadOnly?: boolean;
}

const StyledMenuItem = withStyles({
  root: {
    fontFamily: 'Poppins !important',
    '&:hover': {
      backgroundColor: '#1C78AD20 !important',
    },
  },
  selected: {
    backgroundColor: '#1C78AD50 !important',
    '&:hover': {
      backgroundColor: '#1C78AD50 !important',
    },
  },
})(MenuItem);

const useCreateDeliveryReceiptDialog = makeStyles(() => ({
  dialogPaper: {
    minWidth: '771px',
    minHeight: '513px',
    fontFamily: 'Poppins, sans-serif',
  },
  dialogTitle: {
    borderBottom: '1px solid #EAEAEA',
    '& h2': {
      fontSize: '22px',
      fontFamily: 'Poppins, sans-serif',
      fontWeight: 300,
    },
  },
  dialogActions: {
    margin: '8px',
    borderRadius: '8px',
    backgroundColor: '#ADADAD20',
    padding: '8px 12px',
    justifyContent: 'space-between',
  },
  textfield: {
    width: '354px !important',
    marginTop: '24px',
    marginBottom: '24px',
    '& .MuiOutlinedInput-root': {
      paddingRight: '0px !important',
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #1c78ad !important',
      },
      '& .MuiInputAdornment-root': {
        marginRight: '0px !important',
      },
    },
  },
  iconButtonRoot: {
    padding: '0px 6px',
    height: '45px',
    backgroundColor: '#1C78AD',
    borderRadius: '0 6px 6px 0',
    '& svg': {
      color: '#FFFFFF !important',
    },
    '&:hover': {
      padding: '0px 6px',
      height: '45px',
      backgroundColor: '#1C78AD',
      borderRadius: '0 6px 6px 0',
      '& svg': {
        color: '#FFFFFF !important',
      },
    },
  },
}));

const validationSchema = Yup.object({
  RefNumber: Yup.string().max(50, 'Ref max 50 char'),
  DeliveryDate: Yup.date().required('Delivery date is required'),
  WarehouseID: Yup.string().required('Warehouse is required'),
});

const prevArrow = ({loop, handlePrev, firstIndex}: {loop: boolean; handlePrev: () => void; firstIndex: boolean}) => {
  return (
    <button
      data-autoid="btnPrev"
      onClick={handlePrev}
      disabled={!loop && firstIndex ? true : false}
      className="!absolute left-[-45px] top-[50%] z-10 grid h-12 max-h-[55px] w-12 max-w-[55px] -translate-y-2/4 select-none place-items-center transition-all disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
    >
      <ArrowLeftRoundedIcon style={{color: 'inherit', fontSize: '55px'}} />
    </button>
  );
};

const nextArrow = ({loop, handleNext, lastIndex}: {loop: boolean; handleNext: () => void; lastIndex: boolean}) => {
  return (
    <button
      data-autoid="btnNext"
      onClick={handleNext}
      disabled={!loop && lastIndex ? true : false}
      className="!absolute right-[-35px] top-[50%] z-10 grid h-12 max-h-[55px] w-12 max-w-[55px] -translate-y-2/4 select-none place-items-center transition-all disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
    >
      <ArrowRightRoundedIcon style={{color: 'inherit', fontSize: '55px'}} />
    </button>
  );
};

const initialFilter = {
  IsShowActiveOnly: true,
  DatTypeID: DatTypes.Suppliers,
  IsApprovedForWeb: true,
  IsShowAccountCustomersOnly: false,
  MaxResults: 40,
  IsExactMatch: false,
  SortAsc: false,
  SortField: 'Name',
};

export interface ICreateDeliveryReceiptValues extends Partial<IDeliveryReceipt> {}

const newPurchaseInvoice: ICreateDeliveryReceiptValues = {
  RefNumber: '',
  DeliveryDate: new Date(),
  LineIDs: [],
  Lines: [],
  WarehouseID: undefined,
  SupplierID: undefined,
};

export const CreateDeliveryReceiptDialog = (props: ICreatePurchaseInvoice) => {
  const history = useHistory();
  const {tenantInfo} = useContext(AppContext);
  const classes = useCreateDeliveryReceiptDialog();
  const {open, onClose, deliveryReceiptID, isSupplierSelect, deliveryReceiptData, isReadOnly} = props;

  const defaultWarehouseID = tenantInfo?.TenantUserDetails?.DefaultWarehouseID;

  const [deliveryReceiptToEdit, setDeliveryToEdit] = React.useState<ICreateDeliveryReceiptValues>(newPurchaseInvoice);
  const [supplierId, setSupplierId] = useState<any>(undefined);
  const [createSupplierDialog, setCreateSupplierDialog] = useState<boolean>(false);
  const [selectedSupplier, setSelectedSupplier] = useState<ISupplier>();

  const {
    get: getDeliveryReceiptById,
    generateDeliveryReceipt,
    save: saveDeliveryReceipt,
    isLoading,
  } = useDeliveryReceiptAPI();

  useEffect(() => {
    if (open && deliveryReceiptID) {
      getDeliveryReceiptById(Number(deliveryReceiptID)).then(res => {
        setDeliveryToEdit(res);
      });
    }
  }, [deliveryReceiptID, open]);

  useEffect(() => {
    if (open && deliveryReceiptData) {
      history.replace({state: {}});
      setDeliveryToEdit(JSON.parse(deliveryReceiptData));
    }
  }, [open, deliveryReceiptData]);

  const handleCreateSupplier = () => {
    setCreateSupplierDialog(true);
  };

  const handleCreateSupplierClose = (supplier?: ISupplier) => {
    setCreateSupplierDialog(false);
    if (supplier) {
      setSelectedSupplier(supplier);
    }
    setSupplierId(null);
  };

  const handleCancelClick = (refetch: boolean = false) => {
    setSelectedSupplier(undefined);
    setSupplierId(undefined);
    onClose(refetch);
  };

  const handleEditSupplierClick = (supplier: ISupplier) => {
    setCreateSupplierDialog(true);
    setSupplierId(supplier.ID);
  };

  const onSubmit = async (
    values: ICreateDeliveryReceiptValues,
    helpers: FormikHelpers<ICreateDeliveryReceiptValues>,
  ): Promise<void> => {
    let payload: ICreateDeliveryReceiptValues = pick(values, [
      'RefNumber',
      'DeliveryDate',
      'LineIDs',
      'Lines',
      'WarehouseID',
      'SupplierID',
    ]);

    if (!payload.WarehouseID) {
      payload.WarehouseID = defaultWarehouseID;
    }

    if (deliveryReceiptID) {
      return saveDeliveryReceipt({...payload, ID: deliveryReceiptID}).then(res => {
        if (res && res.ID) {
          helpers.resetForm();
          handleCancelClick(true);
        }
      });
    } else {
      return generateDeliveryReceipt(payload).then(res => {
        if (res && res.ID) {
          helpers.resetForm();
          Toast.info('Delivery Receipt created');
          history.push(AUTH_PURCHASING_DELIVERY_RECEIPT_DETAIL.replace(/:deliveryReceiptID/g, String(res.ID)));
        }
      });
    }
  };

  return (
    <>
      <CreateSupplierDialog open={createSupplierDialog} onClose={handleCreateSupplierClose} supplierId={supplierId} />
      <SDialog classes={{paper: classes.dialogPaper}} fullWidth maxWidth="sm" open={open && !createSupplierDialog}>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={deliveryReceiptToEdit}
          onSubmit={onSubmit}
        >
          {props => (
            <>
              <DialogTitle className={clsx(classes.dialogTitle, 'text-center font-light text-spenda-primarytext')}>
                Delivery details
              </DialogTitle>
              <DialogContent className="flex flex-col items-center justify-center">
                <LoadingIndicator
                  isLoading={isLoading}
                  position={{
                    height: '100% !important',
                    display: 'flex',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                  size="md"
                  color="#1C78AD"
                />
                {!props.values.SupplierID || isSupplierSelect ? (
                  <SelectSupplier
                    {...props}
                    selectedSupplier={selectedSupplier}
                    handleEditSupplierClick={handleEditSupplierClick}
                    setSelectedSupplier={setSelectedSupplier}
                  />
                ) : null}
                {props.values.SupplierID && !isSupplierSelect ? (
                  <DeliveryReceiptDetail {...props} defaultWarehouseID={defaultWarehouseID} isReadOnly={isReadOnly} />
                ) : null}
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <SecondaryButton label="Cancel" onClick={handleCancelClick} />
                <div>
                  {!props.values.SupplierID ? (
                    <SecondaryButton label="Create New Supplier" onClick={handleCreateSupplier} />
                  ) : null}
                  {props.values.SupplierID ? (
                    <PrimaryButton
                      margin="0 0 0 8px"
                      label="Save & Close"
                      disabled={!props.isValid || props.isSubmitting}
                      onClick={props.handleSubmit}
                    />
                  ) : null}
                </div>
              </DialogActions>
            </>
          )}
        </Formik>
      </SDialog>
    </>
  );
};

const SelectSupplier = (
  props: FormikProps<ICreateDeliveryReceiptValues> & {
    selectedSupplier?: ISupplier;
    handleEditSupplierClick: (supplier: ISupplier) => void;
    setSelectedSupplier: (supplier: ISupplier) => void;
  },
) => {
  const classes = useCreateDeliveryReceiptDialog();
  const {errors, touched, selectedSupplier, handleEditSupplierClick, setSelectedSupplier, setFieldValue} = props;

  const [suggestionsInput, setSuggestionsInput] = useState<string>('');
  const [suggestions, setSuggestions] = useState<ISupplier[]>([]);

  useEffect(() => {
    getSupplierList();
  }, []);

  useEffect(() => {
    if (selectedSupplier) {
      setSuggestions([selectedSupplier]);
    }
  }, [selectedSupplier]);

  const {dashboard, isLoading} = useSupplierAPI();

  const debouncedSave = useCallback(
    debounce((newValue: string) => {
      getSupplierList(newValue);
    }, 500),
    [suggestions],
  );

  const handleMousedown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleDropDownClick = () => {
    if (suggestionsInput) {
      getSupplierList(suggestionsInput);
    }
  };

  const getSupplierList = (newValue: string = '') => {
    dashboard({
      ...initialFilter,
      ...(newValue ? {SearchString: newValue} : {}),
    }).then((res: ISupplier[]) => {
      setSuggestions(res || []);
    });
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSuggestionsInput(event.target.value);
    if (event.target.value.length > 2) {
      debouncedSave(event.target.value);
    }
  };

  const handleSupplierSelect = (supplier: ISupplier) => {
    setSelectedSupplier(supplier);
    setFieldValue('SupplierID', supplier.ID);
  };

  return (
    <>
      <p className="text-xs text-spenda-primarytext">
        Please select the Supplier you are receiving this delivery from.
      </p>
      <STextField
        v2
        variant="outlined"
        name="SearchSuppliers"
        className={classes.textfield}
        value={suggestionsInput}
        onChange={onChange}
        placeholder="Search by name, phone or address"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                className={classes.iconButtonRoot}
                onClick={handleDropDownClick}
                onMouseDown={handleMousedown}
                data-autoid="btnAutocompleteEndAdornment"
              >
                <SearchRoundedIcon color="inherit" fontSize="large" />
              </IconButton>
            </InputAdornment>
          ),
        }}
        helperText={(touched?.SupplierID as boolean) && (errors?.SupplierID as string)}
        error={touched?.SupplierID && Boolean(errors?.SupplierID)}
      />
      {!suggestions.length && !isLoading && suggestionsInput ? <span>No results found</span> : null}
      {suggestions.length ? (
        <div className="relative">
          <Carousel className="static max-w-[685px] py-2" itemsToShow={4} nextArrow={nextArrow} prevArrow={prevArrow}>
            {suggestions.map(suggestion => (
              <div key={suggestion.ID} className="h-full w-full px-1">
                <div
                  className={clsx(
                    `relative flex h-[100px] w-[150px] cursor-pointer items-center justify-center rounded-md 
                bg-white p-1 font-poppins text-xs font-semibold text-primary shadow-[0_0_6px_0_#908E8E50]`,
                    {
                      'bg-[#33333320]': selectedSupplier?.ID === suggestion.ID,
                    },
                  )}
                  onClick={() => handleSupplierSelect(suggestion)}
                  data-autoid={`btnSuggestion${suggestion.ID}`}
                >
                  <p className="line-clamp-4 text-center">{suggestion.Name}</p>
                  <IconButton
                    className={clsx('!absolute right-0 top-0 !p-[6px]')}
                    onClick={e => {
                      e.stopPropagation();
                      handleEditSupplierClick(suggestion);
                    }}
                    onMouseDown={handleMousedown}
                    data-autoid={`btnEditSupplier${suggestion.ID}`}
                  >
                    <IconEdit iconColor="#1C78AD" height="13px" width="13px" />
                  </IconButton>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      ) : null}
    </>
  );
};

const DeliveryReceiptDetail = (
  props: FormikProps<ICreateDeliveryReceiptValues> & {
    defaultWarehouseID: number | undefined;
    isReadOnly?: boolean;
  },
) => {
  const {values, errors, touched, handleChange, handleBlur, setFieldValue, setValues, defaultWarehouseID, isReadOnly} =
    props;

  const [warehouses, setWarehouses] = React.useState<IWarehouse[]>([]);

  const {search: searchWarehouse, isLoading} = useWarehouseAPI();

  useEffect(() => {
    searchWarehouse().then(res => {
      setWarehouses(res);
    });
  }, []);

  useEffect(() => {
    if (!values.WarehouseID) {
      setValues({...values, WarehouseID: defaultWarehouseID});
    }
  }, [warehouses]);

  return (
    <div className="flex flex-col">
      <p className="mt-16 text-center text-xs	text-spenda-primarytext">
        Please enter the Supplier reference to start this delivery.
      </p>
      <div className="mx-auto my-12 flex w-[257px] flex-col gap-y-4">
        <STextField
          v2
          fullWidth
          autoFocus
          id="RefNumber"
          name="RefNumber"
          variant="outlined"
          label="consignment Note Ref"
          placeholder="consignment Note Ref"
          value={values.RefNumber}
          onBlur={handleBlur}
          onChange={handleChange}
          helperText={(touched?.RefNumber && errors?.RefNumber) || props.status}
          error={touched?.RefNumber && Boolean(errors?.RefNumber)}
          disabled={isReadOnly}
        />
        <SDatePicker
          v2
          autoOk
          variant="inline"
          id="DeliveryDate"
          name="DeliveryDate"
          inputVariant="outlined"
          label="Delivery date"
          placeholder="dd MMM, yyyy"
          format="dd MMM, yyyy"
          value={values.DeliveryDate}
          onChange={date => setFieldValue('DeliveryDate', date)}
          helperText={touched?.DeliveryDate && errors?.DeliveryDate}
          error={touched?.DeliveryDate && Boolean(errors?.DeliveryDate)}
          disabled={isReadOnly}
        />
        <STextField
          v2
          fullWidth
          select
          id="WarehouseID"
          name="WarehouseID"
          variant="outlined"
          label="Warehouse"
          placeholder="Warehouse"
          value={props.values.WarehouseID || ''}
          onBlur={props.handleBlur}
          onChange={props.handleChange}
          helperText={props.touched?.WarehouseID as boolean}
          error={props.touched?.WarehouseID as boolean}
          disabled={isLoading || isReadOnly}
        >
          {warehouses.map(warehouse => (
            <StyledMenuItem key={warehouse.ID} value={warehouse.ID}>
              {warehouse.Name}
            </StyledMenuItem>
          ))}
        </STextField>
      </div>
    </div>
  );
};
