import React from 'react';
import {Typography} from 'spenda-ui-react';
import {AlertDialogSlideV2} from './AlertDialogSlideV2';
import {IQuotesPaymentSummary} from '../../model/quotes/quotes';
import {useQuoteContext} from '../../context/quote-context/QuoteContext';
import moment from 'moment';
import {PriceFormat} from '../../utils/formatter';

interface IPaymentSummaryProps {
  handleClose: () => void;
  selectedPaymentSummary: IQuotesPaymentSummary;
}

const PaymentSummary = ({handleClose, selectedPaymentSummary}: IPaymentSummaryProps) => {
  const {quotePackage, approvedQuote} = useQuoteContext();
  return (
    <AlertDialogSlideV2
      title={'Payment summary'}
      size="sm"
      headingTextSize="h1"
      footerClass="flex justify-center"
      dialogClassess="md:!min-w-[620px]"
      dialogBodyClassess="flex max-h-[80vh] flex-col gap-1 overflow-y-auto px-10 pt-6"
      data-autoid={'dlgPaymentSummary'}
      actions={[
        {
          label: 'Close',
          action: handleClose,
        },
      ]}
    >
      <div className="mb-2 flex justify-between px-2">
        <div className="flex flex-col gap-2">
          <Typography variant="h3" className="text-justify font-medium text-spenda-primarytext">
            Payment Ref - [{selectedPaymentSummary.refNumber}]
          </Typography>
          <Typography variant="h3" className=" text-justify font-medium text-spenda-primarytext">
            Quote Ref - [{quotePackage?.refNumber}]
          </Typography>
        </div>
        <div className="rounded-xl border-4 border-[#e5f2ed] px-14 py-3 text-lg font-medium text-[#3C9F78] ">PAID</div>
      </div>
      <div className="mb-2 flex flex-col gap-2 px-2">
        <Typography className="text-black">By payment method: {selectedPaymentSummary?.paymentMethodName}</Typography>
        <Typography className="text-black">
          Paid on: {moment.utc(selectedPaymentSummary.appliedDate).local().format('DD MMM YYYY')}
        </Typography>
      </div>
      <div className="mb-3 flex w-[240px] flex-col gap-2 px-2">
        <div className="flex justify-between gap-2">
          <Typography className="text-black">Set Deposit:</Typography>
          <Typography className="font-semibold text-spenda-primarytext">
            {PriceFormat(approvedQuote?.requiredDepositAmount)}
          </Typography>
        </div>
        <div className="flex justify-between gap-2">
          <Typography className="text-black">Deposit Paid:</Typography>
          <Typography className="font-semibold text-spenda-primarytext">
            {PriceFormat(selectedPaymentSummary?.appliedAmount)}
          </Typography>
        </div>
        <div className="flex justify-between gap-2">
          <Typography className="text-black">Balance:</Typography>
          <Typography className="font-semibold text-spenda-primarytext">
            {PriceFormat(approvedQuote?.balance)}
          </Typography>
        </div>
      </div>

      {false && (
        <div className="rounded-lg bg-[#F6F6F6] p-2">
          <Typography variant="h3" className="mb-2 font-semibold text-spenda-primarytext">
            Payment applied on:
          </Typography>
          <Typography className="mb-2 text-black">IV-000014 - total $3,192.64</Typography>
          <div className="flex justify-between">
            <Typography className="text-black">Applied amount: $3,192.64</Typography>
            <Typography className="text-black">Remaining balance: $0.00</Typography>
          </div>
        </div>
      )}
    </AlertDialogSlideV2>
  );
};

export default PaymentSummary;
