import useHttp from '../hooks/useHttp';
import {IResponseWrapper} from '../model/ActionResults';

export interface IPrefixSuffix {
  affixes: IAffixes[];
  roundingThreshold: number;
  roundingAccountCode: string;
  roundingRevenueAccountCode: string;
}

export interface IAffixes {
  prefix: string | null;
  suffix: string | null;
  datTypeID: number;
}

export const useAPConfigs = () => {
  const {GET, POST, isLoading} = useHttp();

  const getPrefixSuffix = async (): Promise<IPrefixSuffix> => {
    return GET(`Spenda/Payments/AccountsPayable/Config/MatchTransaction`).then(
      (res: IResponseWrapper<IPrefixSuffix>) => res.value,
    );
  };

  const savePrefixSuffix = (payload: Partial<IPrefixSuffix>): Promise<IPrefixSuffix> => {
    return POST('Spenda/Payments/AccountsPayable/Config/MatchTransaction', payload).then(
      (data: IResponseWrapper<IPrefixSuffix>) => data.value,
    );
  };

  return {
    getPrefixSuffix,
    savePrefixSuffix,
    isLoading,
  };
};
