import React, {useContext, useEffect, useState} from 'react';
import {Dialog, DialogBody} from 'spenda-ui-react';
import {DatTypes} from '../../../model/constants/Constants';
import {
  IDebitNoteSummmary,
  IDebitNoteSummmaryValue,
  IPurchaseInvoiceSummmary,
  IPurchaseInvoiceSummmaryValue,
  IStagedTransactions,
} from '../../../model/requires-attention/StagedTransactions';
import {useSupplierTransactionsAPI} from '../../../services/useSupplierTransactionsAPI';
import {InvoiceFromCapricornTemplate} from '../templates/InvoiceFromCapricornTemplate';
import {InvoiceFromFinancialAdaptorDialog} from './InvoiceFromFinancialAdaptorDialog';
import {IntegrationContext} from '../../../context/IntegrationContext';

interface IMatchAndLinkRequireAttentionDialogProps {
  isShow: boolean;
  handleClose: () => void;
  handleDone: () => void;
  selectedStagedTransaction: IStagedTransactions;
  PSBLSTAGING?: boolean;
}

const MatchAndLinkRequireAttentionDialog = (props: IMatchAndLinkRequireAttentionDialogProps) => {
  // Props
  const {isShow, handleClose, selectedStagedTransaction, handleDone, PSBLSTAGING} = props;

  // States
  const [financialAdaptorTxs, setFiancialAdaptorTxs] = useState<
    (IPurchaseInvoiceSummmaryValue | IDebitNoteSummmaryValue)[]
  >([]);
  const [totalCapricornAmount, setTotalCapricornAmount] = useState<number | undefined>(undefined);
  const [isLoading, setIsLoading] = useState({dataLoading: false, postAsNewLoading: false, matchAndLinkLoading: false});

  // Context
  const {financialAdaptorDisplayName} = useContext(IntegrationContext);

  // APIs
  const {getPurchaseInvoiceSummary, getDebitNoteSummary} = useSupplierTransactionsAPI();

  // Constant
  const isMatchedDocument = Boolean(selectedStagedTransaction?.matchedDocumentID);

  useEffect(() => {
    getInvoices(selectedStagedTransaction);
  }, [selectedStagedTransaction]);

  const setLoadingState = (key: string, value: boolean) => {
    setIsLoading(prevState => ({...prevState, [key]: value}));
  };

  const getInvoices = async (tx?: IStagedTransactions) => {
    try {
      setLoadingState('dataLoading', true);
      if (tx?.datTypeID === DatTypes.StagedPurchaseInvoice) {
        const response: IPurchaseInvoiceSummmary = await getPurchaseInvoiceSummary({
          supplierID: tx?.stagedPurchaseInvoice?.supplierID,
          stagingDocumentID: tx?.documentID,
        });
        setFiancialAdaptorTxs(response.value);
      } else {
        const response: IDebitNoteSummmary = await getDebitNoteSummary({
          supplierID: tx?.stagedDebitNote?.supplierID,
          stagingDocumentID: tx?.documentID,
        });
        setFiancialAdaptorTxs(response.value);
      }
      setLoadingState('dataLoading', false);
    } catch (error) {
      console.error('Error fetching invoices:', error);
    }
  };

  const invFromCapricorn = (
    <InvoiceFromCapricornTemplate
      selectedStagedTransaction={selectedStagedTransaction}
      setTotalCapricornAmount={setTotalCapricornAmount}
      financialAdaptorDisplayName={financialAdaptorDisplayName}
      onClickCancleBtn={handleClose}
      isMatchedDocument={isMatchedDocument}
      PSBLSTAGING={PSBLSTAGING}
      isLoading={isLoading}
      handleDone={handleDone}
    />
  );

  const InvFromFinancialAdaptor = (
    <InvoiceFromFinancialAdaptorDialog
      isMatchedDocument={isMatchedDocument}
      selectedStagedTransaction={selectedStagedTransaction}
      financialAdaptorTxs={financialAdaptorTxs}
      totalCapricornAmount={totalCapricornAmount}
      financialAdaptorDisplayName={financialAdaptorDisplayName}
      isLoading={isLoading}
      handleDone={handleDone}
      setLoadingState={setLoadingState}
      PSBLSTAGING={PSBLSTAGING}
    />
  );

  return (
    <Dialog
      size="xxl"
      open={isShow}
      handler={() => handleClose()}
      className={`flex flex-col items-center justify-center bg-[#ADADAD33] backdrop-blur-[13px]`}
    >
      <DialogBody className="flex h-full flex-col items-center justify-center !px-0 !py-2.5">
        <div className="mx-10 flex h-[calc(100vh-15%)] flex-row items-center gap-x-[33px]">
          {invFromCapricorn} {InvFromFinancialAdaptor}
        </div>
      </DialogBody>
    </Dialog>
  );
};

export default MatchAndLinkRequireAttentionDialog;
