import React, {useState} from 'react';
import {Radio} from 'spenda-ui-react';
import PSBLOnboardingFooter, {ResponsiveBookOnboardingButton} from '../buttons/PSBLOnboardingFooter';
import {useStagedTransactionsAPI} from '../../services/useStagedTransactionsAPI';
import moment from 'moment';
import {usePSBLHook} from '../../hooks/useARHook';
import {defaultMarketPlacePath} from '../../config';
// import {SelectRoundingToleranceDropdown} from '../AccountsReceivable/required-attention/SelectRoundingToleranceDropwdown';
// import {useAPConfigs} from '../../services/useAPConfigs';

enum ReconsileOption {
  PreviousMonth = 'Previous Month',
  CurrentMonth = 'Current Month',
}
interface IReconsilingPeriodProps {
  handleNext: () => void;
}

export const ReconcilingPeriod = (props: IReconsilingPeriodProps) => {
  // Props
  const {handleNext} = props;

  // State
  const [selectedOpt, setSelectedOpt] = useState<ReconsileOption>(ReconsileOption.PreviousMonth);
  // const [roundingTolerance, setRoundingTolerance] = useState<string>();

  // Hook
  const {getSupplierByMarketplacePath} = usePSBLHook();

  // APIs
  const {setAPSetting, isLoading} = useStagedTransactionsAPI();
  // const {savePrefixSuffix} = useAPConfigs();

  // Constants
  const previousMonthStartDate = moment.utc().subtract(1, 'months').startOf('month').format('DD/MM/YYYY');
  const currentMonthStartDate = moment.utc().startOf('month').format('DD/MM/YYYY');

  const handleReconsilingPeriod = async () => {
    try {
      const onboardingStatementStartDate =
        selectedOpt === ReconsileOption.PreviousMonth ? previousMonthStartDate : currentMonthStartDate;
      const supplierId = getSupplierByMarketplacePath(defaultMarketPlacePath)?.SupplierID;
      if (supplierId) {
        await setAPSetting(Number(supplierId), {onboardingStatementStartDate});
        // await savePrefixSuffix({roundingThreshold: Number(roundingTolerance)});
        handleNext();
      }
    } catch {}
  };

  return (
    <div className="wrapper relative w-full overflow-hidden bg-white pb-24">
      <div className={` mx-auto flex h-full w-full flex-col justify-between overflow-y-auto sm:!max-w-[660px]`}>
        <div className={` mt-4 w-full text-left align-middle sm:w-[660px]`}>
          <p className={`text-left font-poppins text-[20px] font-medium text-[#333] sm:text-2xl sm:font-light`}>
            When do you want to start reconciling?
          </p>
          <p className={`mt-6 text-left text-sm font-normal text-[#999999] sm:mt-2`}>
            You should start reconciling at the beginning of a statement period where you haven’t marked any invoices as
            paid by your trade account. Set the start date you want below, and we’ll only import invoices from that
            date.
          </p>
          <div className="flex w-full flex-col gap-y-8 pt-20 sm:gap-y-0 sm:pt-5">
            <Radio
              color="primary"
              name="previousChk"
              label={`Previous (We'll start importing your statement from the beginning of ${previousMonthStartDate}).`}
              checked={selectedOpt === ReconsileOption.PreviousMonth}
              onChange={() => setSelectedOpt(ReconsileOption.PreviousMonth)}
              labelProps={{className: 'text-base font-normal text-black-800'}}
            />
            <Radio
              color="primary"
              name="currentChk"
              label={`Current (We'll start importing your statement from the beginning of ${currentMonthStartDate}).`}
              checked={selectedOpt === ReconsileOption.CurrentMonth}
              onChange={() => setSelectedOpt(ReconsileOption.CurrentMonth)}
              labelProps={{className: 'text-base font-normal text-black-800'}}
            />
          </div>

          {/* <p className="mt-12 font-poppins font-medium text-[#000000]">Rounding Tolerance</p>
          <p className="flex-start my-5 flex text-sm font-normal text-[#333333]">
            How would you like us to post tax rounding adjustments?
          </p>
          <div className="w-full sm:max-w-[343px]">
            <SelectRoundingToleranceDropdown
              value={roundingTolerance}
              onChange={value => value && setRoundingTolerance(value)}
            />
          </div> */}
        </div>
        <ResponsiveBookOnboardingButton />
      </div>
      <PSBLOnboardingFooter isChatWidget onPrimaryClick={handleReconsilingPeriod} isLoading={isLoading} />
    </div>
  );
};
