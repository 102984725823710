import {css} from 'glamor';
import React, {PropsWithChildren} from 'react';
import RebrandedSpendaFooterLogo from '../../assets/svg/RebrandedSpendaFooterLogo';
interface IPayByLinkLayoutProps {
  isScrollable?: boolean;
  footerLogo?: string;
}

export const PayByLinkLayout = (props: PropsWithChildren<IPayByLinkLayoutProps>) => {
  const layoutHeight = css({
    height: 'calc(100vh - calc(100vh - 100%))',
  });

  const contentHeight = css({
    height: 'calc(100% - 48px)',
    overflowY: 'hidden',
    WebkitOverflowScrolling: 'touch',
    position: 'relative',
  });

  return (
    <section className={` flex flex-col ${layoutHeight}`} style={{backgroundColor: '#F4F8FB'}}>
      <main className={`${contentHeight} flex items-center justify-center`}>{props.children}</main>

      <footer>
        <div
          style={{zIndex: 50}}
          className={'flex h-12 flex-1 items-center justify-center bg-spenda-footerBg p-1 text-xl text-white'}
        >
          {props.footerLogo ? (
            <img src={props.footerLogo} alt="Tenant Logo" className={`h-[32px]`} />
          ) : (
            <RebrandedSpendaFooterLogo style={{width: '150', height: '40px'}} />
          )}
        </div>
      </footer>
    </section>
  );
};
