import React from 'react';
import {css} from 'glamor';

interface ProgressBarProps {
  bgcolor?: string;
  completed?: number;
  isWithoutText?: boolean;
}

const ProgressBar: React.FC<ProgressBarProps> = props => {
  const {bgcolor, completed, isWithoutText} = props;

  const containerStyles = css({
    background: `linear-gradient(to right, ${bgcolor} ${completed}%, #eee 0)`,
  });

  const fillerStyles = css({
    background: `linear-gradient(to right, white ${completed}%, #333 0)`,
    webkitBackgroundClip: 'text',
    webkitTextFillColor: 'transparent',
  });

  return (
    <div
      className={`${containerStyles} !relative flex ${isWithoutText ? '!h-[6px] !rounded-[10px]' : '!h-[24px] !rounded-[50px]'} !w-full items-center justify-center bg-[#EEE] !bg-blend-difference`}
    >
      <div
        className={`${fillerStyles} !h-full !w-full !bg-clip-text bg-[${bgcolor}] !absolute !top-0 !z-[999] !rounded-[inherit] text-center !text-base !font-bold !leading-6 text-transparent`}
      >
        {isWithoutText ? '' : `${completed}% Finalising Setup`}
      </div>
    </div>
  );
};

export default ProgressBar;
