import React, {useEffect, useMemo, useState} from 'react';
import {Layout} from '../../components/layout/Layout';
import {ARSearchBar, IARSearchBarSearch} from '../../components/AccountsReceivable/ARSearchBar';
import {ARTable} from '../../components/AccountsReceivable/ARTable';
import {Chip, IconButton, Typography, ChipProps, Button} from 'spenda-ui-react';
import IconFilter from '../../assets/svg/IconFilter';
import AdvanceFilter from '../../components/ECommerce/AdvanceFilter';
import {useTransactionHistoryApi} from '../../../src/services/useTransactionHistoryApi';
import {
  ITransactionHistoryResponse,
  ISupplier,
  IBorrower,
  ITransaction,
} from '../../model/transaction-history/TransactionHistory';
import moment from 'moment';
import {pascalToSentence, PriceFormat} from '../../utils/formatter';
import {useTransactionViewHook} from '../../hooks/useARHook';
import {TransactionView} from '../../components/AccountsReceivable/TransactionView';
import {Link} from 'react-router-dom';

export interface ITransactionHistoryFilters {
  suppliers: ISupplier[];
  borrowers: IBorrower[];
  paymentTypes: IFilterOption[];
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export const initialFilters = {
  suppliers: [],
  borrowers: [],
  paymentTypes: [],
  startDate: null,
  endDate: null,
};

const InitialSearch = {searchString: '', searchCategory: 'Reference', searchPlaceholder: 'Reference'};

interface IFilterOption {
  id: string | number;
  name: string;
}

interface IFilterSection {
  title: string;
  options: IFilterOption[];
  searchPlaceholder: string;
  searchKey: string;
  filterKey: string;
}

export const TransactionHistory = () => {
  // State
  const [isShowFilterDialog, setIsShowFilterDialog] = useState<boolean>(false);
  const [transactionHistoryResponse, setTransactionHistoryResponse] = useState<ITransactionHistoryResponse>();
  const [filters, setFilters] = useState<ITransactionHistoryFilters>(initialFilters);
  const [isRefetchList, setIsRefetchList] = useState(false);
  const [search, setSearch] = useState<IARSearchBarSearch>(InitialSearch);

  const {handleCloseTx, viewingTx} = useTransactionViewHook();

  // Constant
  const searchCategories = [
    {value: 'TransactionID', placeholder: 'Reference', label: 'Reference'},
    {value: 'SupplierName', placeholder: 'by Supplier Name/ID', label: 'Supplier'},
    {value: 'BorrowerName', placeholder: 'by Member Name/ID', label: 'Member'},
    {value: 'Amount', placeholder: 'by Amount', label: 'Amount'},
  ];
  const {suppliers, borrowers, paymentTypes, startDate, endDate} = filters;

  const isFilterApplied = useMemo(
    () =>
      suppliers?.length > 0 ||
      borrowers?.length > 0 ||
      paymentTypes?.length > 0 ||
      startDate != null ||
      endDate != null,
    [suppliers, borrowers, paymentTypes, startDate, endDate],
  );

  // Api
  const {getTransactionHistory, isLoading} = useTransactionHistoryApi();

  useEffect(() => {
    fetchTransactionHistory();
  }, [search]);

  useEffect(() => {
    isRefetchList && fetchTransactionHistory();
  }, [filters?.startDate, filters?.endDate, isRefetchList]);

  const filterRecords = useMemo(() => {
    if (!suppliers?.length && !borrowers?.length && !paymentTypes?.length) return transactionHistoryResponse;

    const historyList = transactionHistoryResponse?.transactions?.filter(h => {
      let isValid = false;
      if (suppliers?.length) {
        isValid = suppliers?.some(s => s?.name === h?.supplierName);
      }
      if (borrowers?.length) {
        isValid = borrowers?.some(b => b?.name === h?.borrowerName);
      }
      if (paymentTypes.length) {
        isValid = paymentTypes.some(p => p.name === h.transactionType);
      }
      return isValid;
    });
    return {...transactionHistoryResponse, transactions: historyList};
  }, [transactionHistoryResponse, suppliers, borrowers, paymentTypes]);

  const fetchTransactionHistory = async () => {
    const {startDate, endDate} = filters;
    try {
      const query = {
        searchString: search?.searchString?.trim() || undefined,
        searchField: search?.searchString && search?.searchCategory ? search?.searchCategory : undefined,
        dateStart: startDate ? startDate.format('YYYY-MM-DD') : undefined,
        dateEnd: endDate ? endDate.format('YYYY-MM-DD') : undefined,
      };
      const response = await getTransactionHistory(query);
      setTransactionHistoryResponse(response?.value);
    } finally {
      setIsRefetchList(false);
    }
  };

  const handleSaveFilters = (f: ITransactionHistoryFilters, isRefetch: boolean) => {
    setIsShowFilterDialog(false);
    setFilters(f);
    if (isRefetch) {
      setIsRefetchList(true);
    }
  };

  const handleClearAllFilter = (isRefetch: boolean) => {
    setIsShowFilterDialog(false);
    setFilters(initialFilters);
    if (isRefetch) {
      setIsRefetchList(true);
    }
  };

  const paymentTypesList: IFilterOption[] = useMemo(() => {
    const list = transactionHistoryResponse?.transactions?.reduce((a: ITransaction[], c) => {
      if (!a.some(transaction => transaction.transactionType === c?.transactionType)) {
        a.push(c);
      }
      return a;
    }, []);
    return list?.map(l => ({id: l.transactionType, name: l.transactionType})) || [];
  }, [transactionHistoryResponse?.transactions]);

  const filterSections: IFilterSection[] = [
    {
      title: 'Suppliers',
      options: transactionHistoryResponse?.suppliers || [],
      searchPlaceholder: 'Search suppliers',
      searchKey: 'supplierSearch',
      filterKey: 'suppliers',
    },
    {
      title: 'Members',
      options: transactionHistoryResponse?.borrowers || [],
      searchPlaceholder: 'Search members',
      searchKey: 'borrowerSearch',
      filterKey: 'borrowers',
    },
    {
      title: 'Types',
      options: paymentTypesList,
      searchPlaceholder: 'Search types',
      searchKey: 'paymentTypeSearch',
      filterKey: 'paymentTypes',
    },
  ];

  const handleSearchChange = (searchData: IARSearchBarSearch) => {
    setSearch(searchData);
  };

  const columns = [
    {
      title: 'Date',
      key: 'transactionDatetime',
      width: '10%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      rowRenderer: (rowData: ITransaction) => (
        <Typography
          data-autoid={`lblTransactionDatetime`}
          variant="paragraph"
          className="inline overflow-ellipsis font-medium"
        >
          {moment(rowData?.transactionDatetime).format('DD/MM/YYYY')}
        </Typography>
      ),
    },
    {
      title: 'Supplier',
      key: 'supplierName',
      width: '15%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: ITransaction) => (
        <div>
          <Typography
            data-autoid={`lblSupplierName`}
            variant="paragraph"
            className="inline overflow-ellipsis font-medium"
          >
            {rowData?.supplierName}
          </Typography>
          {rowData?.supplierID && (
            <Typography variant="xsmall" className="text-xs text-spenda-labeltext">
              {`(${rowData?.supplierID})`}
            </Typography>
          )}
        </div>
      ),
    },
    {
      title: 'Supplier Invoice ID',
      key: 'supplierInvoiceID',
      width: '12%',
      align: 'left',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: ITransaction) => {
        const path = `?txDatType=${rowData.transactionDatTypeID}&mcId=${rowData.merchantCustomerID}&txId=${rowData.supplierInvoiceID}`;

        return (
          <Link
            to={{
              pathname: location.pathname,
              search: path,
            }}
          >
            <Typography
              data-autoid={`lblSupplierInvoiceID-${rowData?.supplierInvoiceID}`}
              variant="paragraph"
              className="inline cursor-pointer overflow-ellipsis font-medium text-primary"
            >
              {rowData?.supplierInvoiceID}
            </Typography>
          </Link>
        );
      },
    },
    {
      title: 'Member',
      key: 'borrowerName',
      width: '15%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: ITransaction) => (
        <div className={`ml-auto text-left font-poppins text-black-800`}>
          <Typography
            data-autoid={`lblMemberName`}
            variant="paragraph"
            className="inline overflow-ellipsis font-medium"
          >
            {rowData?.borrowerName}
          </Typography>
          {rowData?.memberID && (
            <Typography variant="xsmall" className="text-xs text-spenda-labeltext">
              {`(${rowData?.memberID})`}
            </Typography>
          )}
        </div>
      ),
    },
    {
      title: 'Reference',
      key: 'transactionID',
      width: '13%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: ITransaction) => (
        <Typography
          data-autoid={`lblTransactionID`}
          variant="paragraph"
          className="inline overflow-ellipsis font-medium"
        >
          {rowData?.transactionID}
        </Typography>
      ),
    },
    {
      title: 'Type',
      key: 'transactionType',
      width: '13%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: ITransaction) => (
        <Typography
          data-autoid={`lblTransactionType`}
          variant="paragraph"
          className="inline overflow-ellipsis font-medium"
        >
          {pascalToSentence(rowData?.transactionType || '')}
        </Typography>
      ),
    },
    {
      title: 'Source',
      key: 'source',
      width: '10%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: ITransaction) => (
        <Typography data-autoid={`lblSource`} variant="paragraph" className="inline overflow-ellipsis font-medium">
          {rowData?.source}
        </Typography>
      ),
    },
    {
      title: 'Amount',
      key: 'amount',
      width: '12%',
      align: 'right',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: true,
      rowRenderer: (rowData: ITransaction) => (
        <Typography data-autoid={`lblAmount`} variant="paragraph" className="inline overflow-ellipsis font-medium">
          {PriceFormat(rowData?.amount, undefined, 2)}
        </Typography>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      width: '10%',
      align: 'center',
      columClassName: 'text-spenda-labeltext',
      rowClassName: 'p-2.5',
      isSortable: false,
      rowRenderer: (rowData: ITransaction) => (
        <div data-autoid={`lblStatus`} className="item-center flex flex-row justify-center">
          {transactionHistoryStatus(rowData?.status)}
        </div>
      ),
    },
  ];

  const AppliedFilters = () => {
    return isFilterApplied ? (
      <div className="flex flex-row flex-wrap items-center gap-2">
        {suppliers?.length > 0 && (
          <>
            <Typography>{`Supplier${suppliers.length > 1 ? 's:' : ':'}`}</Typography>
            {suppliers.map((s, index) => (
              <Chip key={index} data-autoid={`lblSupplier-${s?.name}`} size="sm" value={s?.name} />
            ))}
          </>
        )}
        {borrowers?.length > 0 && (
          <>
            <Typography>{`Member${borrowers.length > 1 ? 's:' : ':'}`}</Typography>
            {borrowers.map((b, index) => (
              <Chip key={index} data-autoid={`lblMember-${b?.name}`} size="sm" value={b?.name} />
            ))}
          </>
        )}
        {paymentTypes?.length > 0 && (
          <>
            <Typography>{`Type${paymentTypes.length > 1 ? 's:' : ':'}`}</Typography>
            {paymentTypes.map((p, index) => (
              <Chip key={index} data-autoid={`lblType-${p?.name}`} size="sm" value={p?.name} />
            ))}
          </>
        )}
        {startDate && endDate && (
          <>
            <Typography>Date Range:</Typography>
            <Chip
              data-autoid={`lblDateRange`}
              size="sm"
              value={`From ${startDate.format('DD/MM/YYYY')} to ${endDate.format('DD/MM/YYYY')}`}
            />
          </>
        )}
      </div>
    ) : (
      <Typography>All Transactions</Typography>
    );
  };

  const onClearAllFilter = () => {
    handleClearAllFilter(true);
    setSearch(InitialSearch);
  };

  const leftPannel = (
    <div className={`relative flex h-full flex-col overflow-hidden rounded-md bg-primary-background`}>
      <div className="h-[inherit] w-full">
        <div className={`mt-1 flex items-center justify-between rounded-t-md border-b border-[#D8D8D8] bg-white p-2.5`}>
          <AppliedFilters />
          <div className="flex flex-row items-center justify-end gap-2.5">
            <div>
              <ARSearchBar
                reverse
                type="advance"
                searchCategories={searchCategories}
                dataAutoIdSuffix={'TransactionHistorySearch'}
                searchString={search?.searchString || ''}
                searchCategory={search?.searchCategory || 'TransactionID'}
                searchPlaceholder={search?.searchPlaceholder}
                isSearching={isLoading}
                onSubmit={handleSearchChange}
              />
            </div>
            <IconButton variant="outlined" data-autoid={'btnAdvanceFilter'} onClick={() => setIsShowFilterDialog(true)}>
              <IconFilter />
            </IconButton>
            {isFilterApplied ? (
              <Button variant="outlined" data-autoid={'btnResetAllFilter'} onClick={onClearAllFilter} className="px-2">
                Reset
              </Button>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className={`invoicesList h-[inherit] overflow-auto bg-white pb-20 pt-2.5`}>
          <ARTable
            isPaginated
            dataAutoId={`TransactionHistory`}
            tableClass="mb-12"
            isLoading={isLoading}
            columns={columns}
            rows={filterRecords?.transactions || []}
            getRowDataAutoId={rowData => `rowTransactionHistory${rowData?.transactionID}`}
          />
        </div>
      </div>
      {viewingTx && (
        <TransactionView
          key={viewingTx?.txGuid}
          isShowInPdfView={true}
          transactionId={viewingTx?.txId}
          txDatType={viewingTx?.txDatType}
          txGuid={viewingTx?.txGuid}
          merchantCustomerID={viewingTx?.mcId}
          handleOnClosePdfView={() => handleCloseTx()}
          hideFooter
        />
      )}
      {isShowFilterDialog && (
        <AdvanceFilter
          showModal={isShowFilterDialog}
          setOpen={() => setIsShowFilterDialog(!isShowFilterDialog)}
          onSave={handleSaveFilters}
          onClear={handleClearAllFilter}
          filterSections={filterSections}
          selectedFilters={filters}
        />
      )}
    </div>
  );

  return (
    <div className={`relative h-full overflow-hidden bg-spenda-newbg`}>
      <Layout leftPanel={leftPannel} splitWidthType={4} />
    </div>
  );
};

const transactionHistoryStatus = (status: any) => {
  const statusMap: {[key: string]: {value: string; color?: ChipProps['color']}} = {
    Cancelled: {value: 'Cancelled', color: 'error'},
    Cleared: {value: 'Processed', color: 'success'},
    Failed: {value: 'Failed', color: 'error'},
    Finalised: {value: 'Processed', color: 'success'},
    InProgress: {value: 'In Progress'},
    Paid: {value: 'Processed', color: 'success'},
    Reserved: {value: 'Reserved', color: 'blue'},
  };

  const {value, color} = statusMap[status] || {value: status};

  return <Chip data-autoid={`lbl${status}`} size="sm" value={value} className="w-[100px]" color={color} />;
};
