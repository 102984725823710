import * as React from 'react';
import {SVGProps} from 'react';

const SetUpInventoryStepImage = (props: SVGProps<SVGSVGElement>) => (
  <svg width={143} height={140} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      opacity={0.6}
      d="M20.5 27.5C26.704 16.355 44.926 10.498 60 22c17 6 35.5 3 35.5 3 24.5-4 34.5-1.5 41 4.5 3.674 3.391 11.34 18.927 1 37.5-12.408 22.287 4.5 41.5-28 41.5-9 0-7.917 3.248-13.1 10.873-6.173 9.083-15.433 15.523-30.027 6.785-19.08-11.423-8.592-27.431-24.7-37.076-10.74-6.43-24.709 7.494-36.154.641-10.738-6.43-2.745-16.501 5.527-31.36 7.238-13 3.25-19.72 9.454-30.863Z"
      fill="#D6EAF3"
    />
    <mask
      id="a"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={105}
      y={79}
      width={2}
      height={2}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M105.958 79.152h.37v1.22h-.37v-1.22Z" fill="#fff" />
    </mask>
    <g mask="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.328 80.362a.454.454 0 0 0-.132.01c-.316-.405-.32-.81.006-1.212a.15.15 0 0 1 .126.015v1.187Z"
        fill="#999"
      />
    </g>
    <mask
      id="b"
      style={{
        maskType: 'luminance',
      }}
      maskUnits="userSpaceOnUse"
      x={33}
      y={70}
      width={74}
      height={38}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M33.672 70.887h72.53V108h-72.53V70.887Z" fill="#fff" />
    </mask>
    <g mask="url(#b)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106.196 80.373c-.153 1.317-.735 2.432-1.801 3.194-3.332 2.382-6.662 4.769-10.066 7.042-2.215 1.48-4.767 1.908-7.41 1.863-4.721-.08-9.442-.162-14.164-.2a21.962 21.962 0 0 0-9.455 2.02c-.46.212-.497.346-.224.776.827 1.307.56 2.842-.697 3.743-4.07 2.916-8.155 5.813-12.262 8.679-1.3.908-2.935.568-3.858-.73a6383.554 6383.554 0 0 1-11.983-16.907c-1-1.414-.746-3.018.666-4.028 4.014-2.871 8.042-5.722 12.082-8.557 1.378-.966 2.968-.675 3.955.716.312.44.485.428.892.136 2.264-1.627 4.578-3.184 6.823-4.836 2.273-1.672 4.743-2.087 7.49-1.616 4.031.691 8.084 1.255 12.127 1.877 1.169.18 2.347.32 3.5.57 3.667.794 5.282 4.633 3.324 7.822-.084.137-.254.249-.203.486.186.009.394-.03.566.034 1.246.461 1.816-.26 2.437-1.196 1.801-2.712 3.703-5.357 5.546-8.041 1.129-1.642 2.658-2.537 4.65-2.294 2.152.263 3.505 1.562 4.063 3.66.099.374.295.513.674.64 1.883.636 3.057 1.916 3.334 3.934l-.006 1.213Z"
        fill="#333"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.387 32.004c-.613-.008-31.754 0-31.754 0-1.843.74-2.5 2.119-2.425 4.07.099 2.613.024 5.234.024 7.852 0 7.063.028 14.127-.017 21.19-.014 2.063 1.444 3.481 3.221 3.494 4.1.031 8.202.013 12.303.01.698 0 1.182-.298 1.316-1.03-.004-.567-.246-.977-.782-1.196-4.029-.014-8.057-.027-12.086-.043a6.41 6.41 0 0 1-.887-.046c-.508-.073-.778-.392-.816-.9-.013-.172-.005-.346-.005-.519V35.674c0-1.165.276-1.447 1.428-1.444 1.705.004 3.41.017 5.115.025 1.185-.009 2.37.009 3.554-.038.525-.021.652.141.649.655-.022 3.727-.02 7.455-.008 11.183.004 1.424.886 2.27 2.147 2.114.433-.053.764-.317 1.123-.53.696-.413 1.328-1.233 2.064-1.189.689.042 1.337.754 2.002 1.172.167.105.326.224.498.318 1.227.667 2.701-.092 2.77-1.492.07-1.428.03-2.863.014-4.294-.007-.58-.328-.963-.89-1.13-1.01.032-1.331.341-1.342 1.322-.01.862.005 1.724-.008 2.585-.003.179.12.439-.11.52-.112.04-.3-.114-.445-.196-.744-.421-1.366-1.058-2.234-1.257-.81-.102-1.406.35-2.02.766-.38.256-.775.492-1.154.731-.177-.2-.125-.38-.125-.543-.004-3.356.007-6.712-.013-10.068-.004-.51.127-.698.656-.665.786.048 1.577.029 2.367.038.86-.01 1.722-.004 2.583-.035.381-.013.525.107.508.5-.028.639-.013 1.28-.006 1.92.01.979.22 1.226 1.202 1.442.797-.222 1.087-.766 1.045-1.563-.031-.591.01-1.186-.012-1.778-.013-.355.047-.526.47-.524 3.066.018 6.131.003 9.196.015.707.003 1.026.345 1.067 1.045.006.099.003.198.003.297v29.432c-.001 1.078-.27 1.345-1.372 1.347-3.905.005-7.811-.001-11.717.004-1.04.002-1.324.245-1.508 1.225.195.773.72 1.045 1.482 1.043 3.832-.01 7.664-.005 11.496-.005 2.668 0 3.91-1.23 3.91-3.876 0-9.565-.023-19.13.02-28.694.009-1.942-.816-3.976-2.497-4.048Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M72 66.344c-1.355-.015-1.583-.307-1.233-1.646a.785.785 0 0 1 .045-.05c-.666-.072-1.341.054-2.006-.146-1.271-.382-2.215-1.498-2.229-2.821-.027-2.643-.033-5.286.002-7.928.021-1.607 1.345-2.896 2.956-2.914l1.539-.012c-.263-.104-.407-.33-.428-.692a10.075 10.075 0 0 1-.018-.591c-.001-4.614-.004-9.228.003-13.842.001-.51-.057-1.051.391-1.447-1.705-.009-3.41-.021-5.115-.026-1.152-.002-1.427.28-1.428 1.444v29.212c0 .173-.008.347.005.52.038.508.308.827.816.9.292.042.59.044.887.045 4.029.017 8.057.03 12.086.043-2.09-.118-4.182-.027-6.274-.05Z"
      fill="#C68A19"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M83.484 66.359c3.906-.005 7.812.001 11.717-.004 1.102-.002 1.37-.27 1.37-1.348.003-9.81.002-19.62.002-29.431 0-.1.003-.198-.003-.297-.041-.7-.36-1.042-1.067-1.045-3.065-.012-6.13.003-9.195-.015-.425-.002-.484.17-.471.523.022.593-.02 1.188.012 1.78.042.796-.249 1.34-1.045 1.562.071.978-.157 1.971.14 2.94.562.167.883.55.89 1.13.017 1.431.058 2.866-.013 4.294-.069 1.4-1.543 2.158-2.77 1.492-.173-.094-.331-.213-.498-.318-.665-.418-1.314-1.13-2.002-1.172-.736-.044-1.368.776-2.065 1.189-.358.213-.689.476-1.122.53-1.262.157-2.143-.69-2.147-2.115-.012-3.727-.014-7.455.008-11.182.003-.514-.125-.676-.65-.655-1.183.047-2.368.03-3.553.038-.449.396-.39.936-.391 1.448-.007 4.613-.004 9.227-.004 13.84 0 .198.007.395.019.592.021.363.165.588.428.692 2.055-.012 4.111-.007 6.167-.012 1.858.17 2.973 1.357 2.98 3.198.01 2.47.01 4.94 0 7.409-.008 1.909-1.295 3.191-3.206 3.205-2.05.015-4.1.019-6.15.027l-.053-.005a.73.73 0 0 0-.045.049c-.35 1.34-.123 1.632 1.232 1.646 2.091.022 4.184-.069 6.274.05.536.218.778.629.781 1.195l2.922-.005c.184-.98.469-1.224 1.508-1.225Z"
      fill="#DDB975"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M84.804 38.084c-.982-.216-1.192-.463-1.202-1.442-.007-.64-.022-1.28.006-1.92.017-.393-.127-.513-.508-.5-.86.03-1.722.026-2.584.035l-.003 8.889c0 .319-.05.652.292.852.87.2 1.491.836 2.234 1.258.145.082.334.235.445.195.23-.081.108-.34.11-.52.014-.86-.001-1.723.009-2.584.011-.981.331-1.29 1.341-1.323-.297-.968-.069-1.961-.14-2.94Z"
      fill="#7FC0A6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m80.513 43.145.003-8.888c-.789-.01-1.58.01-2.367-.039-.528-.032-.659.156-.656.666.022 3.356.01 6.712.014 10.068 0 .163-.053.342.125.543.379-.24.773-.475 1.154-.732.614-.414 1.21-.867 2.02-.765-.343-.2-.293-.533-.293-.853Z"
      fill="#3C9F78"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M66.579 53.753a337.396 337.396 0 0 0-.002 7.928c.014 1.324.957 2.44 2.23 2.821.663.2 1.339.074 2.005.147.018.002.036.002.054.005 2.05-.009 4.1-.012 6.149-.027 1.91-.014 3.198-1.296 3.206-3.205.01-2.47.01-4.94 0-7.41-.007-1.84-1.122-3.027-2.98-3.197-2.056.005-4.112 0-6.167.012l-1.539.012c-1.612.019-2.935 1.307-2.956 2.914Z"
      fill="#333"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.976 85.217c1.662 2.315 3.33 4.625 4.983 6.947 1.769 2.483 3.509 4.987 5.293 7.46.324.447.276.637-.168.934-1.511 1.009-2.991 2.064-4.483 3.101-.41.037-.523-.322-.693-.56-2.998-4.205-5.971-8.427-8.964-12.635-.58-.816-.637-1.74.018-2.388 1.175-1.163 2.546-2.088 4.014-2.86Z"
      fill="#BBDBED"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M45.976 85.217c-1.11.809-2.219 1.619-3.33 2.426-1.113.81-1.225 1.766-.416 2.899 2.34 3.276 4.65 6.574 6.98 9.856.781 1.099 1.508 2.237 2.39 3.261-.872.636-1.743 1.273-2.619 1.905-.566.408-.85-.014-1.128-.405-1.553-2.189-3.104-4.38-4.655-6.57l-6.834-9.643c-.596-.842-.576-.985.264-1.582 2.248-1.599 4.504-3.186 6.745-4.794.394-.283.617-.324.906.152.523.858 1.127 1.666 1.697 2.495Z"
      fill="#1EA0E4"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="m67.506 74.212.277.05a.903.903 0 0 0-.277-.05Z" fill="#F3DFDA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M102.495 77.473c-.951-.407-1.818-.227-2.647.358-2.96 2.094-5.948 4.15-8.885 6.276-1.033.747-2.099.965-3.338.832-2.626-.28-5.26-.493-7.891-.726-1.698-.15-3.397-.281-5.094-.437-.67-.062-1.124-.58-1.077-1.173.048-.612.54-1.038 1.22-1.046.173-.003.346.013.518.028 1.5.13 3 .278 4.503.385.61.043 1.23.134 1.838-.06 1.314-.417 2.192-1.68 2.065-2.982-.144-1.487-1.177-2.486-2.758-2.718-2.195-.322-4.385-.677-6.58-1.002-2.194-.326-4.39-.632-6.586-.947-.9.084-1.457.785-2.135 1.24-1.914 1.286-3.775 2.651-5.654 3.99-1.419 1.011-1.625 2.07-.63 3.506 1.775 2.563 3.581 5.104 5.36 7.665.173.25.34.407.647.382 3.53-1.038 7.144-1.094 10.783-1 3.755.096 7.513.084 11.269.148 2.48.041 4.704-.666 6.71-2.114 1.18-.852 2.38-1.675 3.571-2.512 1.715-1.206 3.444-2.394 5.139-3.627 1.697-1.234 1.493-3.677-.348-4.466Z"
      fill="#DDB975"
    />
    <path fillRule="evenodd" clipRule="evenodd" d="m65.37 91.044-.089.035a.865.865 0 0 0 .09-.035Z" fill="#F3DFDA" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M59.529 82.998c-.994-1.436-.788-2.495.63-3.506 1.88-1.339 3.74-2.705 5.655-3.99.677-.455 1.234-1.157 2.135-1.24-.093-.018-.185-.034-.278-.05-.63-.115-1.262-.226-1.892-.348-1.731-.335-3.41-.225-4.898.794-2.584 1.768-5.13 3.59-7.697 5.382-.274.19-.349.34-.134.642 2.773 3.896 5.536 7.8 8.3 11.702.133.19.23.34.524.183 1.14-.611 2.372-1.015 3.573-1.488l.089-.034c-.306.024-.474-.133-.647-.383-1.778-2.56-3.585-5.102-5.36-7.664Z"
      fill="#C68A19"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m50.948 81.849 7.933 11.154c.686.965 1.374 1.93 2.057 2.896.488.691.456.868-.247 1.37-.622.445-1.254.876-1.87 1.328-.246.18-.394.279-.632-.061-3.385-4.824-6.786-9.636-10.184-14.45-.14-.209-.051-.387.11-.51a39.167 39.167 0 0 1 2.43-1.733c.124-.08.27-.117.403.006Z"
      fill="#7FC0A6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m96.247 77.584-5.652 4.04-.106-.136c1.691-2.437 3.365-4.886 5.081-7.306.836-1.179 2.296-1.359 3.507-.511-.956 1.295-1.776 2.688-2.83 3.913Z"
      fill="#C68A19"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M50.948 81.848c-1.029.502-1.856 1.3-2.809 1.913-.08.053-.091.214-.134.324-.677-.93-1.347-1.863-2.032-2.786-.197-.265-.22-.426.1-.633.72-.468 1.408-.983 2.11-1.479.413-.292.749-.264 1.05.187.556.835 1.142 1.65 1.715 2.474Z"
      fill="#3C9F78"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M96.247 77.583c.713-1.363 1.681-2.555 2.57-3.798.048-.066.172-.078.26-.115.294.154.457.424.627.693.333.527.384.823-.336 1.183-1.104.552-2.087 1.346-3.12 2.037Z"
      fill="#DDB975"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M73.394 61.328c-.077-.583.322-.516.7-.543.65-.045 1.318.162 1.952-.123-1.553.011-3.106.031-4.66.029-.955-.001-1.418-.385-1.413-1.122.004-.729.489-1.112 1.426-1.129l1.993-.037c.003-1.328.003-1.317 1.368-1.335.379-.005.777.122 1.138-.105-1.528-.003-3.057 0-4.585-.01-.887-.006-1.346-.394-1.341-1.11.005-.7.49-1.108 1.353-1.128.69-.016 1.38-.022 2.07-.033a3.499 3.499 0 0 1 .284-1.595c-1.236-.009-2.47-.022-3.706-.024-.916 0-1.17.258-1.171 1.196a2171.14 2171.14 0 0 0 0 6.896c.001.966.265 1.233 1.224 1.237 2.25.007 4.499.01 6.749.007.213 0 .447.076.636-.102-1.104-.01-2.207-.044-3.31-.023-.726.014-.648-.5-.707-.946Z"
      fill="#C68A19"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M77.983 53.962c0-.6-.322-.9-.92-.899-1.13.005-2.26.016-3.392.024a3.499 3.499 0 0 0-.284 1.596c.714.01 1.428.018 2.142.034.653.015 1.177.241 1.28.96.101.695-.24 1.122-.918 1.287-.36.226-.76.1-1.138.105-1.365.017-1.365.007-1.368 1.334l2.14.04c.587.012 1.097.187 1.258.812.172.665-.09 1.153-.745 1.408-.634.284-1.3.077-1.951.122-.379.027-.777-.04-.7.544.058.445-.02.959.707.945 1.103-.021 2.206.013 3.31.023.442-.092.58-.404.58-.81.001-2.509.003-5.017 0-7.525Z"
      fill="#C68A19"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M69.972 55.844c-.005.715.454 1.104 1.341 1.11 1.528.008 3.057.007 4.585.01.678-.165 1.02-.592.92-1.287-.105-.719-.629-.946-1.282-.96-.714-.016-1.428-.023-2.142-.034-.69.01-1.38.017-2.069.032-.864.02-1.348.428-1.353 1.129ZM69.972 59.57c-.004.737.459 1.12 1.415 1.122 1.552.002 3.106-.018 4.659-.029.655-.254.916-.742.745-1.408-.162-.625-.671-.8-1.258-.81-.713-.015-1.427-.028-2.14-.042l-1.994.038c-.937.017-1.422.4-1.427 1.129Z"
      fill="#333"
    />
  </svg>
);

export default SetUpInventoryStepImage;
