import {Form, Formik, FormikHelpers, FormikProps, yupToFormErrors} from 'formik';
import * as Yup from 'yup';
import React, {Ref, useContext, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import {toast} from 'react-toastify';
import {Badge, Button, Checkbox, IconButton, Input, Menu, MenuHandler, MenuItem, MenuList} from 'spenda-ui-react';

import {AttachDocs} from '../../assets/svg/AttachDocs';
import CreateQuoteTable from '../quote-management/CreateQuoteTable';
import {AUTH_SELLING_QUOTES_LIST, AUTH_SELLING_QUOTES_PREVIEW} from '../../routes/QuoteManagementRoutes';
import expandIcon from '../../assets/svg/accounts-payable/report-expand-icon.svg';
import {ICustomer} from '../../model/customer/Customer';
import {IQuoteAttachment, IQuotes, IQuotesPackage} from '../../model/quotes/quotes';
import useQuotesAPI from '../../services/useQuotesAPI';
import LoadingIndicator from '../ui/LoadingIndicator';
import {Toast} from '../../utils/Toast';
import {AttachmentTypeEnum, DISCOUNT_MODE} from '../../model/constants/Constants';
import {useAttachmentsAPI} from '../../services/useAttachmentsAPI';
import AppContext from '../../context/app/appContext';
import {ITenantInfo} from '../../model/Tenant';
import {discountToDiscountString, fileExtension} from '../../utils/formatter';
import {
  DeleteQuotePackageDialog,
  QuoteAttachmentDialog,
  QuoteCancelDialog,
  QuoteDeleteDialog,
  QuoteDiscardPackageDialog,
  QuoteDuplicateDialog,
  QuoteAddNoteDialog,
} from '../dialog';
import {useFeatureFlags} from '../../hooks/useFeatureFlags';
import {SelectQuoteCustomerDialog} from '../dialog/SelectQuoteCustomerDialog';
import {IconNoteBook, ListCheckIcon} from '../../assets/svg';
import {useQuoteContext} from '../../context/quote-context/QuoteContext';
import { AttachmentType } from '../../model/FileDetails';

const validationSchema = Yup.object({
  name: Yup.string().required('Quote name is required.').max(50, 'Quote name max 50 char.'),
  description: Yup.string().required('Quote Description is required.'),
  lines: Yup.array().of(
    Yup.object().shape({
      shortDescription: Yup.string().nullable(true),
      lineTotalEx: Yup.number().when(['$tenantInfo', 'shortDescription', 'inventoryID'], {
        is: (tenantInfo: ITenantInfo, shortDescription: string, inventoryID: number) => {
          return (
            shortDescription?.length > 0 &&
            inventoryID !== tenantInfo?.TenantUserDetails?.DefaultDiscountLineItemInventoryId &&
            inventoryID !== tenantInfo?.TenantUserDetails?.DefaultShippingLineItemInventoryId
          );
        },
        then: Yup.number().required('Line Total is required').nullable().min(0.1, 'Invalid Line Total'),
        otherwise: Yup.number().notRequired().nullable(),
      }),
      quantity: Yup.number().when(['shortDescription'], {
        is: (shortDescription: string) => shortDescription?.length > 0,
        then: Yup.number().required('Quantity is required').nullable().min(1, 'Invalid Quantity'),
        otherwise: Yup.number().notRequired().nullable(),
      }),
    }),
  ),
});

interface QuoteCreateFormProps {
  handleShowQuoteVariant?: (value: boolean) => void;
  showQuoteVariant?: boolean;
  selectedQuoteCustomer: ICustomer;
  expiryDate: string;
  assignedToUserID: number;
  setOpenCustomer?: React.Dispatch<React.SetStateAction<boolean>>;
  setShowQuoteDetailsDialog: React.Dispatch<React.SetStateAction<boolean>>;
  selectedQuote: number;
  quotePackage?: IQuotesPackage;
  createNewVariant?: () => void;
  setQuotesPackage: React.Dispatch<React.SetStateAction<IQuotesPackage>> | ((_package: IQuotesPackage) => void);
  handleDeleteQuote: (quoteId: number) => void;
  formRef: Ref<FormikProps<IQuotes>>;
  updateVariantTotalAmount: (amount: number) => void;
  setIsUpdatingLines: React.Dispatch<React.SetStateAction<boolean>>;
}

export const quoteVariantInitialValues: IQuotes = {
  quotePackageID: -1,
  quoteID: -1,
  name: '',
  description: '',
  isActive: true,
  discountMode: DISCOUNT_MODE.NONE,
  discountString: '',
  discount: 0,
  shipping: 0,
  lines: [
    {
      inventoryID: 0,
      code: '',
      shortDescription: '',
      sellPriceExString: '',
      lineTotalExString: '',
      quantity: 0,
      isActive: true,
      sellPriceEx: 0,
      lineTotalEx: 0,
      lineTotalInc: 0,
      uoM: '',
      sequenceNumber: 0,
    },
  ],
};

export interface ColumnOptions {
  costPriceAndMargin: boolean;
  marginAmountAndPercentage: boolean;
}

const QuoteCreateForm = (quoteProps: QuoteCreateFormProps) => {
  const {
    showQuoteVariant,
    handleShowQuoteVariant,
    selectedQuote,
    quotePackage,
    setQuotesPackage,
    handleDeleteQuote,
    setShowQuoteDetailsDialog,
    formRef,
    updateVariantTotalAmount,
    setIsUpdatingLines,
  } = quoteProps;
  const [openAddAttachment, setOpenAddAttachment] = React.useState(false);
  const [openAddNoteDialog, setOpenAddNoteDialog] = useState<boolean>(false);
  const history = useHistory();
  const [quoteDetails, setQuoteDetails] = useState<IQuotes>();
  const {createQuote, deleteQuotePackage, updateQuote, getQuoteAttachments, isLoading, updateQuotePackage} =
    useQuotesAPI();
  const {deleteAttachment,getSecureAttachment, isLoading: attachmentLoading} = useAttachmentsAPI();
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [showDeleteQuotePackage, setShowDeleteQuotePackage] = useState<boolean>(false);
  const [showDeleteQuote, setShowDeleteQuote] = useState<boolean>(false);
  const [quoteAttachments, setQuoteAttachments] = useState<IQuoteAttachment[]>([]);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [lineItemsError, setLineItemsError] = useState(false);
  const [columnOptions, setColumnOptions] = useState<ColumnOptions>({
    costPriceAndMargin: true,
    marginAmountAndPercentage: true,
  });
  const [openDuplicateDialog, setOpenDuplicateDialog] = useState<boolean>(false);
  const [openSelectCustomerDialog, setOpenSelectCustomerDialog] = useState<boolean>(false);
  const [showDiscardConfirmationDialog, setShowDiscardConfirmationDialog] = useState<boolean>(false);
  const [selectedQuoteCustomer, setSelectedQuoteCustomer] = useState<ICustomer>();
  const [isSavingNotes, setIsSavingNotes] = useState<boolean>();

  //context
  const {tenantInfo} = useContext(AppContext);
  const {QuoteV289367} = useFeatureFlags().tenantOwned();
  const {setDuplicatePackage, refreshQuotePackage, isCollapseRightPanal} = useQuoteContext();

  const onSubmit = async (values: IQuotes, formikHelper: FormikHelpers<IQuotes>) => {
    if (moment(quotePackage?.expiryDate).isBefore(moment(), 'day') && quotePackage?.isActive) {
      setShowQuoteDetailsDialog(true);
      return;
    }

    const validateIsActive = (values: IQuotes) => {
      for (let i = 0; i < values.lines.length; i++) {
        if (values.lines[i].isActive && values.lines[i].code) {
          return false;
        }
      }
      return true;
    };
    if ((values.lines && values.lines.length === 0) || !values.lines[0].code || validateIsActive(values)) {
      setLineItemsError(true);
      return Promise.reject();
    } else if (QuoteV289367 && (values?.requiredDepositAmount || 0) > (values?.totalInc || 0)) {
      toast.error(`Please adjust the deposit amount to be less than the total balance.`, {
        position: 'top-center',
        className: 'bg-error',
      });
      return Promise.reject();
    }
    if (quotePackage?.quotes) {
      const quotes = quotePackage?.quotes;
      for (let i = 0; i < quotes.length; i++) {
        if (
          quotes[i].quoteID !== values.quoteID &&
          (quotes[i].lines.length === 0 ||
            quotes[i].lines[0].code === '' ||
            !quotes[i].description ||
            quotes[i].lines.filter(line => !line.quantity || !line.lineTotalEx).length)
        ) {
          toast.error(
            `Please fill all the required fields in the quote variant [${quotes[i].name}] before preview the quote.`,
            {
              position: 'top-center',
              className: 'bg-error',
            },
          );
          return Promise.reject();
        } else if (
          QuoteV289367 &&
          quotes[i].quoteID !== values.quoteID &&
          (quotes[i]?.requiredDepositAmount || 0) > (quotes[i]?.totalInc || 0)
        ) {
          toast.error(
            `Please adjust the deposit amount to be less than the total balance in the quote variant [${quotes[i].name}] before preview the quote.`,
            {
              position: 'top-center',
              className: 'bg-error',
            },
          );
          return Promise.reject();
        }
      }
    }

    if (values.quoteID === -1) {
      createQuote(values)
        .then(() => {
          Toast.info('Quote saved successfully');
          formikHelper.setSubmitting(false);
          history.push(`${AUTH_SELLING_QUOTES_PREVIEW}${quotePackage?.quotePackageID}`);
        })
        .catch(() => {
          formikHelper.setSubmitting(false);
        });
    } else {
      updateQuote(values)
        .then(() => {
          Toast.info('Quote updated successfully');
          formikHelper.setSubmitting(false);
          history.push(`${AUTH_SELLING_QUOTES_PREVIEW}${quotePackage?.quotePackageID}`);
        })
        .catch(e => {
          console.error(e);
          formikHelper.setSubmitting(false);
        });
    }
  };

  const updateQuoteVariantDetails = (quote: IQuotes) => {
    const quotePkg = quotePackage && {...quotePackage};

    const index = quotePkg?.quotes?.findIndex(q => q.quoteID == quote.quoteID);

    if (index != -1 && quotePkg && quotePkg?.quotes?.length) {
      quotePkg.quotes[index!] = quote;

      const discountString = () => {
        if (!(index != -1 && quotePkg && quotePkg?.quotes?.length)) return '';
        return (quotePkg.quotes[index!].discountString = discountToDiscountString(
          Number(quotePkg.quotes[index!].discount),
          quotePkg.quotes[index!].discountMode!,
        ));
      };

      setQuotesPackage(quotePkg);

      setQuoteDetails({
        ...quote,
        lines:
          quote?.lines.map(line => {
            return {
              ...line,
              marginString: discountToDiscountString(Number(line.margin), line.marginMode!, true, line.quantity),
              lineTotalExString: line.lineTotalEx?.toFixed(2),
            };
          }) ?? [],
        discountString: discountString(),
        shipping: Number(quote?.shipping || 0).toFixed(2),
        quotePackageID: quotePackage?.quotePackageID!,
      });
    }
  };

  const saveAsDraft = async (values: IQuotes, isSave?: boolean, showToast: boolean = true) => {
    if (values.quoteID === -1) {
      const createdQuote: IQuotes = await createQuote(values);
      Toast.info('Quote saved successfully');
      if (!isSave) {
        history.push(AUTH_SELLING_QUOTES_LIST);
      } else {
        updateQuoteVariantDetails(createdQuote);
      }
    } else {
      const updatedQuote: IQuotes = await updateQuote(values);
      showToast && Toast.info('Quote updated successfully');
      if (!isSave) {
        history.push(AUTH_SELLING_QUOTES_LIST);
      } else {
        updateQuoteVariantDetails(updatedQuote);
      }
    }
  };

  const fetchQuoteAttachments = async () => {
    const quoteAttachments = await getQuoteAttachments(selectedQuote);
    if (quoteAttachments.length > 0) {
      const securedAttachments = await Promise.all(
        quoteAttachments.map(async (attachment: IQuoteAttachment) => {
          try {
            const secureAttachment = await getSecureAttachment(AttachmentTypeEnum.Quotes, attachment.attachmentGuid);
            const blob = new Blob([secureAttachment], { type: attachment.type });
            const fileName = attachment.caption;
  
            const file = new File([blob], fileName, {
              type: AttachmentType[fileExtension(fileName) as keyof typeof AttachmentType],
            });
  
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.onload = () => {
                attachment.uri = reader.result as string;
                resolve({ ...attachment, secureAttachment: reader.result as string });
              };
              reader.onerror = reject;
              reader.readAsDataURL(file);
            });
          } catch (error) {
            console.error('Error securing attachment:', attachment.attachmentGuid, error);
            return attachment;
          }
        }),
      );
      setQuoteAttachments(securedAttachments as IQuoteAttachment[]);
    } else {
      setQuoteAttachments(quoteAttachments);
    }
  };

  useEffect(() => {
    if (selectedQuote !== -1) {
      const selectedQuoteDetails = quotePackage?.quotes?.find(quote => quote.quoteID === selectedQuote);
      if (!selectedQuoteDetails?.lines.length) {
        selectedQuoteDetails?.lines.push({
          inventoryID: 0,
          code: '',
          shortDescription: '',
          sellPriceExString: '',
          lineTotalExString: '',
          quantity: 0,
          isActive: true,
          sellPriceEx: 0,
          lineTotalEx: 0,
          lineTotalInc: 0,
          uoM: '',
          sequenceNumber: 0,
        });
      }

      const discountString = () => {
        if (!selectedQuoteDetails) return '';
        return (selectedQuoteDetails.discountString = discountToDiscountString(
          Number(selectedQuoteDetails.discount),
          selectedQuoteDetails.discountMode!,
        ));
      };

      setQuoteDetails({
        ...quoteVariantInitialValues,
        ...selectedQuoteDetails,
        lines:
          selectedQuoteDetails?.lines.map(line => {
            return {
              ...line,
              marginString: discountToDiscountString(Number(line.margin), line.marginMode!, true, line.quantity),
              lineTotalExString: line.lineTotalEx?.toFixed(2),
            };
          }) ?? [],
        discountString: discountString(),
        shipping: Number(selectedQuoteDetails?.shipping || 0).toFixed(2),
        quotePackageID: quotePackage?.quotePackageID!,
      });
      fetchQuoteAttachments();
    }
    setIsMounted(true);
  }, [selectedQuote, quotePackage]);

  const validateQuoteFillingInfoOrNot = (quotePackage: IQuotesPackage) => {
    if (!quotePackage.quotePackageID || !quotePackage.quotes || quotePackage.quotes.length === 0) {
      return false;
    }
    for (let i = 0; i < quotePackage.quotes.length; i++) {
      if (quotePackage.quotes[i].description) {
        return false;
      }
      for (let j = 0; j < quotePackage.quotes[i].lines.length; j++) {
        if (quotePackage.quotes[i].lines[j].inventoryID) {
          return false;
        } else {
          break;
        }
      }
    }
    return true;
  };

  const handleCloseClick = async (dirty?: boolean) => {
    if (dirty) {
      setOpenConfirmationDialog(true);
    } else if (!validateQuoteFillingInfoOrNot(quotePackage!)) {
      history.push(AUTH_SELLING_QUOTES_LIST);
    } else {
      setShowDiscardConfirmationDialog(true);
    }
  };

  const handleDiscardPackege = async () => {
    await deleteQuotePackage(quotePackage?.quotePackageID!);
    setShowDiscardConfirmationDialog(false);
    history.push(AUTH_SELLING_QUOTES_LIST);
  };

  const handleDeletePackage = async () => {
    setIsDeleting(true);
    await deleteQuotePackage(quotePackage?.quotePackageID!);
    setShowDeleteQuotePackage(false);
    setIsDeleting(false);
    history.push(AUTH_SELLING_QUOTES_LIST);
  };

  const handleDeleteQuoteById = async () => {
    await handleDeleteQuote(quoteDetails?.quoteID!);
    setShowDeleteQuotePackage(false);
    setShowDeleteQuote(false);
    setIsDeleting(false);
  };

  const deleteQuoteAttachment = async (guid: string) => {
    await deleteAttachment(AttachmentTypeEnum.Quotes, guid);
    fetchQuoteAttachments();
  };

  const onKeyDown = (keyEvent: React.KeyboardEvent<HTMLFormElement>) => {
    if (keyEvent && keyEvent.key === 'Enter') {
      keyEvent.preventDefault();
    }
  };

  const onSaveNote = async (internalNote: string, externalNote: string) => {
    const values = {
      quotePackageID: quotePackage?.quotePackageID!,
      internalNote,
      externalNote,
    };
    setIsSavingNotes(true);
    try {
      await updateQuotePackage(values);
      refreshQuotePackage();
      setOpenAddNoteDialog(false);
    } catch (error) {
      Toast.error('Failed to save the notes. Please try again.');
    } finally {
      setIsSavingNotes(false);
    }
  };

  return (
    <>
      {!isMounted || !quoteDetails ? (
        <LoadingIndicator isLoading={QuoteV289367 ? false : true} size="md" color="#1C78AD" />
      ) : (
        <>
          <Formik
            validateOnBlur
            enableReinitialize
            initialValues={{...quoteDetails}}
            innerRef={formRef}
            validate={async values => {
              try {
                await validationSchema.validate(values, {
                  abortEarly: false,
                  context: {
                    tenantInfo: tenantInfo,
                  },
                });
              } catch (error) {
                return yupToFormErrors(error);
              }
            }}
            onSubmit={(value, helper) => onSubmit(value, helper)}
          >
            {props => {
              return (
                <>
                  <Form onKeyDown={onKeyDown}>
                    <LoadingIndicator
                      isLoading={isLoading}
                      position={{
                        height: '100% !important',
                        display: 'flex',
                        position: 'absolute',
                        left: '0',
                        right: 0,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                      }}
                      size="md"
                      color="#1C78AD"
                    />
                    <div className="h-[calc(100vh-106px)] overflow-y-auto rounded-lg bg-[#FFFFFF] p-2.5">
                      <div className="flex items-center justify-between">
                        <div className="flex items-center gap-x-2">
                          {!showQuoteVariant && !QuoteV289367 && (
                            <div className={`cursor-pointer`}>
                              <img
                                src={expandIcon}
                                onClick={() => {
                                  handleShowQuoteVariant?.(true);
                                }}
                                data-autoid="imgCollapsibleIcon"
                              />
                            </div>
                          )}
                          <div className="flex gap-x-2">
                            <Input
                              name="name"
                              label={'Quote name'}
                              helperText={props.touched.name && props.errors?.name ? props.errors?.name : ''}
                              data-autoid="name"
                              value={props.values?.name}
                              onChange={props.handleChange}
                              onBlur={async e => {
                                const buttonClicked =
                                  e.relatedTarget &&
                                  (e?.relatedTarget.textContent === 'Continue' ||
                                    e?.relatedTarget.textContent === 'Save' ||
                                    e?.relatedTarget.textContent === 'Save as Draft' ||
                                    e?.relatedTarget.textContent === 'Delete');
                                if (!buttonClicked) {
                                  await updateQuote({
                                    name: props.values?.name,
                                    description: props.values?.description,
                                    isActive: true,
                                    quoteID: props.values?.quoteID,
                                    discount: props.values?.discount,
                                    discountMode: props.values?.discountMode,
                                    shipping: props.values?.shipping,
                                  });
                                }
                              }}
                              error={props.touched.name && Boolean(props.errors?.name)}
                              containerProps={{
                                className: 'min-w-[164px]',
                              }}
                            />
                            <Input
                              name="description"
                              label={'Quote description'}
                              value={props.values?.description}
                              error={props.touched?.description && Boolean(props.errors?.description)}
                              helperText={
                                props.touched.description && props.errors?.description ? props.errors?.description : ''
                              }
                              onChange={props.handleChange}
                              onBlur={async e => {
                                const buttonClicked =
                                  e.relatedTarget &&
                                  (e?.relatedTarget.textContent === 'Continue' ||
                                    e?.relatedTarget.textContent === 'Save' ||
                                    e?.relatedTarget.textContent === 'Save as Draft' ||
                                    e?.relatedTarget.textContent === 'Delete');
                                if (!buttonClicked) {
                                  await updateQuote({
                                    name: props.values?.name,
                                    description: props.values?.description,
                                    isActive: true,
                                    quoteID: props.values?.quoteID,
                                    discount: props.values?.discount,
                                    discountMode: props.values?.discountMode,
                                    shipping: props.values?.shipping,
                                  });
                                }
                              }}
                              data-autoid="txtDescription"
                              containerProps={{
                                className: 'min-w-[334px]',
                              }}
                            />
                          </div>
                        </div>
                        <div className="flex gap-x-2">
                          {QuoteV289367 && (
                            <IconButton
                              variant="outlined"
                              name="notebook"
                              onClick={() => {
                                setOpenAddNoteDialog(!openAddNoteDialog);
                              }}
                            >
                              <IconNoteBook />
                            </IconButton>
                          )}
                          {quoteDetails?.quoteID && quoteDetails?.quoteID !== -1 && (
                            <Badge
                              className="error h-[22px] min-h-[20px] w-[22px] min-w-[20px]"
                              color="error"
                              invisible={quoteAttachments.length === 0}
                              content={quoteAttachments.length}
                            >
                              <IconButton
                                variant="outlined"
                                name="attachment"
                                onClick={() => setOpenAddAttachment(true)}
                              >
                                <AttachDocs className="-rotate-45" width={22} height={22} />
                              </IconButton>
                            </Badge>
                          )}

                          {!QuoteV289367 && (
                            <Button
                              variant="outlined"
                              disabled={props.isSubmitting}
                              className={'h-10'}
                              onClick={() => {
                                quoteProps?.setOpenCustomer?.(true);
                              }}
                            >
                              Edit Customer
                            </Button>
                          )}
                          <Menu
                            placement="bottom-end"
                            dismiss={{
                              itemPress: false,
                            }}
                          >
                            <MenuHandler>
                              {!QuoteV289367 ? (
                                <Button variant="outlined" className={'h-10'}>
                                  Column Option
                                </Button>
                              ) : (
                                <IconButton variant="outlined" name="listCheck">
                                  <ListCheckIcon />
                                </IconButton>
                              )}
                            </MenuHandler>
                            <MenuList className="overflow-hidden border-primary p-0">
                              <MenuItem className="flex flex-col items-start px-2.5">
                                <div className="flex items-center gap-x-2">
                                  <Checkbox
                                    id="CostPriceAndMargin"
                                    name="CostPriceAndMargin"
                                    ripple={false}
                                    color="primary"
                                    checked={columnOptions.costPriceAndMargin}
                                    onChange={() =>
                                      setColumnOptions(prev => ({
                                        ...prev,
                                        costPriceAndMargin: !prev.costPriceAndMargin,
                                      }))
                                    }
                                    containerProps={{className: 'p-0'}}
                                    className="border-primary bg-white checked:border-none checked:bg-primary hover:before:opacity-0"
                                  />
                                  <label
                                    htmlFor="CostPriceAndMargin"
                                    className="cursor-pointer gap-2 p-2 text-base font-semibold text-black-800"
                                  >
                                    Cost Price & Margin
                                  </label>
                                </div>
                                {QuoteV289367 && (
                                  <div className="mt-2 flex items-center gap-x-2">
                                    <Checkbox
                                      id="MarginAmountAndPercentage"
                                      name="MarginAmountAndPercentage"
                                      ripple={false}
                                      color="primary"
                                      checked={columnOptions.marginAmountAndPercentage}
                                      onChange={() =>
                                        setColumnOptions(prev => ({
                                          ...prev,
                                          marginAmountAndPercentage: !prev.marginAmountAndPercentage,
                                        }))
                                      }
                                      containerProps={{className: 'p-0'}}
                                      className="border-primary bg-white checked:border-none checked:bg-primary hover:before:opacity-0"
                                    />
                                    <label
                                      htmlFor="MarginAmountAndPercentage"
                                      className="cursor-pointer gap-2 p-2 text-base font-semibold text-black-800"
                                    >
                                      Margin Amount & Percent
                                    </label>
                                  </div>
                                )}
                              </MenuItem>
                            </MenuList>
                          </Menu>
                          <Button
                            variant="outlined"
                            disabled={props.isSubmitting}
                            className={'h-10'}
                            onClick={() => {
                              quoteProps.setShowQuoteDetailsDialog(true);
                            }}
                          >
                            Change Details
                          </Button>
                        </div>
                      </div>
                      <div className="my-2 h-0.5 w-full border-b border-b-[#ECECEC]" />
                      <CreateQuoteTable
                        updateQuote={updateQuoteVariantDetails}
                        lineItemError={lineItemsError}
                        columnOptions={columnOptions}
                        updateVariantTotalAmount={updateVariantTotalAmount}
                        setIsUpdatingLines={setIsUpdatingLines}
                      />

                      <div
                        className={`fixed  bottom-2.5 left-[22px] flex ${QuoteV289367 && !isCollapseRightPanal ? 'w-[calc(100%-360px)] ' : 'w-[calc(100%-44px)]'}  items-center justify-between rounded-lg bg-[#ECECEC]`}
                      >
                        <div className="m-2.5 flex gap-x-2.5">
                          <Button
                            variant="outlined"
                            disabled={props.isSubmitting}
                            className="bg-[#FFFFFF]"
                            onClick={() => handleCloseClick(props.dirty)}
                          >
                            Cancel
                          </Button>
                          {QuoteV289367 ? (
                            <Button
                              variant="outlined"
                              className="bg-[#FFFFFF]"
                              onClick={() => {
                                setOpenDuplicateDialog(!openDuplicateDialog);
                                setDuplicatePackage(true);
                              }}
                            >
                              Duplicate Package
                            </Button>
                          ) : (
                            <Button
                              variant="outlined"
                              disabled={props.values.quoteID === -1 || (quotePackage?.quotes?.length ?? 0) >= 5}
                              className="bg-[#FFFFFF]"
                              onClick={() => {
                                quoteProps?.handleShowQuoteVariant?.(true);
                                quoteProps.createNewVariant?.();
                              }}
                            >
                              Create a Quote Variant
                            </Button>
                          )}
                          <Button
                            color="error"
                            disabled={!quotePackage?.quotePackageID}
                            onClick={() => {
                              if (quotePackage?.quotes && quotePackage?.quotes?.length <= 1) {
                                setShowDeleteQuotePackage(true);
                                setShowDeleteQuote(false);
                              } else {
                                setShowDeleteQuote(true);
                                setShowDeleteQuotePackage(false);
                              }
                            }}
                          >
                            Delete Quote
                          </Button>
                        </div>
                        <div className="m-2.5 flex gap-x-2.5">
                          <Button disabled={isLoading} onClick={() => saveAsDraft(props.values, true)}>
                            Save
                          </Button>
                          <Button disabled={isLoading} onClick={() => saveAsDraft(props.values)}>
                            Save as Draft
                          </Button>
                          <Button type="submit" disabled={props.isSubmitting || isLoading}>
                            Continue
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Form>
                </>
              );
            }}
          </Formik>
          {openDuplicateDialog && (
            <QuoteDuplicateDialog
              handleCancel={() => setOpenDuplicateDialog(false)}
              handleDuplicate={() => {
                setOpenDuplicateDialog(false);
                setOpenSelectCustomerDialog(!openSelectCustomerDialog);
              }}
            />
          )}
          {openSelectCustomerDialog && (
            <SelectQuoteCustomerDialog
              open={openSelectCustomerDialog}
              handleCancel={() => {
                setOpenSelectCustomerDialog(!openSelectCustomerDialog);
              }}
              handleConfirm={() => {
                setOpenSelectCustomerDialog(false);
                quoteProps.setShowQuoteDetailsDialog(true);
              }}
              setSelectedQuoteCustomer={setSelectedQuoteCustomer}
              selectedQuoteCustomer={selectedQuoteCustomer}
            />
          )}
          {openAddNoteDialog && (
            <QuoteAddNoteDialog
              handleCancel={() => setOpenAddNoteDialog(false)}
              handleAddNote={(internalNote, externalNote) => onSaveNote(internalNote, externalNote)}
              isLoading={isSavingNotes}
            />
          )}
          {openAddAttachment && (
            <QuoteAttachmentDialog
              open={openAddAttachment}
              handleOpenAndClose={setOpenAddAttachment}
              quoteID={quoteDetails.quoteID}
              uploadedAttachments={quoteAttachments}
              deleteQuoteAttachment={deleteQuoteAttachment}
              fetchAttachments={fetchQuoteAttachments}
              loading={attachmentLoading}
            />
          )}
          {openConfirmationDialog && <QuoteCancelDialog onCancel={() => setOpenConfirmationDialog(false)} />}
          {showDiscardConfirmationDialog && <QuoteDiscardPackageDialog handleDiscard={() => handleDiscardPackege()} />}
          {showDeleteQuote && (
            <QuoteDeleteDialog
              onCancel={() => setShowDeleteQuote(false)}
              deleteQuote={() => {
                setIsDeleting(true);
                handleDeleteQuoteById();
              }}
              isSubmitting={isDeleting}
            />
          )}
          {showDeleteQuotePackage && (
            <DeleteQuotePackageDialog
              isSubmitting={isDeleting}
              deleteQuotePackage={() => {
                handleDeletePackage();
              }}
              deleteQuote={() => {
                setIsDeleting(true);
                handleDeleteQuoteById();
              }}
              onCancel={() => setShowDeleteQuotePackage(false)}
              disableQuoteButton={!quoteDetails.quoteID || quoteDetails.quoteID === -1}
              showQuoteButton={quotePackage?.quotes! && quotePackage?.quotes?.length > 1}
            />
          )}
        </>
      )}
    </>
  );
};

export default QuoteCreateForm;
