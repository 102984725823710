import {IActionRes, IActionResults} from './../model/ActionResults';
import {IConnectedSupplier, ISupplierSummary} from '../model/supplier/ConnectedSupplier';
import useHttp from '../hooks/useHttp';
import {ICreditCardT, ITenantSupplierPaymentMethod} from '../model/payment/PaymentMethod';
import {UpdateAccountRequest} from './useSpendaPaymentServicesAPI';

const useConnectedSupplierAPI = () => {
  const {GET, POST, DELETE, PUT, isLoading} = useHttp();

  const connectedSuppliersSearch = async (filter: any): Promise<ISupplierSummary[] | IConnectedSupplier[]> => {
    const data = await GET('Spenda/Payments/AccountsReceivable/Buyer/ConnectedSuppliersSummary', filter);

    const suppliersResponse = data.map(
      (supplier: {
        AvailableCustomerModuleIDs: any;
        Logos: any;
        SupplierID: any;
        TenantName_Supplier: any;
        Status: any;
        StatusDescription: any;
        MarketplacePath: any;
        IsLendingProvider: boolean;
        IsPaymentProvider: boolean;
        IsPaymentServicesCreditCardMerchant: boolean;
        IsPaymentServicesMerchant: boolean;
        IsAutomaticInvoiceImportEnabled?: boolean;
        IsAutomaticInvoiceImportSetup?: boolean;
        MarketplaceID?: number;
      }) => {
        return {
          AvailableModules: supplier.AvailableCustomerModuleIDs,
          Logos: supplier.Logos,
          SupplierID: supplier.SupplierID,
          SupplierName: supplier.TenantName_Supplier,
          Status: supplier.Status,
          StatusDescription: supplier.StatusDescription,
          TenantName: supplier.TenantName_Supplier,
          MarketplacePath: supplier.MarketplacePath,
          IsLendingProvider: supplier.IsLendingProvider,
          IsPaymentProvider: supplier.IsPaymentProvider,
          IsPaymentServicesCreditCardMerchant: supplier.IsPaymentServicesCreditCardMerchant,
          IsPaymentServicesMerchant: supplier.IsPaymentServicesMerchant,
          IsAutomaticInvoiceImportEnabled: supplier.IsAutomaticInvoiceImportEnabled,
          IsAutomaticInvoiceImportSetup: supplier.IsAutomaticInvoiceImportSetup,
          MarketplaceID: supplier?.MarketplaceID,
        };
      },
    );

    return suppliersResponse;
  };

  const getConnectedSupplier = (id: string): Promise<IConnectedSupplier> => {
    return GET(`Spenda/Payments/AccountsReceivable/Buyer/ConnectedSupplierDetails/${id}`).then(
      (data: IConnectedSupplier) => data,
    );
  };

  const getPaymentMethods = (id: number): Promise<IActionResults<ITenantSupplierPaymentMethod[]>> => {
    return GET(`Spenda/Payments/AccountsReceivable/Buyer/Payment/${id}/PaymentMethods`).then(
      (data: IActionResults<ITenantSupplierPaymentMethod[]>) => {
        return data;
      },
    );
  };

  const savePaymentMethod = (id: number, data: ICreditCardT): Promise<IActionResults<ITenantSupplierPaymentMethod>> => {
    return POST(`connectedSuppliers/${id}/myPaymentOptions`, data).then(
      (data: IActionResults<ITenantSupplierPaymentMethod>) => data,
    );
  };

  const deletePaymentMethod = (id: number, SupplierPaymentMethodID: number): Promise<boolean> => {
    return DELETE(`connectedSuppliers/${id}/myPaymentOptions/${SupplierPaymentMethodID}`).then(
      (data: IActionRes) => data.IsSuccess,
    );
  };

  const updatePaymentMethodDetails = (
    supplierID: string,
    supplierPaymentOptionID: string,
    req: UpdateAccountRequest,
  ) => {
    return POST(`connectedSuppliers/${supplierID}/myPaymentOptions/${supplierPaymentOptionID}`, {
      FriendlyName: req.friendlyName,
    });
  };

  const setDefaultPaymentMethod = (
    id: number,
    SupplierPaymentMethodID: number,
  ): Promise<IActionResults<ITenantSupplierPaymentMethod>> => {
    return PUT(`connectedSuppliers/${id}/myPaymentOptions/setDefault/${SupplierPaymentMethodID}`).then(
      (data: IActionResults<ITenantSupplierPaymentMethod>) => data,
    );
  };

  return {
    connectedSuppliersSearch,
    deletePaymentMethod,
    getConnectedSupplier,
    getPaymentMethods,
    savePaymentMethod,
    isLoading,
    updatePaymentMethodDetails,
    setDefaultPaymentMethod,
  };
};

export default useConnectedSupplierAPI;
