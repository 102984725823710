import React, {useEffect, useRef, useState} from 'react';
import {Dialog, DialogBody, DialogHeader, IconButton, Typography} from 'spenda-ui-react';

import useQuotesAPI from '../../services/useQuotesAPI';
import LoadingIndicator from '../ui/LoadingIndicator';
import environment from '../../utils/environment';
import CrossIcon from '../../assets/svg/Cross';
import {ProcessingAnimation} from '../../assets/svg/animations/LottieAnimations';

interface ILimepayCheckout {
  inviteCode: string;
  open: boolean;
  handleLimepayClose: (showStatus: boolean, isSuccessful: boolean) => void;
  amountToPay: number;
  isQuote?: boolean;
}

export const LimepayCheckout = (props: ILimepayCheckout) => {
  const {open, inviteCode, handleLimepayClose, amountToPay, isQuote} = props;
  const checkoutRef = useRef<HTMLDivElement | null>(null);
  const [isScriptLoaded, setIsScriptLoaded] = useState(false);
  const {sendLimepayCheckoutToken} = useQuotesAPI();
  const [isProcessing, setIsProcessing] = useState(false);

  const basePaymentUrl = isQuote
    ? 'Spenda/Sales/QuotePackageInvite/:inviteCode/PaymentToken'
    : 'Spenda/Payments/AccountsReceivable/InvoicePaymentInvitation/:inviteCode/PaymentToken';

  useEffect(() => {
    // Create a script element
    const script = document.createElement('script');
    script.src = 'https://checkout-v3.au.meetapril.io/v3/checkout-v3.0.0.min.js';
    script.async = true;
    script.onload = () => {
      setIsScriptLoaded(true); // Mark script as loaded when it finishes loading
    };
    script.onerror = () => {
      console.error('Failed to load AprilCheckout script.');
    };

    // Append the script to the body
    document.body.appendChild(script);

    // Cleanup script on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (isScriptLoaded && amountToPay && typeof window.AprilCheckout !== 'undefined' && !isProcessing) {
      const AprilCheckout = window.AprilCheckout.createCheckout();
      AprilCheckout.init({
        publicKey: environment.LIMEPAY_KEY,
        hidePayLaterOption: true /* optional (default false),
            can pass (true or false), it will show/hide the BNPL option */,
        paymentToken: function (_token: any, _paymentData: any) {
          setIsProcessing(true);
          /* Once the checkout is complete this function will be called.
            Use the `token` on your server to complete the transaction. */
          const paymentMethod =
            _paymentData?.paymentMethodType === 'Card' &&
            _paymentData?.paymentSource?.cardPaymentSource?.brand?.replace(' ', '');
          sendLimepayCheckoutToken(basePaymentUrl, inviteCode, _token, amountToPay, paymentMethod)
            .then(() => {
              setIsProcessing(false);
              handleLimepayClose(true, true);
            })
            .catch(() => {
              setIsProcessing(false);
              handleLimepayClose(true, false);
            });
        },
      });
      AprilCheckout.render({
        elementId: 'april-cont',
        currency: 'AUD',
        amount: parseInt((amountToPay * 100).toString()), // should be in cents (integer)
        paymentType: 'paycard' /* Optional (default "paycard"),
            selected payment option ("paycard" or "payplan") */,
        showPayNow: true /* Optional,
            enables Pay Now button for pay by card option */,
        showPayPlanSubmit: true /* Optional,
            enables Submit Payment Plan button for split payments option */,
        primaryColor: '#1C78AD', // Optional, theme primary color (HEX)
      });
      AprilCheckout.errorHandler(function (_err: any) {
        setIsProcessing(false);
        // Handle errors
        console.error(_err);
      });
      AprilCheckout.eventHandler(function (_event: any) {
        setIsProcessing(false);
        // Handle errors
      });
    }
  }, [isScriptLoaded, amountToPay]);

  return (
    <div className="relative h-full overflow-hidden bg-white">
      <Dialog
        open={open}
        handler={() => {}}
        className={`flex h-full !w-full !min-w-[20%] flex-col items-center justify-center p-8 sm:block sm:h-auto sm:!max-w-[320px] sm:p-0`}
      >
        {!isProcessing && (
          <DialogHeader>
            <div className="flex w-full justify-end">
              <IconButton
                variant="outlined"
                onClick={() => {
                  handleLimepayClose(false, false);
                }}
              >
                <CrossIcon className="fill-primary" height={'17px'} width={'17px'} />
              </IconButton>
            </div>
          </DialogHeader>
        )}
        <DialogBody className="mb-[4.5rem] flex min-h-[450px] flex-col items-center justify-center gap-10">
          {!isScriptLoaded ? (
            <LoadingIndicator isLoading={true} size="md" color="#1C78AD" />
          ) : (
            <>
              {isProcessing && (
                <div className="my-6 flex-col justify-center p-2">
                  <ProcessingAnimation width="68px" height="68px" />
                  <Typography className={`mx-2 mt-8 text-center  text-primary`} variant="h2">
                    Payment in Progress
                  </Typography>
                  <Typography variant="small" className="mt-8">
                    Please wait while we process your payment.
                  </Typography>
                </div>
              )}
              <div id="april-cont" ref={checkoutRef} hidden={isProcessing}></div>
            </>
          )}
        </DialogBody>
      </Dialog>
    </div>
  );
};
