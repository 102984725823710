import * as React from 'react';
import {SVGProps} from 'react';

const IconCapSupplierMapping = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 10a3.333 3.333 0 1 0 0-6.667A3.333 3.333 0 0 0 10 10Zm0-5a1.667 1.667 0 1 1 0 3.333A1.667 1.667 0 0 1 10 5Zm7.217 2.63-.6-.198A6.667 6.667 0 0 0 10 0a6.66 6.66 0 0 0-6.595 7.577A4.204 4.204 0 0 0 1.282 8.66 4.122 4.122 0 0 0 0 11.667v3.396a4.177 4.177 0 0 0 3 4l2.392.75c.401.125.82.188 1.24.187a4.23 4.23 0 0 0 1.13-.155l4.816-1.425a2.49 2.49 0 0 1 1.311 0l1.99.667A3.334 3.334 0 0 0 20 15.85V11.56a4.179 4.179 0 0 0-2.783-3.93ZM6.465 3.136a4.99 4.99 0 0 1 7.07 0 5.03 5.03 0 0 1 .009 7.083l-2.96 2.88a.827.827 0 0 1-1.158 0L6.465 10.23a5.033 5.033 0 0 1 0-7.096Zm11.868 12.716a1.66 1.66 0 0 1-.636 1.31 1.64 1.64 0 0 1-1.355.329l-1.955-.657a4.187 4.187 0 0 0-2.264-.013l-4.82 1.423a2.5 2.5 0 0 1-1.411-.024l-2.412-.75a2.51 2.51 0 0 1-1.813-2.407v-3.395a2.47 2.47 0 0 1 .766-1.803 2.52 2.52 0 0 1 1.391-.671c.336.832.836 1.589 1.47 2.224l2.973 2.877a2.486 2.486 0 0 0 3.479 0l2.969-2.886a6.634 6.634 0 0 0 1.51-2.35l.448.148a2.505 2.505 0 0 1 1.66 2.354v4.29Z"
      className="fill-primary"
    />
  </svg>
);

export default IconCapSupplierMapping;
