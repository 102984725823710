import {SALES_ORDER_STATUS_LABEL} from '../../model/constants/Constants';
import {ITheme} from '../../model/theme/Theme';
import CapricornLogo from './CapricornLogo';

const CapricornDSDTheme: ITheme = {
  rootClassName: 'capricorn',
  title: 'Capricorn Ecommerce Transactions',
  logo: CapricornLogo,
  content: {
    header: {
      moduleName: true,
    },
    settings: {
      businessDetails: true,
      businessLocations: true,
      betaFeatures: true,
    },
    notifications: true,
    zohoChat: true,
    salesOrders: {
      customerOrderDetails: {
        editCustomerButton: true,
        customerRefInput: true,
        disclaimer: true,
        editOrdersButton: true,
        deleteOrderButton: true,
        invoicePickslipQuote: true,
        stepper: true,
        backButton: false,
      },
      editOrder: {
        downloadLinkedTransactions: true,
        emailLinkedInvoice: true,
      },
      dashboard: {
        tabs: true,
        createOrderButton: true,
      },
    },
    accountsReceivable: {
      paidInvoices: {
        paymentMethodsFilter: true,
        paymentMethodsIcon: true,
        table: {
          header: {
            sortAble: true,
          },
          columns: {
            creditApplied: true,
          },
        },
        invoice: {
          sendInvoiceButton: true,
          pdfButton: true,
        },
      },
      menu: {
        accountsReceivable: true,
        payments: true,
      },
      creditMemos: {
        createClaimRequestButton: true,
        orderDetailsTable: true,
      },
      invoicesPDF: {
        sendInvoiceButton: true,
      },
      widgetPanel: {
        conversationChatBox: true,
      },
      claimRequests: {
        claimsHighLevel: {
          table: {
            data: {
              startConvo: true,
            },
            columns: {
              vendorName: true,
            },
          },
        },
        aRClaimsWidget: {
          attachment: true,
          startConvo: true,
        },
        createAndEdit: {
          header: {
            startConvo: true,
            attachment: true,
          },
        },
        approveModal: {
          refNoAndDateInputs: true,
        },
      },
    },
  },

  personalizedContent: {
    salesOrders: {
      dashboard: {
        status: {
          Approved: SALES_ORDER_STATUS_LABEL.Approved,
        },
      },
      customerOrderDetails: {
        status: {
          Approved: SALES_ORDER_STATUS_LABEL.Approved,
          Cancelled: SALES_ORDER_STATUS_LABEL.Cancelled,
          Open: SALES_ORDER_STATUS_LABEL.Open,
          Reserved: SALES_ORDER_STATUS_LABEL.Reserved,
        },
      },
    },
    accountReceivables: {
      header: {
        subMenu: {
          'Paid Invoices': 'Invoiced Sales Order',
          'Claim Requests': 'Credit Memos',
        },
      },
      paymentHistory: {
        status: {
          paid: 'Invoiced',
        },
      },
      ARtable: {
        column: {
          'Invoice Ref': 'Invoice No',
        },
      },
      invoices: {
        searchRefNumber: {
          Payment_Ref: 'Invoice No',
        },
        PDF: {
          footer: {
            createClaim: 'Create Credit Memo',
          },
        },
      },
      claimRequests: {
        search: {
          refNumber: 'Invoice No',
        },
      },
    },
  },
};

export default CapricornDSDTheme;
